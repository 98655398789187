import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService, NzTreeComponent, NzTreeNodeOptions, NzTreeNode, TransferChange } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { clientConfig } from '@env/client-config';
import { FormControl } from '@angular/forms';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-configuration-invoice-inventory-set-alarm-rule',
  templateUrl: './set-alarm-rule.component.html',
  styleUrls: ['./set-alarm-rule.component.less'],
})
export class OutputConfigurationInvoiceInventorySetAlarmRuleComponent implements OnInit {
  @ViewChild('f', { static: true }) f: FormControl;
  loading = false;
  i: any;
  data: any = {
    inventoryId: '',
    invoiceType: '',
    noticeusers: '',
    alarmRules: [],
  }
  alarmRuleData: any = {
    alarmDesc: '',
    operation: '',
    quantity: '',
  }; // 报警规则
  alarmRuleList = [];
  // 报警状态
  statusOption = clientConfig.inventoryStatusOption;
  // 报警参数
  parameterOption = clientConfig.alarmOperationOption;
  @ViewChild('tree', { static: true }) tree!: NzTreeComponent;
  list: NzTreeNodeOptions[] = [
    { key: '皮卡丘上海分公司', id: 1, parentid: 0, title: '皮卡丘上海分公司' },
    { key: '杨洋', id: 2, parentid: 1, title: '杨洋', isLeaf: true },
    { key: '王宇', id: 3, parentid: 1, title: '王宇', isLeaf: true },
    { key: '刘鑫', id: 4, parentid: 1, title: '刘鑫', isLeaf: true },
    { key: '皮卡丘北京分公司', id: 5, parentid: 0, title: '皮卡丘北京分公司' },
    { key: '李子钦', id: 6, parentid: 5, title: '李子钦', isLeaf: true },
    { key: '张志飞', id: 7, parentid: 5, title: '张志飞', isLeaf: true },
    { key: '皮卡丘深圳分公司', id: 8, parentid: 0, title: '皮卡丘深圳分公司' },
    { key: '李紫嫣', id: 9, parentid: 8, title: '李紫嫣', isLeaf: true },
    { key: '王新宇', id: 10, parentid: 8, title: '王新宇', isLeaf: true },
  ];
  treeData = this.generateTree(this.list);
  checkedNodeList: NzTreeNode[] = [];
  checkedRightNodeList: NzTreeNode[] = [];

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    if (this.i) {
      this.data.inventoryId = this.i.inventoryId;
      this.data.invoiceType = this.i.invoiceType;
      this.getInfo();
    }
  }

  // 获取报警规则
  getInfo() {
    this.http.get(`/output/alarmrules/getByInventory/${this.data.inventoryId}`).subscribe(res => {
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.data.alarmRules = res.alarmRules;
      }
    }, error => {
      console.log('HTTP Error', error);
    });
  }

  // 添加报警规则条件
  add() {
    this.alarmRuleData = {
      alarmDesc: '',
      operation: '',
      quantity: '',
    };
    this.data.alarmRules.push(this.alarmRuleData);
  }

  // 删除报警规则
  delete(index) {
    this.data.alarmRules.splice(index, 1);
  }

  save() {
    this.loading = true;
    this.http.put('/output/alarmrules/save', this.data).subscribe(res => {
      this.loading = false;
      const flag = this.dialogService.getErrorCode(res);
      if (flag) {
        this.modal.close(true);
        this.msgSrv.success('操作成功！');
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  private generateTree(arr: NzTreeNodeOptions[]): NzTreeNodeOptions[] {
    const tree: NzTreeNodeOptions[] = [];
    const mappedArr: any = {};
    let arrElem: NzTreeNodeOptions;
    let mappedElem: NzTreeNodeOptions;

    for (let i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.id] = { ...arrElem };
      mappedArr[arrElem.id].children = [];
    }

    for (const id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id];
        if (mappedElem.parentid) {
          mappedArr[mappedElem.parentid].children.push(mappedElem);
        } else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }

  checkBoxChange(node: NzTreeNode, onItemSelect: (item: NzTreeNodeOptions) => void): void {
    if (node.isDisabled) {
      return;
    }
    node.isChecked = !node.isChecked;
    if (node.isChecked) {
      this.checkedNodeList.push(node);
    } else {
      const idx = this.checkedNodeList.indexOf(node);
      if (idx !== -1) {
        this.checkedNodeList.splice(idx, 1);
      }
    }
    const item = this.list.find(w => w.id === node.origin.id);
    onItemSelect(item!);
  }

  change(ret: TransferChange): void {
    if (ret.to === 'right') {
      this.checkedNodeList.forEach(node => {
        node.isDisabled = true;
        node.isChecked = true;
      })
    } else if (ret.to === 'left') {
      this.checkedNodeList.forEach(node => {
        if (node.key === ret.list[0].key) {
          node.isDisabled = false;
          node.isChecked = false;
          // todo-删除checkedNodeList当前移除项或者重新获取checkedNodeList
        }
      })
    }
  }

  close() {
    this.modal.destroy();
  }
}
