import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { STComponent, STColumn } from '@delon/abc';

@Component({
  selector: 'app-input-purchase-order-three-single-matches-match-result',
  templateUrl: './match-result.component.html',
  styles: [
    `
      :host ::ng-deep .ant-result {
      padding: 20px 0px;
  }
    `,
  ],
})
export class InputPurchaseOrderThreeSingleMatchesMatchResultComponent implements OnInit {
  record: any = {};
  i: any;
  // 校验通过数据
  successRows: any = [
    { 'id': '1', 'name': '物料凭证状态是否为可匹配状态', isSuccess: true, 'detail': '无' },
    { 'id': '2', 'name': '购方名称', isSuccess: true, 'detail': '无' },
    { 'id': '3', 'name': '购方税号', isSuccess: true, 'detail': '成功通过校验' },
    { 'id': '4', 'name': '销方名称', isSuccess: true, 'detail': '成功通过校验' },
    { 'id': '5', 'name': '物料组与税收分类', isSuccess: true, 'detail': '成功通过校验' },
    { 'id': '6', 'name': '物料说明与开票名称、规格型号', isSuccess: true, 'detail': '成功通过校验' },
    { 'id': '7', 'name': '税率', isSuccess: true, 'detail': '成功通过校验' },
    { 'id': '8', 'name': '数量', isSuccess: true, 'detail': '成功通过校验' },
    { 'id': '9', 'name': '不含税金额', isSuccess: true, 'detail': '成功通过校验' },
    { 'id': '10', 'name': '含税金额', isSuccess: true, 'detail': '成功通过校验' },
    { 'id': '11', 'name': '税额差', isSuccess: true, 'detail': '成功通过校验' },
  ]
  @ViewChild('sucessSt', { static: false }) sucessSt: STComponent;
  columns: STColumn[] = [
    { title: '校验项', index: 'name', i118n: 'input.matchResult.name', width: 300 },
    { title: '是否通过', index: 'isSuccess', type: 'yn', className: 'text-center', i118n: 'input.matchResult.isSuccess', width: 150 },
    { title: '详情', index: 'detail', i118n: 'input.matchResult.detail', width: 300 },
  ];
  // 校验失败数据
  errorRows: any = [
    { 'id': '1', 'name': '物料凭证状态是否为可匹配状态', isSuccess: false, 'detail': '无' },
    { 'id': '2', 'name': '购方名称', isSuccess: false, 'detail': '无' },
    { 'id': '3', 'name': '购方税号', isSuccess: false, 'detail': '不符合匹配要求' },
    { 'id': '4', 'name': '销方名称', isSuccess: false, 'detail': '不符合匹配要求' },
    { 'id': '5', 'name': '物料组与税收分类', isSuccess: false, 'detail': '不符合匹配要求' },
    { 'id': '6', 'name': '物料说明与开票名称、规格型号', isSuccess: false, 'detail': '不符合匹配要求' },
    { 'id': '7', 'name': '税率', isSuccess: true, 'detail': '不符合匹配要求' },
    { 'id': '8', 'name': '数量', isSuccess: true, 'detail': '不符合匹配要求' },
    { 'id': '9', 'name': '不含税金额', isSuccess: true, 'detail': '不允许手动匹配' },
    { 'id': '10', 'name': '含税金额', isSuccess: true, 'detail': '不符合匹配要求' },
    { 'id': '11', 'name': '税额差', isSuccess: true, 'detail': '不符合匹配要求' },
  ]
  @ViewChild('errorSt', { static: false }) errorSt: STComponent;

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
  ) { }

  ngOnInit(): void {

  }

  close() {
    this.modal.destroy();
  }
}
