import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { clientConfig } from './../../../../../../environments/client-config';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-configuration-corporate-customer-rules-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less'],
})
export class OutputConfigurationCorporateCustomerRulesEditComponent implements OnInit {
  loading = false;
  rulesId = this.route.snapshot.queryParams.rulesId;
  deptName = this.route.snapshot.queryParams.deptName;
  i: any;
  data: any = {
    'rulesId': '',
    'amount': '',
    'invoiceEntity': '',
    'printedQuantity': '',
    'payee': '',
    'reviewer': '',
    'drawer': '',
    'remark': '',
  };
  invoiceCarrierOption = clientConfig.invoiceCarrierOption; // 发票载体

  constructor(
    private route: ActivatedRoute,
    public location: Location,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private router: Router,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.getInfo();
  }

  // 获取规则信息
  getInfo() {
    this.loading = true;
    this.http.get(`/output/companyrules/info/${this.rulesId}`).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.data = res.companyRules;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 保存规则信息
  save() {
    this.loading = true;
    this.http.post('/output/companyrules/update', this.data).subscribe(res => {
      this.loading = false;
      const flag = this.dialogService.getErrorCode(res);
      if (flag) {
        this.msgSrv.success('操作成功！');
        this.router.navigate(['/output/corporate-customer-rules/view'], { queryParams: { rulesId: this.rulesId, deptName: this.deptName } });
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }
}
