import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { Router } from '@angular/router';
import { OutputConfigurationSubjectEditComponent } from './edit/edit.component';
import { NzMessageService } from 'ng-zorro-antd';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-configuration-subject',
  templateUrl: './subject.component.html',
  styles: [
    `
      :host ::ng-deep .search__form .ant-form-item>.ant-form-item-label {
        width: 30%;
      }
    `,
  ],
})
export class OutputConfigurationSubjectComponent implements OnInit {
  loading = false;
  rows: any = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '企业科目', index: 'name', i18n: 'output.subject.enterpriseSubjects' },
    { title: '标准会计科目', index: 'standardAccountName', i18n: 'output.subject.standardAccountName' },
    { title: '所属企业', index: 'deptName', i18n: 'output.subject.deptName' },
    {
      title: '操作',
      i18n: 'st.operate',
      buttons: [
        {
          text: '编辑',
          i18n: 'st.operate.edit',
          click: (item: any) => this.edit(item)
        },
        {
          text: '删除',
          i18n: 'st.operate.delete',
          pop: true,
          popTitle: '确认要进行删除操作吗？',
          click: (item: any) => this.delete(item.subjectId)
        }
      ]
    }
  ];
  queryParams: any = {
    page: 1,
    limit: 10,
    subjectName: '',
    standardAccountId: '',
    deptName: '',
  };
  pageTotal = 0;
  // 标准会计科目
  accountingSubjectsOption = [];


  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private msg: NzMessageService,
    private router: Router,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getList();
    this.getStandardAccountList();
  }

  // 获取列表
  getList() {
    this.loading = true;
    this.http.get('/output/subjects/list', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 获取标准会计准则列表
  getStandardAccountList() {
    this.loading = true;
    this.http.get('/output/standardaccount/alllist').subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.accountingSubjectsOption = data.list;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 添加科目
  add() {
    this.modal.static(OutputConfigurationSubjectEditComponent, { i: { ptype: 0 } }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.queryParams.standardAccountId = this.queryParams.standardAccountId || '';
    this.getList();
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      subjectName: '',
      standardAccountId: '',
      deptName: '',
    };
    this.getList();
  }

  // 编辑
  edit(item) {
    this.modal.static(OutputConfigurationSubjectEditComponent, { i: { ptype: 1, selectedRows: item } }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 删除
  delete(subjectId) {
    this.loading = true;
    this.http.delete('/output/subjects/delete?subjectIds=' + subjectId).subscribe(res => {
      this.loading = false;
      const flag = this.dialogService.getErrorCode(res);
      if (flag) {
        this.msg.success('操作成功！');
        this.getList();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
  }

}
