import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STData } from '@delon/abc';
import { DialogService } from 'src/app/service/dialog.service';
import * as format from 'date-fns/format';

@Component({
  selector: 'app-input-ledger-bank',
  templateUrl: './ledger-bank.component.html',
  styleUrls: ['./ledger-bank.component.less'],
})
export class InputLedgerBankComponent implements OnInit {
  show = false;
  more = 'down';
  rows: any = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '发票代码', index: 'invoiceCode', i18n: 'st.invoiceCode' },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'st.invoiceNumber' },
    { title: '发票类型', index: 'invoiceType', render: 'invoiceType', i18n: 'st.invoiceEntity' },
    { title: '开票日期', index: 'billingDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceCreateDate' },
    { title: '购方企业名称', index: 'purchaserName', i18n: 'st.invoicePurchaserCompany' },
    { title: '购方税号', index: 'purchaserTaxNo', i18n: 'st.invoicePurchaserParagraph' },
    { title: '销方企业名称', index: 'salesTaxName', i18n: 'st.invoiceSellCompany' },
    { title: '销方税号', index: 'salesTaxNo', i18n: 'st.invoiceSellParagraph' },
    { title: '税额', index: 'totalTax', i18n: 'st.invoiceTaxPrice' },
    { title: '金额', index: 'totalAmount', i18n: 'st.ledgerBank.amount' },
    { title: '金税发票状态', index: 'state', render: 'state', i18n: 'st.ledgerBank.state' },
    { title: '认证所属期', index: 'deductiblePeriod', i18n: 'st.ledgerBank.period' },
    { title: '认证日期', index: 'deductibleDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.ledgerBank.deductiblePeriod' },
    { title: '认证类型', index: 'deductibleMode', render: 'deductibleMode', i18n: 'st.ledgerBank.deductibleMode' },
    { title: '认证方式', index: 'deductibleType', render: 'deductibleType', i18n: 'st.ledgerBank.deductibleType' },
    { title: '金税系统同步时间', index: 'syncTime', i18n: 'st.ledgerBank.syncTime' },
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    invoiceCode: '',
    invoiceNumber: '',
    deductible: '',
    period: '',
    purchaserName: '',
    salesTaxName: '',
    billingDate: '',
    deductiblePeriod: '',
  };
  pageTotal = 0;
  loading = false;


  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getList();
  }

  // 获取列表
  getList() {
    this.loading = true;
    this.http.get('/input/invoicesync/list', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 同步刷新
  synchronousRefresh() {
    // 待定
    this.getList();
  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      invoiceCode: '',
      invoiceNumber: '',
      deductible: '',
      period: '',
      purchaserName: '',
      salesTaxName: '',
      billingDate: '',
      deductiblePeriod: '',
    };
    this.getList();
  }

  // 开票日期事件
  invoiceCreateDateChange(event) {
    this.queryParams.billingDate = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 认证所属期事件
  periodChange(event) {
    this.queryParams.period = format(event, 'YYYY-MM');
  }

  // 认证日期事件
  deductiblePeriodChange(event) {
    this.queryParams.deductiblePeriod = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 展开
  showOrHidden() {
    this.show = !this.show;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.show) {
      this.more = 'up';
    } else {
      this.more = 'down';
    }
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
  }

}
