import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService, NzModalService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-invoiced-invalid',
  templateUrl: './invalid.component.html',
})
export class OutputInvoicedInvalidComponent implements OnInit {
  i: any;
  loading = false;
  invalidData: any = {
    invoiceIds: "",
    reason: "",
  }; // 作废信息


  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    private modalService: NzModalService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    if (this.i.invoiceId)
      this.invalidData.invoiceIds = this.i.invoiceId;
  }

  // 作废
  invalid() {
    this.loading = true;
    this.http.post('/output/invoice/invalid', {}, this.invalidData).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.modal.close(true);
        this.msgSrv.success('操作成功');
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  close() {
    this.modal.destroy();
  }
}
