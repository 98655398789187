import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';
import { AdminRoleEditComponent } from './edit/edit.component';
import { AdminRoleViewComponent } from './view/view.component';
import { DialogService } from 'src/app/service/dialog.service';
import { AdminRoleEditRoleComponent } from './edit-role/edit-role.component';

@Component({
  selector: 'app-admin-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.less'],
})
export class AdminRoleComponent implements OnInit {
  rolerows: any = []; // 角色列表
  menurows: any = []; // 权限列表
  roleIds: any = []; // 删除角色Id列表
  loading = false;

  @ViewChild('st1', { static: false }) st1: STComponent;
  columns1: STColumn[] = [
    { title: '角色名称', index: 'roleName', i18n: 'role.list.roleName' },
    { title: '角色权限', index: 'menuIdList', render: 'menuIdList', i18n: 'role.list.menuIdList' },
    { title: '创建时间', index: 'createTime', type: 'date', dateFormat: 'YYYY-MM-DD HH:mm:ss', i18n: 'st.createTime' },
    {
      title: '操作',
      i18n: 'st.operate',
      className: 'table-actions',
      buttons: [
        {
          text: '查看',
          i18n: 'st.operate.view',
          type: 'modal',
          size: 1100,
          component: AdminRoleViewComponent,
          paramName: 'i',
          acl: { ability: ['sys:role:info'] },
          click: () => this.getRoleList(),
        },
        {
          text: '编辑',
          i18n: 'st.operate.edit',
          type: 'modal',
          size: 1100,
          component: AdminRoleEditRoleComponent,
          paramName: 'i',
          acl: { ability: ['sys:role:update'] },
          click: () => this.getRoleList(),
        },
        {
          text: '删除',
          i18n: 'st.operate.delete',
          pop: true,
          popTitle: '删除操作不可恢复，确认删除吗？',
          iif: (item) => item.roleName !== 'Admin',
          acl: { ability: ['sys:role:delete'] },
          click: (item: any) => this.deleteRole(item.roleId),
        }
      ],
    },
  ];

  @ViewChild('st2', { static: false }) st2: STComponent;
  columns2: STColumn[] = [
    { title: '权限名称', index: 'name', format: (item: any) => `[${item.parentName}]${item.name}`, i18n: 'permissions.list.name' },
    { title: '权限码', index: 'perms', i18n: 'permissions.list.perms' },
    /* {
      title: '操作',
      className: 'table-actions',
      buttons: [
        {
          text: '编辑',
          type: 'modal',
          size: 500,
          component: '',
          paramName: 'i',
          click: () => this.msg.info('回调，重新发起列表刷新'),
        }
      ],
    }, */
  ];

  saleTabs: any[] = [{ key: 'role', id: 1, show: true }, { key: 'permissions', id: 2 }];
  // 角色列表查询参数
  roleParams: any = {
    page: 1,
    limit: 10,
    roleName: '',
  };
  delParams: any = {
    roleIds: "",
  }
  rolePageTotal: any = 0;
  // 权限列表查询参数
  menuParams: any = {
    page: 1,
    limit: 10,
    name: "",
  };
  menuPageTotal: any = 0;

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private modalSrv: NzModalService,
    public msg: NzMessageService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this.getRoleList();
    this.getMenuList();
  }

  // 获取角色列表
  getRoleList() {
    this.loading = true;
    this.http.get('/sys/role/list', this.roleParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rolerows = data.page.list;
        this.roleParams.page = data.page.currPage;
        this.roleParams.limit = data.page.pageSize;
        this.rolePageTotal = data.page.totalCount;
        this.cdr.detectChanges();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 搜索角色列表
  searchRole() {
    this.roleParams.page = 1;
    this.getRoleList();
  }

  // 获取初始化权限菜单列表
  getMenuList() {
    this.loading = true;
    this.http.get('/sys/menu/list', this.menuParams).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.msg === 'success') {
        if (this.menuParams.name === '') {
          // 获取所有菜单和功能权限列表(type=0为目录)
          const menuList = res.menuList.filter(item => item.type !== 0);
          this.menurows = menuList;
        } else {
          // 搜索，则直接显示搜索列表结果
          this.menurows = res.menuList;
        }
        this.cdr.detectChanges();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 搜索权限列表
  searchMenu() {
    this.menuParams.page = 1;
    this.getMenuList();
  }

  // 创建角色
  add() {
    this.modal.static(AdminRoleEditRoleComponent, { i: { id: 0 } }, 1100, { nzWrapClassName: 'edit-role-modal' }).subscribe((success) => {
      if (success) {
        this.getRoleList();
      }
    });
  }

  // 单个删除角色
  deleteRole(roleId) {
    this.loading = true;
    this.delParams.roleIds = roleId;
    this.http.delete('/sys/role/delete', this.delParams).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msg.info('删除成功');
        this.getRoleList();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    })
  }

  salesChange(idx: number) {
    if (this.saleTabs[idx].show !== true) {
      this.saleTabs[idx].show = true;
      this.cdr.detectChanges();
    }
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.roleParams.page !== event.pi) {
        this.roleParams.page = event.pi;
        this.getRoleList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.roleParams.limit !== event.ps) {
        this.roleParams.limit = event.ps;
        this.roleParams.page = 1;
        this.getRoleList();
      }
    }
  }

  // 分页
  pagechange2(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.menuParams.page !== event.pi) {
        this.menuParams.page = event.pi;
        this.getMenuList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.menuParams.limit !== event.ps) {
        this.menuParams.limit = event.ps;
        this.menuParams.page = 1;
        this.getMenuList();
      }
    }
  }

}
