import { Component, OnInit } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { NzModalRef } from 'ng-zorro-antd';

@Component({
  selector: 'app-input-purchase-order-upload-tip',
  templateUrl: './upload-tip.component.html',
})
export class InputPurchaseOrderUploadTipComponent implements OnInit {
  i: any;
  errorMsg = []; // 上传错误提示信息

  constructor(
    private http: _HttpClient,
    private modal: NzModalRef,
  ) { }

  ngOnInit() {
    // 上传失败信息获取
    if (!this.i.ptype) {
      this.errorMsg = this.i.msg;
    }
  }

  close() {
    this.modal.destroy();
  }

}
