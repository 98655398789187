import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NzModalRef, NzMessageService, NzModalService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { STComponent, STColumn, STData } from '@delon/abc';
import { clientConfig } from '@env/client-config';
import { DialogService } from 'src/app/service/dialog.service';

interface Invoice {
  invoiceCode: string
  invoiceNumber: string
  invoiceType: string
  invoiceEntity: string
  purchaseAddress: string
  purchaseBank: string
  purchaseBankAccount: string
  purchaseContact: string
  purchaseName: string
  purchaseTaxCode: string
  remark: string
  sellerAddress: string
  sellerBank: string
  sellerBankAccount: string
  sellerContact: string
  sellerName: string
  sellerTaxCode: string
  taxAmount: number
  taxRate: number
  totalAmount: number
  excludingTaxAmount: number
  excludingTaxDiscountAmount: number
  applyUser: string
  payee: string
  reviewer: string
  drawer: string
  province: string
  invoiceGoodsList: []
}

@Component({
  selector: 'app-output-nis-billing-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less'],
})
export class OutputNisBillingEditComponent implements OnInit {
  loading = false;
  record: any = {};
  i: any;
  applyId: any = ''; // 申请id
  invoiceId: any = ''; // 发票id
  ptype = 1; // 默认1-发票查看页面；2-申请审批页面；3-审批流程查看；4-红冲发票操作页面；5-红冲审批；6-作废审批
  saleTabs: any[] = [{ key: 'info', id: 1, show: true }, { key: 'invoice', id: 2 }, { key: 'order', id: 3 }];
  taxpayerTypeOption = [
    { value: '一般纳税人', label: '一般纳税人' },
    { value: '个体工商户', label: '个体工商户' },
    { value: '企业纳税人', label: '企业纳税人' },
  ] // 纳税人类型
  invoiceTypeOption = clientConfig.invoiceTypeOption;
  invoiceCarrierOption = clientConfig.invoiceCarrierOption;
  // 基本信息
  data: any = {
    orderId: "",
    taxpayerType: "",
    invoiceType: "",
    invoiceEntity: "",
    preformedInvoice: 0, // 是否预制发票
    invoiceRemark: "",
    applyType: '0', // 申请类型：0-订单开票
  }
  // 重新申请开票信息
  reapplyData: any = {
    invoice: {}, // 发票信息
    invoiceGoods: [], // 开票商品列表
  };
  // 发票明细数据
  invoiceInfo: Invoice = {
    invoiceCode: '',
    invoiceNumber: '',
    invoiceType: "",
    invoiceEntity: "",
    purchaseAddress: '',
    purchaseBank: '',
    purchaseBankAccount: '',
    purchaseContact: '',
    purchaseName: '',
    purchaseTaxCode: '',
    remark: '',
    sellerAddress: '',
    sellerBank: '',
    sellerBankAccount: '',
    sellerContact: '',
    sellerName: '',
    sellerTaxCode: '',
    taxAmount: 0,
    taxRate: 0,
    totalAmount: 0,
    excludingTaxAmount: 0,
    excludingTaxDiscountAmount: 0,
    applyUser: '',
    payee: '',
    reviewer: '',
    drawer: '',
    province: '',
    invoiceGoodsList: []
  };
  // 订单明细
  orderList = [] // 订单列表
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '订单编号', index: 'orderNumber', i18n: 'output.st.orderNumber', width: 100 },
    { title: '订单日期', index: 'createTime', type: 'date', dateFormat: 'YYYYMMDD', i18n: 'output.st.orderDate', width: 120 },
    { title: '购方名称', index: 'purchaseName', i18n: 'output.st.purchaseName', width: 150 },
    { title: '销方名称', index: 'sellerName', i18n: 'output.st.sellerName', width: 150 },
    { title: '总金额（不含税）', index: 'excludingTaxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.excludingTaxAmount', width: 200 },
    { title: '税额', index: 'taxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.taxAmount', width: 100 },
    // { title: '折扣方式', index: 'discountType', i18n: 'output.st.discountType', width: 100 },
    // { title: '折扣总额', index: 'excludingTaxDiscountAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.excludingTaxDiscountAmount', width: 100 },
    { title: '合计（含税）', index: 'totalAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.totalAmount', width: 120 },
    { title: '票据类型', index: 'invoiceEntity', i18n: 'output.st.orderInvoiceType', width: 100 },
    { title: '订单状态', index: 'status', render: 'status', i18n: 'output.st.orderStatus', fixed: 'right', width: 100 },
  ];
  orderId: any = ''; // 订单id

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private cdr: ChangeDetectorRef,
    private modalHelper: ModalHelper,
    private modalService: NzModalService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.ptype = this.i.ptype;
    if (this.i.selectedRows) {
      this.invoiceId = this.i.selectedRows.invoiceId || '';
      this.applyId = this.i.selectedRows.applyId || '';
      this.orderId = this.i.selectedRows.orderId || '';
    }
    // 初始化信息
    this.getApplyInfo();
    this.getInvoiceInfo();
    this.getOrderInfo();
  }

  // 获取申请购方信息
  getApplyInfo() {
    this.loading = true;
    this.http.get(`/output/invoiceapply/info/${this.applyId}`).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.data = data.invoiceApply;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 获取发票明细信息
  getInvoiceInfo() {
    this.loading = true;
    this.http.get(`/output/invoice/info/${this.invoiceId}`).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.invoiceInfo = data.invoice;
        if (data.invoice.invoiceGoodsList.length > 0) {
          this.reapplyData.invoiceGoods = data.invoice.invoiceGoodsList;
        }
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 获取订单列表信息
  getOrderInfo() {
    this.loading = true;
    this.http.get(`/output/order/info/${this.orderId}`).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.orderList = data.order;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  save() {
    if (this.ptype === 0) {
      // 修改
      this.update();
    } else if (this.ptype === 1) {
      // 重新申请
      this.reApply();
    }
  }

  // 编辑操作
  update() {
    this.msgSrv.success('操作成功！');
    this.modal.close(true);
  }

  // 重新申请
  reApply() {
    this.reapplyData.invoice = this.invoiceInfo;
    this.loading = true;
    this.http.post('/output/invoice/update', this.reapplyData).subscribe(res => {
      this.loading = false;
      const flag = this.dialogService.getErrorCode(res);
      if (flag) {
        this.msgSrv.success('操作成功！');
        this.modal.close(true);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 是否预制事件
  preformedInvoiceChange(event) {
    this.data.preformedInvoice = event ? 1 : 0;
  }

  // 默认第一个列表项展开
  expandDataChange(data: STData[]) {
    return data.map((i: STData, index: number) => {
      i.expand = index === 0;
      return i;
    });
  }

  // tab切换事件
  salesChange(idx: number) {
    if (this.saleTabs[idx].show !== true) {
      this.saleTabs[idx].show = true;
      this.cdr.detectChanges();
    }
  }

  close() {
    this.modal.destroy();
  }
}
