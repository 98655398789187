import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STData } from '@delon/abc';
import { InputPurchaseOrderRefundComponent } from '../../purchase-order/refund/refund.component';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';
import { DialogService } from 'src/app/service/dialog.service';
import * as format from 'date-fns/format';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { environment } from '@env/environment';
import { clientConfig } from './../../../../../environments/client-config';

@Component({
  selector: 'app-input-expense-account-credit',
  templateUrl: './credit.component.html',
  styleUrls: ['./credit.component.less'],
})
export class InputExpenseAccountCreditComponent implements OnInit {

  show = false;
  more = 'down';
  selectedRows: STData[] = []; // 选中数据
  rows: any = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '报销单号', index: 'expenseNumber', i18n: 'st.expenseNumber', width: 200 },
    { title: '申请者', index: 'applyUser', i18n: 'st.applyUser', width: 100 },
    { title: '报销者', index: 'endUser', i18n: 'st.endUser', width: 100 },
    { title: '申请时间', index: 'applyTime', i18n: 'st.applyTime', type: 'date', dateFormat: 'YYYY-MM-DD HH:mm:ss', width: 200 },
    { title: '报销金额', index: 'amount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.amount', width: 100 },
    { title: '已关联票据金额', index: 'invoicesAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoicesAmount', width: 200 },
    { title: '报销单状态', index: 'expenseStatus', i18n: 'st.expenseStatus', render: 'expenseStatus', width: 200 },
  ];
  // 嵌套发票列表
  @ViewChild('expendst', { static: false }) expendst: STComponent;
  expendColumns: STColumn[] = [
    { title: '', index: 'invoiceNumber', type: 'checkbox', className: 'st-checkbox' },
    { title: '发票代码', index: 'invoiceCode', i18n: 'st.invoiceCode', width: 100 },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'st.invoiceNumber', width: 100 },
    { title: '购方企业名称', index: 'invoicePurchaserCompany', i18n: 'st.invoicePurchaserCompany', width: 200 },
    { title: '购方税号', index: 'invoicePurchaserParagraph', i18n: 'st.invoicePurchaserParagraph', width: 100 },
    { title: '销方企业名称', index: 'invoiceSellCompany', i18n: 'st.invoiceSellCompany', width: 200 },
    { title: '销方税号', index: 'invoiceSellParagraph', i18n: 'st.invoiceSellParagraph', width: 100 },
    { title: '票面金额(含税)', index: 'invoiceTotalPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTotalPrice', width: 200 },
    { title: '票面金额(不含税)', index: 'invoiceFreePrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceFreePrice', width: 200 },
    { title: '税额', index: 'invoiceTaxPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTaxPrice', width: 80 },
    { title: '校验码', index: 'invoiceCheckCode', i18n: 'st.invoiceCheckCode', width: 100 },
    { title: '开票日期', index: 'invoiceCreateDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceCreateDate', width: 120 },
    { title: '发票类型', index: 'invoiceEntity', i18n: 'st.invoiceEntity', width: 100 },
    { title: '上传日期', index: 'invoiceUploadDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceUploadDate', width: 120 },
    { title: '上传人', index: 'createUserName', i18n: 'st.createUserName', width: 100 },
    { title: '发票状态', index: 'invoiceStatus', i18n: 'st.invoiceStatus', render: 'invoiceStatus', fixed: 'right', width: 100 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 170,
      buttons: [
        /* {
          text: '导出',
          i18n: 'st.operate.export',
          paramName: 'i',
          click: (item: any) => this.export(item.id),
        }, */
        {
          text: '人工入账',
          i18n: 'st.operate.manualEntry',
          paramName: 'i',
          click: (item: any) => this.manualEntry(item.id),
        },
        {
          text: '...',
          paramName: 'i',
          children: [
            {
              text: '退票',
              i18n: 'st.operate.refund',
              paramName: 'i',
              pop: true,
              popTitle: '确认要进行退票操作吗？',
              iif: item => item.invoiceStatus > 1 && item.invoiceStatus < 10,
              click: (item: any) => this.refund(item.id),
            },
            {
              text: '删除',
              i18n: 'st.operate.delete',
              paramName: 'i',
              pop: true,
              popTitle: '确认要进行删除操作吗？',
              click: (item: any) => this.delete(item.id),
            },
          ]
        },
      ]
    }
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    expenseNumber: '',
    expenseStatus: '',
    applyUser: '',
    endUser: '',
    applyTime: '',
    minAmount: '',
    maxAmount: '',
    invoiceUploadDate: '',
    createUserName: '',
    invoicePurchaserCompany: '',
    invoiceSellCompany: '',
    invoiceCode: '',
    invoiceNumber: '',
    invoiceEntity: '',
    invoiceCreateDate: '',
    invoiceStatus: '',
    invoiceTotalPriceBegin: '',
    invoiceTotalPriceEnd: '',
    menuType: '4', // 报销单-入账列表参数
  };
  applyTime: '';
  invoiceUploadDate: '';
  invoiceCreateDate: '';
  // 报销单状态
  expenseStatusOption = [
    { value: 0, label: '待报销' },
    { value: 1, label: '已报销' },
    { value: 2, label: '超额' },
    { value: 3, label: '异常' },
  ]
  // 入账发票状态
  invoiceStatusOption = clientConfig.creditInvoiceStatusOption;
  pageTotal = 0;
  loading = false;


  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private msgSrv: NzMessageService,
    private modalSrv: NzModalService,
    private dialogService: DialogService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) { }

  ngOnInit() {
    this.getList();
  }

  // 获取列表
  getList() {
    this.loading = true;
    this.http.get('/input/oaexpenseinfo/list', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 导出
  export(id) {

  }

  // 导出入账发票信息
  bulkExport() {
    if (this.selectedRows.length > 0) {
      this.exportById();
    } else {
      this.exportBySearch();
    }
  }

  // 按所选数据导出
  exportById() {
    const ids = this.selectedRows.map(item => item.id).join(',');
    const token = this.tokenService.get().token;
    window.open(environment.SERVER_URL + "/input/invoice/exportRecordListByIds?invoiceIds=" +
      ids +
      "&title=发票总览列表" +
      "&Authorization=" + token
    );
    this.selectedRows = [];
    this.st.reload();
  }

  // 按查询条件导出
  exportBySearch() {
    const token = this.tokenService.get().token;
    window.open(environment.SERVER_URL + "/input/invoice/exportRecordList?invoiceCode=" +
      this.queryParams.invoiceCode +
      "&invoiceNumber=" +
      this.queryParams.invoiceNumber +
      "&invoicePurchaserCompany=" +
      this.queryParams.invoicePurchaserCompany +
      "&invoiceSellCompany=" +
      this.queryParams.invoiceSellCompany +
      "&invoiceCreateDateArray=" +
      this.queryParams.invoiceCreateDateArray +
      "&invoiceStatus=" +
      this.queryParams.invoiceStatus +
      "&invoiceEntity=" +
      this.queryParams.invoiceEntity +
      "&invoiceUploadDateArray=" +
      this.queryParams.invoiceUploadDateArray +
      "&invoiceTotalPriceBegin=" +
      this.queryParams.invoiceTotalPriceBegin +
      "&invoiceTotalPriceEnd=" +
      this.queryParams.invoiceTotalPriceEnd +
      "&invoiceFromto=采购订单" +
      "&createUserName=" +
      this.queryParams.createUserName +
      "&invoiceBatchNumber=" +
      this.queryParams.invoiceBatchNumber +
      "&Authorization=" + token
    );
  }

  // 人工入账
  manualEntry(id) {
    this.loading = true;
    this.http.post('/input/invoice/manualEntry?invoiceIds=' + id).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('人工入账成功');
        this.getList();
      } else {
        this.st.reload();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 批量入账
  bulkManualEntry() {
    this.modalSrv.confirm({
      nzContent: '确认要进行<font color="red">人工入账</font>操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          const idList = [];
          this.selectedRows.map(item => {
            idList.push(item.id);
          })
          const ids = idList.join(',');
          this.manualEntry(ids);
          this.selectedRows = [];
          resolve(true);
        }).catch(() => console.log('人工入账失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          this.selectedRows = [];
          this.st.reload();
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 退票
  refund(id) {
    this.modal.static(InputPurchaseOrderRefundComponent, { i: { invoiceId: id } }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 批量退票
  bulkRefund() {
    this.modalSrv.confirm({
      nzContent: '确认要进行<font color="red">退票</font>操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          // 处理批量退票发票id
          const idList = [];
          this.selectedRows.map(item => {
            idList.push(item.id);
          })
          const ids = idList.join(',');
          this.refund(ids);
          this.selectedRows = [];
          resolve(true);
        }).catch(() => console.log('退票失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          this.selectedRows = [];
          this.st.reload();
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 删除
  delete(id) {
    this.loading = true;
    const idList = Array.isArray(id) ? id : [id];
    this.http.post('/input/invoice/delete', (idList)).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('删除成功');
        this.getList();
      } else {
        this.st.reload();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 批量删除
  bulkDelete() {
    this.modalSrv.confirm({
      nzContent: '确认要进行<font color="red">删除</font>操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          // 处理获取的数据id
          const idList = new Array();
          this.selectedRows.map(item => {
            idList.push(item.id);
          })
          this.delete(idList);
          this.selectedRows = [];
          resolve(true);
        }).catch(() => console.log('删除失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          this.selectedRows = [];
          this.st.reload();
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 多选框禁用
  disabledCheckbox(data: STData[]) {
    return data.map((i: STData, index: number) => {
      i.disabled = index === 0;
      return i;
    });
  }


  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      expenseNumber: '',
      expenseStatus: '',
      applyUser: '',
      endUser: '',
      applyTime: '',
      minAmount: '',
      maxAmount: '',
      invoiceUploadDate: '',
      createUserName: '',
      invoicePurchaserCompany: '',
      invoiceSellCompany: '',
      invoiceCode: '',
      invoiceNumber: '',
      invoiceEntity: '',
      invoiceCreateDate: '',
      invoiceStatus: '',
      invoiceTotalPriceBegin: '',
      invoiceTotalPriceEnd: '',
      menuType: '4',
    };
    this.applyTime = '';
    this.invoiceCreateDate = '';
    this.invoiceUploadDate = '';
    this.getList();
  }

  // 申请时间处理事件
  applyTimeDateChange(event) {
    this.queryParams.applyTime = event.length > 0 ? format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD') : '';
  }

  // 开票日期处理事件
  invoiceUploadDateChange(event) {
    this.queryParams.invoiceUploadDate = event.length > 0 ? format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD') : '';
  }
  // 上传日期处理事件
  invoiceCreateDateChange(event) {
    this.queryParams.invoiceCreateDate = event.length > 0 ? format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD') : '';
  }

  // 展开
  showOrHidden() {
    this.show = !this.show;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.show) {
      this.more = 'up';
    } else {
      this.more = 'down';
    }
  }

  // 报销单默认展开事件
  expenseDataChange(data: STData[]) {
    return data.map((i: STData, index: number) => {
      i.expand = index === 0;
      return i;
    });
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.selectedRows = event.checkbox!;
    }
  }
}
