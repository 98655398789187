import { Component, OnInit, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STData } from '@delon/abc';
import { OutputRuleEditInfoComponent } from '../edit-info/edit-info.component';
import { clientConfig } from './../../../../../environments/client-config';
import { OutputRuleEditPriceComponent } from '../edit-price/edit-price.component';
import { OutputRuleEditCommodityComponent } from '../edit-commodity/edit-commodity.component';
import { NzMessageService } from 'ng-zorro-antd';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogService } from 'src/app/service/dialog.service';
import { OutputRuleApplyComponent } from '../apply/apply.component';
import * as format from 'date-fns/format';
import { OutputRuleAddSpecialGoodsComponent } from '../add-special-goods/add-special-goods.component';

@Component({
  selector: 'app-output-rule-view-rule',
  templateUrl: './view-rule.component.html',
  styleUrls: ['./view-rule.component.less'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutputRuleViewRuleComponent implements OnInit {
  loading = false;
  ptype = 1; // 默认右侧显示基本信息 1-基本信息，2-开票规则，3-商品规则，4-开票编辑
  clientList: any[] = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '', index: 'name' },
  ];
  queryParams: any = {
    name: '',
  };
  // 基本信息
  clientData: any = {
    name: '',
    taxpayerType: '',
    taxCode: '',
    address: '',
    contact: '',
    bank: '',
    bankAccount: '',
  }
  // 开票规则
  billingData: any = {
    customerId: '',
    deptId: '',
    amount: '',
    autoInvoicing: '',
    preformedInvoice: '',
    needSalesList: '',
    expireDate: '',
    invoiceType: '',
    invoiceEntity: '',
    remark: '',
    invoicingFrequency: '',
    invoicingTime: '',
  };
  oldBillingData: any = [];
  invoicingFrequency = []; // 开票频率
  invoicingTime: any = new Date(); // 开票时间
  // 特定开票规则列表
  billingLoading = false;
  specialBillingList: any = [];
  @ViewChild('specialBillingST', { static: false }) specialBillingST: STComponent;
  specialBillingColumns: STColumn[] = [
    { title: '商品编号', index: 'goodsNumber', i18n: 'output.commodityRule.goodsNumber' },
    { title: '商品名称', index: 'goodsName', i18n: 'output.commodityRule.goodsName' },
    { title: '是否自动开票', index: 'autoInvoicing', i18n: 'output.commodityRule.autoInvoicing', render: 'autoInvoicing' },
    { title: '是否预制发票', index: 'preformedInvoice', i18n: 'output.commodityRule.preformedInvoice', render: 'preformedInvoice' },
    {
      title: '操作',
      i18n: 'st.operate',
      iif: record => this.ptype === 4,
      buttons: [
        {
          text: '删除',
          i18n: 'st.operate.delete',
          click: record => this.delete(record.id),
        },
      ]
    }
  ];
  // 特定产品信息
  specialRulesGoodData: any = {
    rulesId: '',
    goodsId: '',
    goodsNumber: '',
    goodsName: '',
    autoInvoicing: 0,
    preformedInvoice: 0,
  }
  billingFrequencyOption = clientConfig.billingFrequencyOption // 开票频率周期
  invoiceTypeOption = clientConfig.invoiceTypeOption; // 开票规则-发票类型
  invoiceCarrierOption = clientConfig.invoiceCarrierOption; // 开票规则-发票载体
  // 商品规则信息
  commodityList: any = [];
  @ViewChild('commodityST', { static: false }) commodityST: STComponent;
  commodityColumns: STColumn[] = [
    { title: '商品编码', index: 'goods.goodsNumber', i18n: 'output.commodityRule.goodsNo' },
    { title: '商品名称', index: 'goods.goodsName', i18n: 'output.commodityRule.goodsName' },
    { title: '商品税率', index: 'goods.taxRate', i18n: 'output.commodityRule.taxRate', render: 'taxRate' },
    { title: '是否参与折扣分摊', index: 'goods.preferential', i18n: 'output.commodityRule.preferential', render: 'preferential' },
    { title: '商品单价', index: 'amount', i18n: 'output.commodityRule.amount', type: 'number', numberDigits: '.2-2', className: 'text-left' },
    {
      title: '操作',
      i18n: 'st.operate',
      buttons: [
        /* {
          text: '查看',
          i18n: 'st.operate.view',
          type: 'link',
          click: record => this.viewCommodity(record.id), // todo-待确认接口
        }, */
        {
          text: '编辑单价',
          i18n: 'st.operate.editPrice',
          click: record => this.editPrice(record),
        },
      ]
    }
  ];
  commodityQueryParams: any = {
    page: 1,
    limit: 15,
    customerId: '',
    keywords: '',
  };
  commodityPageTotal = 0; // 商品规则列表总数
  deptId: any = '';
  customerId: any = ''; // 客户id
  rulesId: any = ''; // 开票规则id
  rightLoading = false;

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private msg: NzMessageService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.deptId = this.route.snapshot.queryParams.deptId;
    this.getList();
  }

  // 获取客户列表
  getList() {
    this.loading = true;
    this.http.get('/output/customer/list?deptId=' + this.deptId).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.clientList = data.list;
        // 获取默认显示第一个公司基本信息
        if (this.clientList[0]) {
          this.clientData = this.clientList[0];
        }
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 新增客户
  addClient() {
    this.modal.static(OutputRuleEditInfoComponent, { i: { ptype: 0, deptId: this.deptId } }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      }
    });
  }

  // 编辑客户基本信息
  editClient() {
    this.modal.static(OutputRuleEditInfoComponent, { i: { ptype: 1, client: this.clientData } }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      }
    });
  }

  // 客户列表搜搜
  search() {

  }

  // 查看客户-基本信息
  viewInfo(customerId) {
    this.ptype = 1; // ptype:1 - 基本信息
    this.customerId = customerId;
    this.rightLoading = true;
    this.http.get(`/output/customer/info/${customerId}`).subscribe(data => {
      this.rightLoading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.clientData = data.customer;
      }
    }, error => {
      this.rightLoading = false;
      console.log('HTTP Error', error);
    });
  }

  // 查看客户-开票规则
  viewInvoiceRule(customerId) {
    this.ptype = 2; // ptype:2 - 开票规则查看
    this.customerId = customerId;
    this.rightLoading = true;
    this.http.get(`/output/invoicerules/getRulesByCustomerId/${customerId}`).subscribe(data => {
      this.rightLoading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        if (data.data) {
          this.billingData = data.data;
          if (data.data.rulesId) {
            this.rulesId = data.data.rulesId;
          }
          // 特定商品列表
          if (data.data.specialRulesGoods) {
            this.specialBillingList = data.data.specialRulesGoods;
          }
          // 开票频率
          if (data.data.invoicingFrequency) {
            this.invoicingFrequency = data.data.invoicingFrequency.split(','); // 将开票频率字符转转为数组处理
          }
          // 开票时间
          if (data.data.invoicingTime) {
            this.invoicingTime = new Date(format(new Date(), 'YYYY-MM-DD') + ' ' + data.data.invoicingTime); // 将HH:mm字符串转换成当天对应时间
          }
        } else {
          // 新增客户-开票规则没有信息，初始化新增信息
          // 清空开票数据
          this.billingData = {
            customerId: this.customerId,
            deptId: this.deptId,
            amount: '',
            autoInvoicing: '',
            preformedInvoice: '',
            needSalesList: '',
            expireDate: '',
            invoiceType: '',
            invoiceEntity: '',
            remark: '',
            invoicingFrequency: '',
            invoicingTime: '00:00',
          };
          this.specialBillingList = [];
          this.cdr.detectChanges();
        }
      }
    }, error => {
      this.rightLoading = false;
      console.log('HTTP Error', error);
    });
  }

  // 查看客户-商品规则
  viewCommodityList(customerId) {
    this.ptype = 3; // ptype:3 - 商品规则列表
    this.customerId = customerId;
    this.commodityQueryParams.customerId = customerId;
    this.getCommodityList();
  }

  // 查看客户信息规则
  viewContent(ptype) {
    this.ptype = ptype;
  }

  // 应用规则
  apply() {
    this.modal.static(OutputRuleApplyComponent, { i: { rulesId: this.rulesId, clientList: this.clientList } }, 1100).subscribe((success) => {
    });
  }

  // 编辑开票规则
  editBilling() {
    this.ptype = 4;
    this.oldBillingData = { ...this.billingData };
  }

  // 更新开票规则信息
  updateBilling() {

  }

  // 取消-编辑开票规则
  cancel() {
    this.ptype = 2;
    this.billingData = this.oldBillingData;
  }

  // 保存-编辑开票规则
  saveBilling() {
    this.billingData.specialRulesGoods = this.specialBillingList;
    this.rightLoading = true;
    this.http.post('/output/invoicerules/update', this.billingData).subscribe(data => {
      this.rightLoading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.msg.success('操作成功！');
        this.viewInvoiceRule(this.customerId);
      }
    }, error => {
      this.rightLoading = false;
      console.log('HTTP Error', error);
    });
  }

  // 添加特定客户开票规则
  addSpecialBilling() {
    const specialRulesGoods = this.specialBillingList.map(item => item.goodsId); // 获取已有商品id
    this.modal.static(OutputRuleAddSpecialGoodsComponent, { i: { specialRulesGoods } }, 500).subscribe((goodsData) => {
      if (goodsData) {
        this.specialRulesGoodData.rulesId = this.rulesId;
        this.specialRulesGoodData.goodsId = goodsData.goodsId || '';
        this.specialRulesGoodData.goodsName = goodsData.goodsName || '';
        this.specialRulesGoodData.goodsNumber = goodsData.goodsNumber || '';
        this.specialBillingList.push(this.specialRulesGoodData);
        this.specialBillingST.reload();
      }
    });
  }

  // 删除特定客户开票规则
  delete(id) {
    this.specialBillingList.map((item, index) => {
      if (item.id === id) {
        this.specialBillingList.splice(index, 1); // 删除
        return;
      }
    })
    this.specialBillingST.reload();
    this.msg.success('删除成功');
  }

  // 获取商品列表
  getCommodityList() {
    this.billingLoading = true;
    this.http.get('/output/goodsrules/list', this.commodityQueryParams).subscribe(data => {
      this.billingLoading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.commodityList = data.page.list;
        this.commodityQueryParams.page = data.page.currPage;
        this.commodityQueryParams.limit = data.page.pageSize;
        this.commodityPageTotal = data.page.totalCount;
      }
    }, error => {
      this.billingLoading = false;
      console.log('HTTP Error', error);
    });
  }

  // 商品列表搜索
  commoditySearch() {
    this.commodityQueryParams.page = 1;
    this.getCommodityList();
  }

  // 添加商品
  addCommodity() {
    this.modal.static(OutputRuleEditCommodityComponent, { i: { ptype: 0 } }, 500).subscribe((success) => {
      if (success) {
        this.getCommodityList();
      }
    });
  }

  // 查看商品
  viewCommodity(id) {
    this.router.navigate(['/output/commodity/view'], { queryParams: { Id: id } });
  }

  // 编辑商品单价
  editPrice(item) {
    this.modal.static(OutputRuleEditPriceComponent, { i: item }, 500).subscribe((success) => {
      if (success) {
        this.getCommodityList();
      }
    });
  }
  // 是否需要销售清单
  needSalesListChange(event) {
    this.billingData.needSalesList = event ? 1 : 0;
  }

  // 是否自动开票
  autoInvoicingChange(event) {
    this.billingData.autoInvoicing = event ? 1 : 0;
  }

  // 是否预制发票事件
  preformedInvoiceChange(event) {
    this.billingData.preformedInvoice = event ? 1 : 0;
  }

  // 特殊商品规则- 是否自动开票
  specialAutoInvoicingChange(event, index) {
    this.specialBillingList[index].autoInvoicing = event ? 1 : 0;
    this.specialBillingST.reload();
  }

  // 特殊商品规则 - 是否预制发票事件
  specialPreformedInvoiceChange(event, index) {
    this.specialBillingList[index].preformedInvoice = event ? 1 : 0;
    this.specialBillingST.reload();
  }

  // 有效期事件
  expireDateChange(event) {
    this.billingData.expireDate = format(event, 'YYYY-MM-DD HH:mm:ss');
  }

  // 开票频率事件
  invoicingFrequencyModelChange(event) {
    this.billingData.invoicingFrequency = event.join(',');
  }

  // 开票时间事件
  invoicingTimeModelChange(event) {
    this.billingData.invoicingTime = format(event, 'HH:mm');
  }

  // 嵌套列表展开状态设置
  expendChange(data: STData[]) {
    return data.map((i: STData, index: number) => {
      i.expand = index <= 2;
      return i;
    });
  }

  // 分页-商品规则列表
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.commodityQueryParams.page !== event.pi) {
        this.commodityQueryParams.page = event.pi;
        this.getCommodityList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.commodityQueryParams.limit !== event.ps) {
        this.commodityQueryParams.limit = event.ps;
        this.commodityQueryParams.page = 1;
        this.getCommodityList();
      }
    }
  }

}
