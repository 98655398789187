import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NzModalRef, NzMessageService, NzCascaderOption } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { clientConfig } from './../../../../../environments/client-config';
import { SystemService } from 'src/app/service/system.service';
import { CacheService } from '@delon/cache';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-admin-organization-view',
  templateUrl: './view.component.html',
  styles: [
    `
      :host ::ng-deep .ant-form-item-label {
        padding-left: 20px;
      }
    `,
  ],
})
export class AdminOrganizationViewComponent implements OnInit {
  data: any = [];
  nzOptions: NzCascaderOption[] = [];
  regeion: string[] | null = null;
  i: any;
  accountingStandardsOption = clientConfig.accountingStandardsOption;
  vatTaxpayerOption = clientConfig.vatTaxpayerOption;
  loginMethodOption = clientConfig.loginMethodOption;
  treeList: any = []; // 地区原始数据
  deptTypeOption = clientConfig.deptTypeOption;
  loading = false;
  enabledYear: any = ''; // 启用年
  enabledMonth: any = ''; // 启用月
  saleTabs: any[] = [{ key: '基本信息', id: 1, show: true }, { key: '报税信息', id: 2 }, { key: '销项信息', id: 3 }];

  constructor(
    private modal: NzModalRef,
    public msgSrv: NzMessageService,
    public http: _HttpClient,
    private systemService: SystemService,
    private cache: CacheService,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.getInfo();
    this.treeList = this.cache.getNone('region');
    if (this.treeList === null) {
      this.treeList = await this.systemService.getRegionList();
    }
    this.nzOptions = this.generateTree(this.treeList);
    this.loading = false;
  }

  // 获取组织详情
  getInfo() {
    this.http.get(`/sys/dept/info/${this.i.deptId}`).subscribe(res => {
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.data = res.dept;
        // tslint:disable-next-line: prefer-conditional-expression
        if (this.data.provinceCode) {
          this.regeion = [this.data.provinceCode, this.data.cityCode, this.data.districtCode];
        } else {
          this.regeion = [this.data.cityCode, this.data.districtCode];
        }
        const curYear = new Date().getFullYear();
        const curMonth = new Date().getMonth() + 1;
        const curDate = new Date().getDate();
        // tslint:disable-next-line: prefer-conditional-expression
        if (this.data.thirdEnabledYear) {
          this.enabledYear = new Date(this.data.thirdEnabledYear + '-' + curMonth + '-' + curDate);
        }
        // tslint:disable-next-line: prefer-conditional-expression
        if (this.data.thirdEnabledMonth) {
          this.enabledMonth = new Date(curYear + '-' + this.data.thirdEnabledMonth + '-' + curDate);
        }
      }
    });
  }

  // 整理地区列表
  generateTree(treeList) {
    if (treeList.length > 0) {
      for (const node of treeList) {
        if (node.children.length > 0) {
          this.generateTree(node.children);
        } else {
          // 设置叶节点标识
          node.isLeaf = true;
        }
      }
    }
    return treeList;
  }

  // tab切换事件
  salesChange(idx: number) {
    if (this.saleTabs[idx].show !== true) {
      this.saleTabs[idx].show = true;
      this.cdr.detectChanges();
    }
  }

  close() {
    this.modal.destroy();
  }
}
