import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STComponent, STColumn } from '@delon/abc';
import { OutputNisBillingExcessRemindComponent } from '../excess-remind/excess-remind.component';
import { OutputNisBillingMergerInvoiceComponent } from '../merger-invoice/merger-invoice.component';

@Component({
  selector: 'app-output-nis-billing-order-confirmation',
  templateUrl: './order-confirmation.component.html',
})
export class OutputNisBillingOrderConfirmationComponent implements OnInit {
  record: any = {};
  i: any;
  selectedRows: any = []; // 开票订单列表
  sumAmount = 0; // 订单总额
  singelAmount = 0; // 单张发票金额上限
  count = 0; // 拆分订单张数

  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '订单编号', index: 'orderNumber', i18n: 'output.st.orderNumber' },
    { title: '订单日期', type: 'date', index: 'createTime', dateFormat: 'YYYYMMDD', i18n: 'output.st.orderDate' },
    { title: '购方名称', index: 'invoicePurchaserCompany', i18n: 'output.st.purchaseName' },
    { title: '含税总额', index: 'totalAmount', i18n: 'output.invoicing.totalTaxIncluded' },
  ];


  constructor(
    private modal: NzModalRef,
    public msgSrv: NzMessageService,
    public http: _HttpClient,
    private modaHelper: ModalHelper,
  ) { }

  ngOnInit(): void {
    // 获取选中列表和总金额
    this.selectedRows = this.i.selectedRows;
    this.sumAmount = this.i.sumAmount;
    this.singelAmount = this.i.singelAmount;
  }

  // 检查订单金额是否超额
  checkAmount() {
    this.close();
    // 发票总金额大于单张大票金额上限，则跳转至拆分确认页面
    if (this.sumAmount > this.singelAmount) {
      this.modaHelper.static(OutputNisBillingExcessRemindComponent, { i: { selectedRows: this.selectedRows, sumAmount: this.sumAmount, singelAmount: this.singelAmount } }, 500).subscribe(() => {
      });
    } else {
      // 否则直接跳转到合并开票信息页面
      this.count = Math.ceil(this.sumAmount / this.singelAmount);
      // ptype=0代表拆分开票，ptype=1代表合并拆分，ptype=2 代表是合并不拆分
      this.modaHelper.static(OutputNisBillingMergerInvoiceComponent, { i: { ptype: 2, count: this.count, singelAmount: this.singelAmount, selectedRows: this.selectedRows, sumAmount: this.sumAmount } }, 1100).subscribe(() => {
      });
    }

  }

  close() {
    this.modal.destroy();
  }
}
