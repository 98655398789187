import { Component, OnInit } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-input-purchase-order-refund-info',
  templateUrl: './refund-info.component.html',
})
export class InputPurchaseOrderRefundInfoComponent implements OnInit {
  loading = false;
  i: any;
  data: any = {
    "expressCompany": "",
    "expressNo": "",
    "detailedReason": "",
  }

  constructor(
    private modal: NzModalRef,
    public msgSrv: NzMessageService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.getRefundInfo();
  }

  // 获取退票信息
  getRefundInfo() {
    this.loading = true;
    this.http.get(`/input/invoicerefund/info/${this.i.id}`).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        // 退票信息
        if (res.invoiceRefund) {
          this.data = res.invoiceRefund;
        }
      }
    }, error => {
      console.log('HTTP Error', error);
    });
  }

  close() {
    this.modal.destroy();
  }
}
