import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService, NzModalService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STComponent, STColumn } from '@delon/abc';
import { clientConfig } from './../../../../../environments/client-config';
import * as format from 'date-fns/format';
import { OutputNisBillingViewComponent } from '../../nis-billing/view/view.component';
import { OutputInvoiceInAdvanceViewComponent } from '../../invoice-in-advance/view/view.component';
import { OutputJdeBillingViewComponent } from '../../jde-billing/view/view.component';

@Component({
  selector: 'app-output-invoiced-manual-red-push',
  templateUrl: './manual-red-push.component.html',
  styleUrls: ['./manual-red-push.component.less'],
})
export class OutputInvoicedManualRedPushComponent implements OnInit {
  record: any = {};
  i: any;
  checkedRow: any = {}; // 单选数据
  loading = false;
  show = false;
  more = 'down';
  rows: any[] = [
    {
      id: 1, invoiceCode: '1000', invoiceNumber: '90x101234561', billingMethod: 'NIS手动', orderNumber: '1000', invoicePurchaserCompany: '宙斯饲料有限公司', invoiceSellCompany: '正大饲料有限公司', noTaxAmount: '5000', writeOffAmount: '100', taxAmount: '350', totalAmount: 5868.80, billType: '普票', createTime: new Date(`2019-11-01`), invoiceStatus: '已开具', businessType: '订单开票',
    },
    {
      id: 2, invoiceCode: '1001', invoiceNumber: '90x101234562', billingMethod: 'NIS自动', orderNumber: '1001', invoicePurchaserCompany: '宙斯饲料有限公司', invoiceSellCompany: '正大饲料有限公司', noTaxAmount: '5000', writeOffAmount: '200', taxAmount: '350', totalAmount: 5868.80, billType: '普票', createTime: new Date(`2019-11-02`), invoiceStatus: '已开具', businessType: '利息',
    },
    {
      id: 3, invoiceCode: '1002', invoiceNumber: '90x101234563', billingMethod: 'JDE手工', orderNumber: '1002', invoicePurchaserCompany: '宙斯饲料有限公司', invoiceSellCompany: '正大饲料有限公司', noTaxAmount: '5000', writeOffAmount: '3100', taxAmount: '350', totalAmount: 5868.80, billType: '普票', createTime: new Date(`2019-11-02`), invoiceStatus: '已打印', businessType: '提前开票',
    },
    {
      id: 4, invoiceCode: '1003', invoiceNumber: '90x101234564', billingMethod: 'JDE手工', orderNumber: '1003', invoicePurchaserCompany: '宙斯饲料有限公司', invoiceSellCompany: '正大饲料有限公司', noTaxAmount: '5000', writeOffAmount: '100', taxAmount: '350', totalAmount: 5868.80, billType: '专票', createTime: new Date(`2019-11-02`), invoiceStatus: '已打印', businessType: '固定资产-简易征收',
    },
    {
      id: 5, invoiceCode: '1004', invoiceNumber: '90x101234565', billingMethod: 'NIS手动', orderNumber: '1004', invoicePurchaserCompany: '宙斯饲料有限公司', invoiceSellCompany: '正大饲料有限公司', noTaxAmount: '5000', writeOffAmount: '2100', taxAmount: '350', totalAmount: 5868.80, billType: '普票', createTime: new Date(`2019-11-03`), invoiceStatus: '已开具', businessType: '订单开票',
    },
    {
      id: 6, invoiceCode: '1005', invoiceNumber: '90x101234566', billingMethod: 'JDE手工', orderNumber: '1005', invoicePurchaserCompany: '宙斯饲料有限公司', invoiceSellCompany: '正大饲料有限公司', noTaxAmount: '5000', writeOffAmount: '100', taxAmount: '350', totalAmount: 5868.80, billType: '普票', createTime: new Date(`2019-11-03`), invoiceStatus: '已打印', businessType: '固定资产-简易征收',
    },
    {
      id: 7, invoiceCode: '1006', invoiceNumber: '90x101234567', billingMethod: 'JDE手工', orderNumber: '1006', invoicePurchaserCompany: '宙斯饲料有限公司', invoiceSellCompany: '正大饲料有限公司', noTaxAmount: '5000', writeOffAmount: '300', taxAmount: '350', totalAmount: 5868.80, billType: '普票', createTime: new Date(`2019-11-03`), invoiceStatus: '已开具', businessType: '固定资产-一般计税',
    },
    {
      id: 8, invoiceCode: '1007', invoiceNumber: '90x101234568', billingMethod: 'NIS手动', orderNumber: '1007', invoicePurchaserCompany: '宙斯饲料有限公司', invoiceSellCompany: '正大饲料有限公司', noTaxAmount: '5000', writeOffAmount: '100', taxAmount: '350', totalAmount: 5868.80, billType: '专票', createTime: new Date(`2019-11-04`), invoiceStatus: '已打印', businessType: '提前开票',
    },
    {
      id: 9, invoiceCode: '1008', invoiceNumber: '90x101234569', billingMethod: 'NIS手动', orderNumber: '1008', invoicePurchaserCompany: '宙斯饲料有限公司', invoiceSellCompany: '正大饲料有限公司', noTaxAmount: '5000', writeOffAmount: '200', taxAmount: '350', totalAmount: 5868.80, billType: '普票', createTime: new Date(`2019-11-04`), invoiceStatus: '已开具', businessType: '固定资产-简易征收',
    },
  ];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '', index: 'id', type: 'radio', width: 10 },
    { title: '发票代码', index: 'invoiceCode', width: 100 },
    { title: '发票号码', index: 'invoiceNumber', width: 100 },
    { title: '开具方式', index: 'billingMethod', width: 100 },
    { title: '关联订单', index: 'orderNumber', width: 100 },
    { title: '开票日期', index: 'createTime', type: 'date', dateFormat: 'YYYY-MM-DD', width: 120 },
    { title: '业务类型', index: 'businessType', width: 100 },
    { title: '购方名称', index: 'invoicePurchaserCompany', width: 150 },
    { title: '销方名称', index: 'invoiceSellCompany', width: 150 },
    { title: '总金额', index: 'noTaxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', width: 100 },
    { title: '税额', index: 'taxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', width: 100 },
    { title: '价税合计', index: 'totalAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', width: 100 },
    { title: '发票类型', index: 'billType', render: 'billType', width: 100 },
    { title: '发票状态', index: 'invoiceStatus', render: 'invoiceStatus', fixed: 'right', width: 100 },
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    item: '',
    createTime: '',
    invoicePurchaserCompany: '',
    invoiceSellCompany: '',
    invoiceCode: '',
    invoiceNumber: '',
    orderNumber: '',
    billType: '',
    invoiceStatus: '',
    minAmount: '',
    maxAmount: '',
  };
  createDate: ''; // 订单日期
  invoiceStatusOption = clientConfig.outputInvoiceStatusOption; // 全部发票状态
  invoiceTypeOption = [
    { value: '专票', label: "增值税专用发票" },
    { value: '普票', label: "增值税普通发票" },
  ];

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    private modalService: NzModalService,
    private modalHelper: ModalHelper,
    public http: _HttpClient,
  ) { }

  ngOnInit(): void {

  }

  // 获取列表
  getList() {

  }

  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 下一步红冲待确认操作
  next() {
    if (Object.keys(this.checkedRow).length === 0) {
      this.modalService.warning({
        nzTitle: '请选择要红冲的发票！',
        nzWrapClassName: 'vertical-center-modal',
      });
      return false;
    }
    this.modal.close(true);
    let viewComponent: any = '';
    if (this.checkedRow.businessType === '订单开票') {
      viewComponent = OutputNisBillingViewComponent;
    } else if (this.checkedRow.businessType === '提前开票') {
      viewComponent = OutputInvoiceInAdvanceViewComponent;
    } else {
      viewComponent = OutputJdeBillingViewComponent;
    }
    // ptype=4,红冲查看
    this.modalHelper.static(viewComponent, { i: { ptype: 4, selectedRows: this.checkedRow } }, 1100).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 订单日期事件
  createDateChange(event) {
    this.queryParams.createTime = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      item: '',
      createTime: '',
      invoicePurchaserCompany: '',
      invoiceSellCompany: '',
      invoiceCode: '',
      invoiceNumber: '',
      orderNumber: '',
      billType: '',
      invoiceStatus: '',
      minAmount: '',
      maxAmount: '',
    };
    this.createDate = '';
    this.getList();
  }

  // 展开
  showOrHidden() {
    this.show = !this.show;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.show) {
      this.more = 'up';
    } else {
      this.more = 'down';
    }
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
    // 单选事件
    if (event.type === 'radio') {
      this.checkedRow = event.radio!;
    }
  }

  close() {
    this.modal.destroy();
  }
}
