export const clientConfig = {
  // 公告管理类型
  noticeTypeOption: [
    { value: 1, label: '申报' },
    { value: 2, label: '销项' },
    { value: 3, label: '进项' },
    { value: 4, label: '其他' },
    { value: 5, label: '升级' },
    { value: 6, label: '警告' },
  ],
  // 会计准则
  accountingStandardsOption: [
    { value: '2000020001', label: '企业会计准则' },
    { value: '2000020002', label: '小企业会计准则' },
    { value: '2000020003', label: '企业会计准则（商业银行）' },
    { value: '2000020004', label: '企业会计准则（保险公司）' },
    { value: '2000020005', label: '企业会计准则（证券公司）' },
    { value: '2000020006', label: '企业会计准则（担保企业会计核算办法）' },
    { value: '2000020007', label: '事业单位会计制度' },
  ],

  periodCodeOption:[
    { value: 1, label: '月度' },
    { value: 2, label: '季度' },
  ],


  // 纳税人身份
  vatTaxpayerOption: [
    { value: '2000010001', label: '一般纳税人' },
    { value: '2000010002', label: '小规模纳税人' },
  ],

  thirAccountingStandard: [
    { value: '2000020002', label: '小企业准则' },
    { value: '2000020001', label: '企业准则' },
  ],


  // 网报登录方式
  loginMethodOption: [
    { value: 1, label: 'CA证书登录' },
    { value: 2, label: '用户名密码登录' },
    { value: 3, label: '实名制登录' },
    { value: 4, label: '证件登录' },
    { value: 5, label: '手机号验证码登录' },
    { value: 6, label: '手机号密码登录' },

  ],
  // 企业类型
  deptTypeOption: [
    { value: 1, label: '总公司' },
    { value: 2, label: '分公司' },
  ],
  // 发票类型
  invoiceEntityOption: [
    { value: '专用发票', label: '专用发票' },
    { value: '普通发票', label: '普通发票' },
    { value: '电子发票', label: '电子发票' },
  ],
  // 进项-全部发票状态
  invoiceStatusOption: [
    // { value: '1', label: '' },
    { value: '2', label: '识别失败' },
    { value: '3', label: '待验真' },
    { value: '4', label: '验真失败' },
    { value: '5', label: '待匹配' },
    { value: '6', label: '匹配失败' },
    { value: '7', label: '待入账' },
    { value: '8', label: '入账失败' },
    { value: '9', label: '待认证' },
    { value: '10', label: '操作中' },
    { value: '11', label: '撤销认证' },
    { value: '12', label: '认证成功' },
    { value: '13', label: '认证失败' },
    { value: '14', label: '待统计' },
    { value: '15', label: '统计中' },
    { value: '16', label: '统计成功' },
    { value: '17', label: '统计失败' },
    { value: '18', label: '完成' },
    { value: '-1', label: '异常-重复' },
    { value: '-2', label: '异常-退票' },
    { value: '-3', label: '异常-删除' },
    { value: '-4', label: '异常-冲销' },
    { value: '-5', label: '异常-串号' },
  ],
  // 验真发票状态
  validationInvoiceStatusOption: [
    { value: '2', label: '识别失败' },
    { value: '3', label: '待验真' },
    { value: '4', label: '验真失败' },
    { value: '5', label: '待匹配' },
    { value: '6', label: '匹配失败' },
  ],
  // 入账发票状态
  creditInvoiceStatusOption: [
    { value: '7', label: '待入账' },
    { value: '8', label: '入账失败' },
  ],
  // 异常发票状态
  abnormalInvoiceStatusOption: [
    { value: '-1', label: '异常-重复' },
    { value: '-2', label: '异常-退票' },
    { value: '-3', label: '异常-删除' },
    { value: '-4', label: '异常-冲销' },
    { value: '-5', label: '异常-串号' },
  ],
  // 开票规则-发票类型
  invoiceTypeOption: [
    { value: '专用发票', label: '专用发票' },
    { value: '普通发票', label: '普通发票' },
  ],
  // 开票规则-发票载体
  invoiceCarrierOption: [
    { value: '纸质发票', label: '纸质发票' },
    { value: '电子发票', label: '电子发票' },
    { value: '数电发票', label: '数电发票' },
    { value: '数电纸票', label: '数电纸票' },
  ],
  // 开票规则-开票频率
  billingFrequencyOption: [
    { value: '7', label: '周日' },
    { value: '1', label: '周一' },
    { value: '2', label: '周二' },
    { value: '3', label: '周三' },
    { value: '4', label: '周四' },
    { value: '5', label: '周五' },
    { value: '6', label: '周六' },
  ],
  // 销项-全部发票状态
  outputInvoiceStatusOption: [
    { value: 1, label: '待申请' },
    { value: 2, label: '待审批' },
    { value: 3, label: '审批驳回' },
    { value: 4, label: '已撤销' },
    { value: 5, label: '待开票' },
    { value: 6, label: '开具失败' },
    { value: 7, label: '已开具' },
    { value: 8, label: '线下开具' },
    { value: 9, label: '已打印' },
    { value: 10, label: '红冲待审批' },
    { value: 11, label: '红冲驳回' },
    { value: 12, label: '红冲已开具' },
    { value: 13, label: '红冲已打印' },
    { value: 14, label: '作废待审批' },
    { value: 15, label: '作废驳回' },
    { value: 16, label: '已作废' },
    { value: 17, label: '红冲失败' },
    { value: 18, label: '作废失败' },
    { value: 19, label: '红冲已撤销' },
    { value: 20, label: '作废已撤销' },
  ],
  // 销项-订单状态
  outputOrderStatusOption: [
    { value: 1, label: '已开具' },
    { value: 2, label: '待开具' },
    { value: 3, label: '部分开具' },
  ],
  // 销项-开票方式
  outputBusinessTypeOption: [
    { value: '0', label: '订单开票' },
    { value: '1', label: '无订单开票' },
    { value: '2', label: '提前开票' },
  ],
  // 发票库存状态
  inventoryStatusOption: [
    { value: 1, label: '正常' },
    { value: 2, label: '库存不足' },
    { value: 3, label: '库存过多' },
  ],
  // 报警规则运算符
  alarmOperationOption: [
    { value: 'GT', label: '>' },
    { value: 'GT_OR_EQ', label: '≥' },
    { value: 'EQ', label: '=' },
    { value: 'LT_OR_EQ', label: '≤' },
    { value: 'LT', label: '<' },
  ]

};
