import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService, NzModalService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { STComponent, STColumn } from '@delon/abc';
import { clientConfig } from '@env/client-config';
import { DialogService } from 'src/app/service/dialog.service';
import * as format from 'date-fns/format';

@Component({
  selector: 'app-output-nis-billing-related-invoice',
  templateUrl: './related-invoice.component.html',
  styleUrls: ['./related-invoice.component.less'],
})
export class OutputNisBillingRelatedInvoiceComponent implements OnInit {
  record: any = {};
  i: any;
  loading = false;
  show = false;
  more = 'down';
  // 订单信息
  rows: any[] = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '订单编号', index: 'orderNumber', i18n: 'output.st.orderNumber', width: 100 },
    { title: '订单日期', index: 'orderDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'output.st.orderDate', width: 120 },
    { title: '购方名称', index: 'purchaseName', i18n: 'output.st.purchaseName', width: 120 },
    { title: '销方名称', index: 'sellerName', i18n: 'output.st.sellerName', width: 120 },
    { title: '总金额', index: 'excludingTaxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.amount', width: 80 },
    { title: '税额', index: 'taxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.taxAmount', width: 60 },
    { title: '价税合计', index: 'totalAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.invoiceTotalAmount', width: 100 },
    { title: '发票类型', index: 'invoiceType', i18n: 'output.st.invoiceType', width: 100 },
    { title: '发票载体', index: 'invoiceEntity', i18n: 'output.st.invoiceEntity', width: 100 },
  ];
  orderId: any = ''; // 订单id
  // 发票列表信息
  checkedRows = []; // 选择关联的发票列表
  invoiceRow: any = [];
  @ViewChild('invoiceST', { static: false }) invoiceST: STComponent;
  invoiceColumns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox', width: 10 },
    { title: '发票代码', index: 'invoiceCode', i18n: 'output.st.invoiceCode', width: 100 },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'output.st.invoiceNumber', width: 100 },
    { title: '开票日期', index: 'invoicingTime', i18n: 'output.st.invoicingTime', type: 'date', dateFormat: 'YYYY-MM-DD', width: 120 },
    { title: '购方名称', index: 'purchaseName', i18n: 'output.st.purchaseName', width: 150 },
    { title: '销方名称', index: 'sellerName', i18n: 'output.st.sellerName', width: 150 },
    { title: '总金额', index: 'excludingTaxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.amount', width: 80 },
    { title: '税额', index: 'taxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.taxAmount', width: 60 },
    { title: '价税合计', index: 'totalAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.invoiceTotalAmount', width: 100 },
    { title: '发票类型', index: 'invoiceType', i18n: 'output.st.invoiceType', width: 100 },
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    purchaseName: '',
    sellerName: '',
    invoiceCode: '',
    invoiceNumber: '',
    invoiceType: '',
    minExcludingTaxAmount: '',
    maxExcludingTaxAmount: '',
    menuType: '8', // 8-线下开具发票列表
  };
  orderDate: ''; // 订单日期
  pageTotal = 0;
  invoiceTypeOption = clientConfig.invoiceTypeOption;
  invoiceCarrierOption = clientConfig.invoiceCarrierOption;

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    private modalService: NzModalService,
    public http: _HttpClient,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    // 获取操作的订单信息显示
    if (this.i) {
      this.orderId = this.i.orderId || '';
      this.rows.push(this.i);
    }
    this.getList();
  }

  // 获取发票列表
  getList() {
    this.loading = true;
    this.http.get('/output/invoice/list', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.invoiceRow = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 关联发票操作
  save() {
    const invoiceIds = this.checkedRows.map(item => item.invoiceId).join(',');
    this.loading = true;
    this.http.post('/output/order/relatedInvoice?invoiceIds=' + invoiceIds + '&orderId=' + this.orderId).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.modal.close(true);
        this.msgSrv.success('操作成功');
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      purchaseName: '',
      sellerName: '',
      invoiceCode: '',
      invoiceNumber: '',
      invoiceType: '',
      minExcludingTaxAmount: '',
      maxExcludingTaxAmount: '',
      menuType: '8',
    };
    this.orderDate = '';
    this.getList();
  }

  // 展开
  showOrHidden() {
    this.show = !this.show;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.show) {
      this.more = 'up';
    } else {
      this.more = 'down';
    }
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
    // 单选事件
    if (event.type === 'checkbox') {
      this.checkedRows = event.checkbox!;
    }
  }

  close() {
    this.modal.destroy();
  }
}
