import { Component, OnInit, ViewChild, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STChange, STData } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';
import { OutputNisBillingOrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { OutputNisBillingMergerInvoiceComponent } from './merger-invoice/merger-invoice.component';
import { OutputNisBillingMakeAnInvoiceComponent } from './make-an-invoice/make-an-invoice.component';
import { OutputNisBillingEditComponent } from './edit/edit.component';
import { OutputNisBillingViewComponent } from './view/view.component';
import * as format from 'date-fns/format';
import { OutputNisBillingExcessRemindComponent } from './excess-remind/excess-remind.component';
import { clientConfig } from './../../../../environments/client-config';
import { OutputNisBillingViewApplyComponent } from './view-apply/view-apply.component';
import { DialogService } from 'src/app/service/dialog.service';
import { OutputNisBillingRelatedInvoiceComponent } from './related-invoice/related-invoice.component';
import { OutputInvoicedInvalidComponent } from '../invoiced/invalid/invalid.component';
import { OutputInvoicedPrintConfirmationComponent } from '../invoiced/print-confirmation/print-confirmation.component';

@Component({
  selector: 'app-output-nis-billing',
  templateUrl: './nis-billing.component.html',
  styleUrls: ['./nis-billing.component.less'],
})
export class OutputNISBillingComponent implements OnInit {
  loading = false;
  show = false;
  more = 'down';
  selectedRows: STData[] = [];
  viewStatus = [2, 4, 8, 9, 10, 13, 14, 16];
  editStatus = [3, 11, 15];
  reapplyStatus = [3, 4, 11, 15];
  revocationStatus = [2, 3, 10, 11, 14, 15];
  downloadStatus = [7, 9, 12, 13];
  invalidStatus = [7, 8, 9];
  sumAmount = 0; // 选择订单合计费用
  invoiceAmountLimit = 0; // 单张发票金额上限
  splitInvoiceNumber = 0; // 拆分张数
  rows: any[] = [];
  // 订单开票信息
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '订单编号', index: 'orderNumber', i18n: 'output.st.orderNumber', width: 100 },
    { title: '订单日期', index: 'orderDate', type: 'date', dateFormat: 'YYYYMMDD', i18n: 'output.st.orderDate', width: 120 },
    { title: '购方名称', index: 'purchaseName', i18n: 'output.st.purchaseName', width: 150 },
    { title: '销方名称', index: 'sellerName', i18n: 'output.st.sellerName', width: 150 },
    { title: '开具方式', index: 'invoicingMethod', i18n: 'output.st.invoicingMethod', width: 100 },
    { title: '总金额（不含税）', index: 'excludingTaxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.excludingTaxAmount', width: 150 },
    { title: '税额', index: 'taxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.taxAmount', width: 100 },
    // { title: '折扣方式', index: 'discountType', i18n: 'output.st.discountType', width: 100 },
    // { title: '折扣金额（不含税）', index: 'excludingTaxDiscountAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.excludingTaxDiscountAmount', width: 150 },
    { title: '合计（含税）', index: 'totalAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.totalAmount', width: 120 },
    { title: '可开票金额', index: 'invoiceAmountAvailable', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.invoiceAmountAvailable', width: 150 },
    { title: '票据类型', index: 'invoiceType', i18n: 'output.st.orderInvoiceType', width: 100 },
    { title: '发票载体', index: 'invoiceEntity', i18n: 'output.st.invoiceEntity', width: 100 },
    { title: '订单状态', index: 'status', i18n: 'output.st.orderStatus', render: 'status', fixed: 'right', width: 100 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 100,
      buttons: [
        {
          text: '申请',
          i18n: 'st.operate.apply',
          iif: record => record.status === 2 || record.status === 3,
          paramName: 'i',
          click: (item: any) => this.apply(item),
        },
        {
          text: '查看',
          i18n: 'st.operate.view',
          iif: record => record.status === 1,
          paramName: 'i',
          click: (item: any) => this.viewApply(item),
        },
        {
          text: '挂票',
          i18n: 'st.operate.relatedInvoice',
          type: 'modal',
          size: 1100,
          component: OutputNisBillingRelatedInvoiceComponent,
          paramName: 'i',
          click: (item: any) => this.getList(),
        },
      ]
    }
  ];
  // 嵌套发票列表
  @ViewChild('expendst', { static: false }) expendst: STComponent;
  expendColumns: STColumn[] = [
    { title: '发票代码', index: 'invoiceCode', i18n: 'output.st.invoiceCode', width: 100 },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'output.st.invoiceNumber', width: 100 },
    { title: '开票申请号', index: 'applyNumber', i18n: 'output.st.applyNumber', width: 120 },
    { title: '申请人', index: 'applyUser', i18n: 'output.st.applyUser', width: 100 },
    { title: '申请日期', index: 'applyTime', i18n: 'output.st.applyTime', type: 'date', dateFormat: 'YYYY-MM-DD', width: 120 },
    // { title: '购方名称', index: 'purchaseName', i18n: 'output.st.purchaseName', width: 150 },
    // { title: '销方名称', index: 'sellerName', i18n: 'output.st.sellerName', width: 150 },
    { title: '开票金额(不含税)', index: 'excludingTaxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.invoiceTaxAmount', width: 160 },
    // { title: '税率', index: 'taxRate', i18n: 'output.st.taxRate', render: 'taxRate', width: 80 },
    { title: '税额', index: 'taxAmount', i18n: 'output.st.taxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', width: 80 },
    { title: '价税合计', index: 'totalAmount', i18n: 'output.st.invoiceTotalAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', width: 100 },
    { title: '开票日期', index: 'invoicingTime', i18n: 'output.st.invoicingTime', type: 'date', dateFormat: 'YYYY-MM-DD', width: 120 },
    { title: '业务类型', index: 'businessType', i18n: 'output.st.businessType', width: 100 },
    { title: '入账凭证号', index: 'accountingVouchers', i18n: 'output.st.accountingVouchers', width: 120 },
    { title: '发票类型', index: 'invoiceType', i18n: 'output.st.invoiceType', width: 100 },
    { title: '发票载体', index: 'invoiceEntity', i18n: 'output.st.invoiceEntity', width: 100 },
    { title: '发票状态', index: 'invoiceStatus', i18n: 'output.st.invoiceStatus', render: 'invoiceStatus', fixed: 'right', width: 100 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 200,
      buttons: [
        {
          text: '查看',
          i18n: 'st.operate.view',
          paramName: 'i',
          iif: record => this.viewStatus.includes(record.invoiceStatus),
          click: (item: any) => this.viewInvoice(item),
        },
        {
          text: '开票',
          i18n: 'st.operate.invoicing',
          iif: record => record.invoiceStatus === 5,
          paramName: 'i',
          pop: true,
          popTitle: '确认要开具此发票吗？',
          click: (item: any) => this.invoicing(item.invoiceId),
        },
        {
          text: '撤销',
          i18n: 'st.operate.revocation',
          iif: record => this.revocationStatus.includes(record.invoiceStatus),
          paramName: 'i',
          pop: true,
          popTitle: '确认要撤销此发票吗？',
          click: (item: any) => this.revocation(item.invoiceId),
        },
        {
          text: '打印',
          i18n: 'st.operate.print',
          iif: record => record.invoiceStatus === 7 || record.invoiceStatus === 12,
          paramName: 'i',
          click: (item: any) => this.print(item),
        },
        {
          text: '下载',
          i18n: 'st.operate.download',
          iif: record => this.downloadStatus.includes(record.invoiceStatus),
          paramName: 'i',
          click: (item: any) => this.download(item.invoiceId),
        },
        {
          text: '重新开具',
          i18n: 'st.operate.reinvoicing',
          iif: record => record.invoiceStatus === 6,
          paramName: 'i',
          pop: true,
          popTitle: '确认要重新开具此发票吗？',
          click: (item: any) => this.invoicing(item.invoiceId),
        },
        {
          text: '重新申请',
          i18n: 'st.operate.reapply',
          iif: record => this.reapplyStatus.includes(record.invoiceStatus),
          paramName: 'i',
          click: (item: any) => this.reapply(item),
        },
        {
          text: '查看原因',
          i18n: 'st.operate.viewReason',
          iif: record => this.editStatus.includes(record.invoiceStatus),
          paramName: 'i',
          click: (item: any) => this.viewReason(item),
        },
        {
          text: '...',
          paramName: 'i',
          iif: record => this.invalidStatus.includes(record.invoiceStatus),
          children: [
            {
              text: '红冲',
              i18n: 'st.operate.redRush',
              iif: record => this.invalidStatus.includes(record.invoiceStatus),
              paramName: 'i',
              pop: true,
              popTitle: '确认要红冲此发票吗？',
              click: (item: any) => this.redRush(item),
            },
            {
              text: '作废',
              i18n: 'st.operate.invalid',
              iif: record => this.invalidStatus.includes(record.invoiceStatus),
              paramName: 'i',
              pop: true,
              popTitle: '确认要作废此发票吗？',
              click: (item: any) => this.invalid(item.invoiceId),
            },
          ]
        },
      ]
    }
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    orderNumber: '',
    orderDate: '',
    purchaseName: '',
    sellerName: '',
    invoiceType: '',
    orderStatus: '',
    minExcludingTaxAmount: '',
    maxExcludingTaxAmount: '',
  };
  pageTotal = 0;
  orderDate: ''; // 订单日期
  invoiceStatusOption = clientConfig.outputInvoiceStatusOption; // 全部发票状态
  invoiceTypeOption = clientConfig.invoiceTypeOption;
  invoiceCarrierOption = clientConfig.invoiceCarrierOption;
  orderStatusOption = clientConfig.outputOrderStatusOption

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private cdr: ChangeDetectorRef,
    public msg: NzMessageService,
    private modalService: NzModalService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getList();
  }

  // 获取NIS列表
  getList() {
    this.loading = true;
    this.http.get('/output/order/list', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 同步刷新订单列表:点击新增一条订单记录
  refreshList() {
    this.loading = true;
    this.http.get('/output/order/syncOrder').subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.msg.success('订单业务同步成功！');
        this.cancel();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 申请
  apply(i) {
    this.modal.static(OutputNisBillingMergerInvoiceComponent, { i: { ptype: 0, orderIds: i.orderId, customerName: i.purchaseName, orderAmount: i.excludingTaxAmount } }, 1100).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 批量申请
  async bulkApply() {
    if (this.selectedRows.length === 0) {
      this.modalService.warning({
        nzTitle: '请选择要拆分开票的订单！',
      });
      return;
    }
    // 判断订单列表是同一购买方，再拆分，否则提示重新选择确认
    const applyFlag = this.checkinvoicePurchaserCompany(this.selectedRows);
    if (!applyFlag) {
      this.modalService.warning({
        nzTitle: '请选择同一购方名称的订单！',
      });
      return;
    }
    // 获取订单信息
    const customerName = this.selectedRows[0].purchaseName;
    const orderIds = this.selectedRows.map(item => item.orderId).filter(id => id).join(',');
    let orderAmount = 0;
    this.selectedRows.map(item => {
      orderAmount += item.excludingTaxAmount;
    });
    await this.getInvoiceAmountLimit(customerName);
    // 发票总金额大于单张大票金额上限，则跳转至拆分确认页面
    if (orderAmount > this.invoiceAmountLimit) {
      this.modal.static(OutputNisBillingExcessRemindComponent, { i: { orderIds, customerName, orderAmount, invoiceAmountLimit: this.invoiceAmountLimit } }, 500).subscribe((success) => {
        this.sumAmount = 0;
        this.selectedRows = [];
        if (success) {
          this.getList();
        } else {
          this.st.reload();
        }
      });
    } else {
      // 否则直接跳转到合并开票信息页面
      // ptype=0代表拆分开票，ptype=1代表合并拆分，ptype=2 代表是合并不拆分
      this.modal.static(OutputNisBillingMergerInvoiceComponent, { i: { ptype: 0, orderIds, customerName, orderAmount } }, 1100).subscribe((success) => {
        this.sumAmount = 0;
        this.selectedRows = [];
        if (success) {
          this.getList();
        } else {
          this.st.reload();
        }
      });
    }
  }

  // 获取票面金额上限
  getInvoiceAmountLimit(customerName) {
    return new Promise((resolve, reject) => {
      this.http.get(`/output/invoicerules/getDetail/${customerName}`).subscribe(async data => {
        this.dialogService.getErrorCode(data);
        if (data.msg === 'success') {
          if (data.data.rules) {
            this.invoiceAmountLimit = data.data.rules.amount;
            resolve(data.data.rules.amount);
          }
          this.loading = false;
        }
      }, error => {
        this.loading = false;
        console.log('HTTP Error', error);
      });
    });
  }

  // 合并开票
  mergeInvoice() {
    if (this.selectedRows.length === 0) {
      this.modalService.warning({
        nzTitle: '请选择要合并开票的订单！',
      });
      return;
    }
    // 判断订单列表是同一购买方，再拆分，否则提示重新选择确认
    this.modal.static(OutputNisBillingOrderConfirmationComponent, { i: { selectedRows: this.selectedRows, sumAmount: this.sumAmount, invoiceAmountLimit: this.invoiceAmountLimit } }, 600).subscribe(() => {
      this.st.load();
      this.sumAmount = 0;
      this.selectedRows = [];
    });
  }

  // 生成预制发票
  generatePrefabricatedIinvoice() {
    this.modalService.confirm({
      nzTitle: '确认生成预制发票?',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          this.msg.success('生成预制发票成功！');
          this.getList();
          resolve(true);
        }).catch(() => this.msg.success('生成预制发票失败!'))
    });
  }

  // 判断所选订单列表是否同一购方
  checkinvoicePurchaserCompany(list) {
    let flag = true;
    if (list.length > 0) {
      const purchaseName = list[0].purchaseName;
      list.map(item => {
        if (item.purchaseName !== purchaseName) {
          flag = false;
          return flag;
        }
      })
    }
    return flag;
  }

  // 开票/重新开具
  invoicing(invoiceId) {
    this.loading = true;
    this.http.post('/output/invoice/invoicing?invoiceIds=' + invoiceId).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msg.success('操作成功');
        this.getList();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 撤销
  revocation(invoiceId) {
    this.loading = true;
    this.http.post('/output/invoice/revoked?invoiceIds=' + invoiceId).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msg.success('操作成功');
        this.getList();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 重新申请
  reapply(item) {
    // ptype: 0-编辑；1-重新申请
    this.modal.static(OutputNisBillingEditComponent, { i: { ptype: 1, selectedRows: item } }, 1100).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 打印
  print(item) {
    this.modal.static(OutputInvoicedPrintConfirmationComponent, { i: { selectedRows: [item] } }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 下载
  download(invoiceId) {

  }

  // 红冲
  redRush(item) {
    // ptype=4,红冲查看
    this.modal.static(OutputNisBillingViewComponent, { i: { ptype: 4, selectedRows: item } }, 1100).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 作废
  invalid(invoiceId) {
    this.modal.static(OutputInvoicedInvalidComponent, { i: { invoiceId } }, 500).subscribe((success) => {
      if (success) {
        this.msg.success('操作成功');
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 查看原因
  viewReason(item) {
    // ptype=3,查看审批原因详情
    this.modal.static(OutputNisBillingViewComponent, { i: { ptype: 3, selectedRows: item } }, 1100).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 订单查看
  viewApply(i) {
    this.modal.static(OutputNisBillingViewApplyComponent, { i: { ptype: 0, orderIds: i.orderId, customerName: i.purchaseName, orderAmount: i.excludingTaxAmount } }, 1100).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 发票-查看
  viewInvoice(item) {
    this.modal.static(OutputNisBillingViewComponent, { i: { ptype: 1, selectedRows: item } }, 1100).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 订单日期事件
  orderDateChange(event) {
    this.queryParams.orderDate = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      orderNumber: '',
      orderDate: '',
      purchaseName: '',
      sellerName: '',
      invoiceType: '',
      orderStatus: '',
      minExcludingTaxAmount: '',
      maxExcludingTaxAmount: '',
    };
    this.orderDate = '';
    this.getList();
  }

  // 展开
  showOrHidden() {
    this.show = !this.show;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.show) {
      this.more = 'up';
    } else {
      this.more = 'down';
    }
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.selectedRows = event.checkbox!;
      this.sumAmount = this.selectedRows.reduce((total, cv) => total + cv.totalAmount, 0);
      this.cdr.detectChanges();
    }
  }

}
