import { Component, OnInit } from '@angular/core';
import { _HttpClient } from '@delon/theme';

@Component({
  selector: 'app-input-rule-setting',
  templateUrl: './rule-setting.component.html',
})
export class InputRuleSettingComponent implements OnInit {

  constructor(private http: _HttpClient) { }

  ngOnInit() { }

}
