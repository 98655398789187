import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-rule-add-special-goods',
  templateUrl: './add-special-goods.component.html',
})
export class OutputRuleAddSpecialGoodsComponent implements OnInit {
  loading = false;
  i: any;
  goodsData: any = {
    goodsId: '',
    goodsName: '',
    goodsNumber: '',
  } // 选择的商品信息
  goodsList: any = []; // 所有商品列表
  specialRulesGoods: any = []; // 已有的特殊商品列表id
  addFlag = false;

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    if (this.i.specialRulesGoods) {
      this.specialRulesGoods = this.i.specialRulesGoods;
    }
    this.getAllGoodsList();
  }

  // 获取所有商品列表
  getAllGoodsList() {
    this.loading = true;
    this.http.get('/output/goods/alllist').subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.goodsList = data.list;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 选择商品去重事件
  goodSelectedChange(goodsId) {
    this.addFlag = this.specialRulesGoods.includes(parseInt(goodsId, 10)) ? true : false;
  }

  save() {
    this.goodsList.map(item => {
      if (item.goodsId === parseInt(this.goodsData.goodsId, 10)) {
        this.goodsData.goodsId = item.goodsId;
        this.goodsData.goodsName = item.goodsName;
        this.goodsData.goodsNumber = item.goodsNumber;
      }
    })
    this.modal.close(this.goodsData);
  }

  close() {
    this.modal.destroy();
  }
}
