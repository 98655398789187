import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService, NzModalService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-rule-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.less'],
})
export class OutputRuleApplyComponent implements OnInit {
  record: any = {};
  i: any;
  clientList: any = []; // 客户列表
  customerIds = []; // 选中客户id
  rulesId: any = ''; // 开票规则id
  loading = false;


  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    private modalService: NzModalService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    // 初始化获得客户列表,开票规则id
    if (this.i.clientList) {
      this.clientList = this.i.clientList;
    }
    this.rulesId = this.i.rulesId || '';
  }

  // 应用规则
  save() {
    if (this.customerIds.length === 0) {
      this.modalService.warning({
        nzTitle: '请选择客户！',
        nzWrapClassName: 'vertical-center-modal',
      });
      return;
    }
    const customerIds = this.customerIds.join(',');
    this.http.post('/output/customerrules/use?rulesId=' + this.rulesId + '&customerIds=' + customerIds).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.modal.close(true);
        this.msgSrv.success('操作成功！');
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });


  }

  // 获取选中客户
  selectedCustomerChange(value: string[]): void {
    this.customerIds = value;
  }

  close() {
    this.modal.destroy();
  }
}
