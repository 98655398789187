import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NzModalRef, NzMessageService, NzModalService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STComponent, STColumn } from '@delon/abc';
import { OutputJdeBillingRejectComponent } from '../../jde-billing/reject/reject.component';
import { DialogService } from 'src/app/service/dialog.service';

interface Invoice {
  orderNumber: string
  invoiceCode: string
  invoiceNumber: string
  invoiceType: string
  invoiceEntity: string
  purchaseAddress: string
  purchaseBank: string
  purchaseBankAccount: string
  purchaseContact: string
  purchaseName: string
  purchaseTaxCode: string
  remark: string
  sellerAddress: string
  sellerBank: string
  sellerBankAccount: string
  sellerContact: string
  sellerName: string
  sellerTaxCode: string
  taxAmount: number
  taxRate: number
  totalAmount: number
  excludingTaxAmount: number
  excludingTaxDiscountAmount: number
  applyUser: string
  applyTime: string
  businessType: string
  accountingVouchers: string
  payee: string
  reviewer: string
  drawer: string
  province: string
  invoiceGoodsList: []
}

interface Record {
  avatar: string
  userName: string
  roleName: string
  createTime: string
  descr: string
  reson: string
}

@Component({
  selector: 'app-output-invoice-in-advance-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.less'],
})
export class OutputInvoiceInAdvanceViewComponent implements OnInit {
  loading = false;
  record: any = {};
  i: any;
  ptype = 1; // 默认1-查看页面；2-审批页面
  invoiceId: any = ''; // 发票id
  index = 0; // 当前tab序号
  saleTabs: any[] = [{ key: 'info', id: 1, show: true }, { key: 'invoiceView', id: 2 }];
  // 发票信息
  invoiceInfo: Invoice = {
    orderNumber: '',
    invoiceCode: '',
    invoiceNumber: '',
    invoiceType: '',
    invoiceEntity: '',
    purchaseAddress: '',
    purchaseBank: '',
    purchaseBankAccount: '',
    purchaseContact: '',
    purchaseName: '',
    purchaseTaxCode: '',
    remark: '',
    sellerAddress: '',
    sellerBank: '',
    sellerBankAccount: '',
    sellerContact: '',
    sellerName: '',
    sellerTaxCode: '',
    taxAmount: 0,
    taxRate: 0,
    totalAmount: 0,
    excludingTaxAmount: 0,
    excludingTaxDiscountAmount: 0,
    applyUser: '',
    applyTime: '',
    businessType: '',
    accountingVouchers: '',
    payee: '',
    reviewer: '',
    drawer: '',
    province: '',
    invoiceGoodsList: []
  };
  // 开票信息列表
  details: any = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '货物或应税劳务名称', index: 'goodsName', i18n: 'output.invoicing.itemName' },
    { title: '规格型号', index: 'specification', i18n: 'output.invoicing.specification' },
    { title: '单位', index: 'unit', i18n: 'output.invoicing.unit' },
    { title: '数量', index: 'quantity', i18n: 'output.invoicing.quantity' },
    { title: '单价', index: 'price', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.invoicing.price' },
    { title: '金额', index: 'amount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.invoicing.totalAmount' },
    { title: '税率', index: 'taxRate', render: 'taxRate', i18n: 'output.invoicing.taxRate' },
    { title: '税额', index: 'taxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.invoicing.taxAmount' }
  ];
  totalAmount = 0; // 价税合计
  // 审批记录
  records: Record[] = [];
  queryParams: any = {
    page: 1,
    limit: 100,
    invoiceId: '',
  };

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    private modalService: NzModalService,
    private modalHelper: ModalHelper,
    public http: _HttpClient,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.ptype = this.i.ptype;
    this.invoiceId = this.i.selectedRows.invoiceId || '';
    // 初始化发票信息
    this.getInvoiceInfo();
    // 增加审批流程查看tab
    if (this.ptype === 3) {
      this.saleTabs.push({ key: 'approval', id: 3 });
      this.index = 2;
      this.queryParams.invoiceId = this.invoiceId;
      this.getApprovalRecordList();
    }
  }

  // 获取发票信息
  getInvoiceInfo() {
    this.loading = true;
    this.http.get(`/output/invoice/info/${this.invoiceId}`).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.invoiceInfo = data.invoice;
        if (data.invoice.invoiceGoodsList) {
          this.details = data.invoice.invoiceGoodsList;
        }
        this.totalAmount = this.invoiceInfo.totalAmount || 0;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 获取审批操作流程列表
  getApprovalRecordList() {
    this.loading = true;
    this.http.get('/output/approvalprocessrecord/list', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.records = data.page.list;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 驳回
  reject() {
    this.modalHelper.static(OutputJdeBillingRejectComponent, { i: { ptype: this.ptype, invoiceId: this.invoiceId } }, 500).subscribe((success) => {
      if (success) {
        this.modal.close(true);
      }
    });
  }

  // 通过
  pass() {
    this.loading = true;
    let actionUrl = '';
    let content = '';
    if (this.ptype === 2) {
      // 申请审批
      actionUrl = '/output/invoice/applyAudit';
      content = '请至“已开发票”中查看此发票';
    } else if (this.ptype === 5) {
      // 红冲审批
      actionUrl = '/output/invoice/reverseAudit';
      content = '请至“作废/红冲发票-已开具-红冲发票”中查看此发票';
    } else if (this.ptype === 6) {
      // 作废审批
      actionUrl = '/output/invoice/invalidAudit';
      content = '请至“作废/红冲发票-已开具-作废发票”中查看此发票';
    }
    this.http.post(actionUrl + '?invoiceId=' + this.invoiceId).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.modal.close(true);
        const modal = this.modalService.success({
          nzTitle: '审批通过',
          nzContent: content,
          nzWrapClassName: 'vertical-center-modal',
        });
        setTimeout(() => modal.destroy(), 3000);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 确定红冲操作
  redPush() {
    this.http.post('/output/invoice/reverse?invoiceIds=' + this.invoiceId).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.modal.close(true);
        this.msgSrv.success('操作成功');
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 确定申请操作
  pendingInvoiceApply() {
    this.http.post('/output/invoice/pendingInvoiceApply?invoiceId=' + this.invoiceId).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.modal.close(true);
        this.msgSrv.success('操作成功');
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // tab切换事件
  salesChange(event) {
    this.index = event.index;
    this.cdr.detectChanges();
  }

  close() {
    this.modal.destroy();
  }
}
