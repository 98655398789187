import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-rule-edit-info',
  templateUrl: './edit-info.component.html',
})
export class OutputRuleEditInfoComponent implements OnInit {
  loading = false;
  i: any;
  data: any = {
    customerId: '',
    deptId: '',
    name: '',
    taxpayerType: '',
    taxCode: '',
    address: '',
    contact: '',
    bank: '',
    bankAccount: '',
  }
  taxTypeOption = [
    { value: '个体工商户', label: '个体工商户' },
    { value: '企业纳税人', label: '企业纳税人' },
  ]; // 纳税人类型

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    if (this.i.ptype === 1) {
      this.data = this.i.client; // 编辑获取客户基本信息
    } else {
      this.data.deptId = this.i.deptId; // 新增获取deptId
    }
  }

  // 保存
  saveOrUpdate() {
    if (this.i.ptype === 0) {
      this.save();
    } else {
      this.update();
    }
  }

  // 新增客户基本信息
  save() {
    this.loading = true;
    this.http.put('/output/customer/save', this.data).subscribe(res => {
      this.loading = false;
      const flag = this.dialogService.getErrorCode(res);
      if (flag) {
        this.modal.close(true);
        this.msgSrv.success('操作成功！');
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 编辑客户基本信息
  update() {
    this.loading = true;
    this.http.post('/output/customer/update', this.data).subscribe(res => {
      this.loading = false;
      const flag = this.dialogService.getErrorCode(res);
      if (flag) {
        this.modal.close(true);
        this.msgSrv.success('操作成功！');
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  close() {
    this.modal.destroy();
  }
}
