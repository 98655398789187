import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { Route, Router } from '@angular/router';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-commodity',
  templateUrl: './commodity.component.html',
})
export class OutputCommodityComponent implements OnInit {
  loading = false;
  rows: any = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '商品编码', index: 'goodsNumber', i18n: 'output.commodity.goodsNumber' },
    { title: '商品名称', index: 'goodsName', i18n: 'output.commodity.goodsName' },
    { title: '所属机构', index: 'deptName', i18n: 'output.commodity.deptName' },
    { title: '商品税率', index: 'taxRate', i18n: 'output.commodity.taxRate', render: 'taxRate' },
    { title: '商品单价（元）', index: 'price', i18n: 'output.commodity.price', type: 'number', numberDigits: '.2-2', className: 'text-left' },
    { title: '是否享受优惠政策', index: 'preferential', i18n: 'output.commodity.preferential', render: 'preferential' },
    {
      title: '操作',
      i18n: 'st.operate',
      buttons: [
        {
          text: '查看',
          i18n: 'st.operate.view',
          type: 'link',
          click: (item: any) => this.view(item.goodsId)
        },
        {
          text: '编辑',
          i18n: 'st.operate.edit',
          click: (item: any) => this.edit(item.goodsId)
        },
      ]
    }
  ];
  queryParams: any = {
    page: 1,
    limit: 10,
    goodsNumber: '',
    goodsName: '',
    deptName: '',
  };
  pageTotal = 0;

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private router: Router,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getList();
  }

  // 获取商品列表
  getList() {
    this.loading = true;
    this.http.get('/output/goods/list', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 添加商品
  add() {
    this.router.navigate(['/output/commodity/edit'], { queryParams: { ptype: 0 } });
  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      goodsNumber: '',
      goodsName: '',
      deptName: '',
    }
    this.getList();
  }

  // 编辑
  edit(id) {
    this.router.navigate(['/output/commodity/edit'], { queryParams: { ptype: 1, goodsId: id } });
  }

  // 查看
  view(id) {
    this.router.navigate(['/output/commodity/view'], { queryParams: { goodsId: id } });
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
  }

}
