import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzModalRef, UploadFile, NzModalService, UploadXHRArgs, NzMessageService } from 'ng-zorro-antd';
import { InputPurchaseOrderUploadTipComponent } from '../upload-tip/upload-tip.component';
import { DialogService } from 'src/app/service/dialog.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpHeaders } from '@angular/common/http';
import { clientConfig } from '@env/client-config';
import * as format from 'date-fns/format';

@Component({
  selector: 'app-input-purchase-order-manual-upload',
  templateUrl: './manual-upload.component.html',
  styleUrls: ['./manual-upload.component.less'],
})
export class InputPurchaseOrderManualUploadComponent implements OnInit {
  saleTabs: any[] = [{ key: 'uploadImg', id: 1, show: true }, { key: 'uploadInfo', id: 2 }];
  i: any;
  showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: true,
  };
  fileList = [];
  previewImage: any = '';
  previewVisible = false;
  uploadFlag = false;
  uploadMsg = [
    { 'name': '', 'msg': '' },
  ]; // 上传返回提示信息
  uploadAction = '/input/fileServer/upload';
  // 手工上传参数
  data: any = {
    'uploadType': '', // 上传方式，1-手动导入；2-扫描上传
    'imagesList': [],
    'invoiceFromto': '', // 来源页面
    'invoiceType': ''
  };
  // 票据上传参数
  invoiceData = {
    'imagesList': [],
    'uploadType': "报销单管理",
    'expenseNo': '',
    'type': 'pdf'
  };
  // 手工录入信息
  invoiceInfo: any = {
    invoiceEntity: '专用发票',
    invoiceFreePrice: '',
    invoiceCode: '',
    invoiceNumber: '',
    invoiceCreateDate: '',
    invoiceCheckCode: '',
    invoiceFromto: '',
    invoiceOcrCode: '',
  }
  loading = false;
  finishFlag = false; // 文件上传完成标志，true-完成
  invoiceStatusOption = clientConfig.invoiceStatusOption; // 全部发票状态
  invoiceEntityOption = clientConfig.invoiceEntityOption; // 发票类型

  constructor(
    private http: _HttpClient,
    private modal: NzModalRef,
    private modalHelper: ModalHelper,
    private dialogService: DialogService,
    private sanitizer: DomSanitizer,
    private modalService: NzModalService,
    public msgSrv: NzMessageService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    // 初始化上传信息
    if (this.i) {
      // 票据上传
      if (this.i.ptype === 1) {
        this.invoiceData.expenseNo = this.i.expenseNo;
        this.invoiceInfo.invoiceFromto = '报销单管理';
      } else {
        // 手工上传
        this.data.uploadType = this.i.uploadType;
        this.data.invoiceFromto = this.i.invoiceFromto;
        this.invoiceInfo.invoiceFromto = this.i.invoiceFromto;
      }
    }
  }

  // 纳税申报流程文件上传
  uploadFile = (item: UploadXHRArgs) => {
    this.loading = true;
    const url = item.action!;
    const formData = new FormData();
    formData.set("file", item.file as any);
    return this.http.post(url, formData, { headers: new HttpHeaders().set("content-type", "multipart/form-data") }).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        item.onSuccess!(res, item.file!, res);
        console.log('上传成功:', item.file.name);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  };

  // 上传
  upload() {
    // 票据上传
    if (this.i.ptype === 1) {
      this.pdfUpload();
    } else {
      // 手工上传
      this.manualUpload();
    }
  }

  // 手工上传
  manualUpload() {
    if (!this.finishFlag) {
      this.modalService.warning({
        nzTitle: '请等待文件上传完成！',
      });
      return;
    }
    this.loading = true;
    this.http.post('/input/invoice/saveBatch', this.data).subscribe(res => {
      this.loading = false;
      if (res.code === 0) {
        this.uploadFlag = true;
        this.modal.close(true);
      } else {
        this.uploadFlag = false;
        // 错误结果待修改-应返回一个上传错误消息数组
        this.uploadMsg[0].msg = res.msg;
        this.modal.close(false);
      }
      // 上传结果提示
      this.modalHelper.static(InputPurchaseOrderUploadTipComponent, { i: { ptype: this.uploadFlag, msg: this.uploadMsg } }, 500).subscribe((success) => {
      });
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 票据上传
  pdfUpload() {
    if (!this.finishFlag) {
      this.modalService.warning({
        nzTitle: '请等待文件上传完成！',
      });
      return;
    }
    this.loading = true;
    this.http.post('/input/OCRService/uploadOcr/', this.invoiceData).subscribe(res => {
      this.loading = false;
      if (res.code === 0) {
        this.uploadFlag = true;
        this.modal.close(true);
      } else {
        this.uploadFlag = false;
        // 错误结果待修改-应返回一个上传错误消息数组
        this.uploadMsg[0].msg = res.msg;
        this.modal.close(false);
      }
      // 上传结果提示
      this.modalHelper.static(InputPurchaseOrderUploadTipComponent, { i: { ptype: this.uploadFlag, msg: this.uploadMsg } }, 500).subscribe((success) => {
      });
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 自定义预览文件
  handlePreview = (file: UploadFile) => {
    this.previewImage = this.sanitizer.bypassSecurityTrustResourceUrl(file.url || file.thumbUrl);
    this.previewVisible = true;
  };

  // 图片上传状态处理事件
  handleChange(res): void {
    if (res.type === 'success') {
      this.finishFlag = true;
    }
    const fileList = this.fileList.filter(item => item.status === 'done').map(file => file.response.msg); // 获取已完成上传的图片路径
    if (this.i.ptype === 1) {
      this.invoiceData.imagesList = fileList;
    } else {
      this.data.imagesList = fileList;
    }
  }

  // 手工录入发票信息保存
  save() {
    this.loading = true;
    this.http.post('/input/invoice/saveInvoiceByManual', this.invoiceInfo).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('操作成功');
        this.modal.close(true);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 根据发票二维码识别结果获取发票参数
  getInvoice(event) {
    let code = event.replace(/[\r\n]/g, "");
    code = code.replace(/\s/g, "");
    let flag = false; // 发票二维码有效性识别
    if (code) {
      const codeList = code.split(",");
      if (codeList.length === 9) {
        // 增值税专票
        if (codeList[1] === "01") {
          this.invoiceInfo.invoiceEntity = "专用发票";
          flag = true;
        } else if (codeList[1] === "04") {
          // 纸质普票
          this.invoiceInfo.invoiceEntity = "普通发票";
          flag = true;
        }
      } else if (codeList.length === 7) {
        // 电子普票
        if (codeList[1] === "10") {
          this.invoiceInfo.invoiceEntity = "普通发票";
          flag = true;
        }
      } else {
        flag = false;
      }
      // 发票字段赋值
      if (flag) {
        this.invoiceInfo.invoiceCode = codeList[2];
        this.invoiceInfo.invoiceNumber = codeList[3];
        this.invoiceInfo.invoiceFreePrice = codeList[4];
        this.invoiceInfo.invoiceCreateDate = codeList[5]
          ? codeList[5].replace(/^(\d{4})(\d{2})(\d{2})$/, "$1-$2-$3")
          : "";
        this.invoiceInfo.invoiceCheckCode =
          codeList[6].length > 6 ? codeList[6].slice(-6) : codeList[6];
      }
    }
  }

  // 开票时间处理
  invoiceCreateDateChange(event) {
    this.invoiceInfo.invoiceCreateDate = event ? format(event, 'YYYY-MM-DD HH:mm:ss') : '';
  }

  // tab切换事件
  salesChange(idx: number) {
    if (this.saleTabs[idx].show !== true) {
      this.saleTabs[idx].show = true;
      this.cdr.detectChanges();
    }
  }

  close() {
    this.modal.destroy();
  }

}
