import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-configuration-corporate-customer-rules-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.less'],
})
export class OutputConfigurationCorporateCustomerRulesViewComponent implements OnInit {
  loading = false;
  rulesId = this.route.snapshot.queryParams.rulesId;
  deptName = this.route.snapshot.queryParams.deptName;
  i: any;
  data: any = {
    'rulesId': '',
    'amount': '',
    'invoiceEntity': '',
    'printedQuantity': '',
    'payee': '',
    'reviewer': '',
    'drawer': '',
    'remark': '',
  };

  constructor(
    private route: ActivatedRoute,
    public msgSrv: NzMessageService,
    public http: _HttpClient,
    private router: Router,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.getInfo();
  }

  // 获取规则信息
  getInfo() {
    this.loading = true;
    this.http.get(`/output/companyrules/info/${this.rulesId}`).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.data = res.companyRules;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 编辑
  edit() {
    this.router.navigate(['/output/corporate-customer-rules/edit'], { queryParams: { rulesId: this.rulesId, deptName: this.deptName } });
  }
}
