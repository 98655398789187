import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STData } from '@delon/abc';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';
import { DialogService } from 'src/app/service/dialog.service';
import { clientConfig } from './../../../../environments/client-config';
import * as format from 'date-fns/format';
import { OutputNisBillingViewComponent } from '../nis-billing/view/view.component';
import { OutputInvoiceInAdvanceViewComponent } from '../invoice-in-advance/view/view.component';
import { OutputJdeBillingViewComponent } from '../jde-billing/view/view.component';

@Component({
  selector: 'app-output-no-credit-note',
  templateUrl: './no-credit-note.component.html',
  styleUrls: ['./no-credit-note.component.less'],
})
export class OutputNoCreditNoteComponent implements OnInit {
  saleTabs: any[] = [{ key: 'redPunch', id: 1, show: true }, { key: 'invalid', id: 2 }];
  revocationStatus = [2, 3, 10, 11, 14, 15, 19, 20];
  redPushApprovalStatus = [10, 19];
  invalidApprovalStatus = [14, 20];
  // 红冲待审批数据
  approveShow = false;
  approveMore = 'down';
  approveSumAmount = 0; // 选择发票合计费用
  approveSelectedRows: STData[] = []; // 选中数据
  approveRows: any = [];
  @ViewChild('approveSt', { static: false }) approveSt: STComponent;
  approveColumns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '发票代码', index: 'invoiceCode', i18n: 'output.st.invoiceCode', width: 100 },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'output.st.invoiceNumber', width: 100 },
    { title: '开具方式', index: 'invoicingMethod', i18n: 'output.st.invoicingMethod', width: 100 },
    { title: '关联会计凭证', index: 'accountingDocument', i18n: 'output.st.accountingDocument', width: 150 },
    { title: '开票日期', index: 'invoicingTime', i18n: 'output.st.invoicingTime', type: 'date', dateFormat: 'YYYY-MM-DD', width: 120 },
    { title: '业务类型', index: 'businessType', i18n: 'output.st.businessType', width: 100 },
    { title: '购方名称', index: 'purchaseName', i18n: 'output.st.purchaseName', width: 150 },
    { title: '销方名称', index: 'sellerName', i18n: 'output.st.sellerName', width: 150 },
    { title: '开票金额(不含税)', index: 'excludingTaxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.invoiceTaxAmount', width: 160 },
    { title: '税额', index: 'taxAmount', i18n: 'output.st.taxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', width: 80 },
    { title: '价税合计', index: 'totalAmount', i18n: 'output.st.invoiceTotalAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', width: 100 },
    { title: '发票类型', index: 'invoiceType', i18n: 'output.st.invoiceType', width: 100 },
    { title: '发票载体', index: 'invoiceEntity', i18n: 'output.st.invoiceEntity', width: 100 },
    { title: '发票状态', index: 'invoiceStatus', i18n: 'output.st.invoiceStatus', render: 'invoiceStatus', fixed: 'right', width: 100 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 100,
      buttons: [
        {
          text: '审批',
          i18n: 'st.operate.approval',
          type: 'modal',
          size: 1100,
          paramName: 'i',
          iif: record => this.redPushApprovalStatus.includes(record.invoiceStatus),
          click: (item: any) => this.redPushApproval(item)
        },
        {
          text: '查看',
          i18n: 'st.operate.view',
          type: 'modal',
          size: 1100,
          paramName: 'i',
          iif: record => record.invoiceStatus === 11,
          click: (item: any) => this.redPushView(item)
        },
        {
          text: '撤销',
          i18n: 'st.operate.revocation',
          pop: true,
          popTitle: '确认要撤销此发票吗？',
          iif: record => this.revocationStatus.includes(record.invoiceStatus),
          click: (item: any) => this.revocation(0, item.invoiceId)
        },
      ]
    }
  ];
  approveQueryParams: any = {
    page: 1,
    limit: 10,
    purchaseName: '',
    sellerName: '',
    invoiceCode: '',
    invoiceNumber: '',
    invoiceType: '',
    invoiceEntity: '',
    invoiceStatus: '',
    invoicingTime: '',
    minExcludingTaxAmount: '',
    maxExcludingTaxAmount: '',
    menuType: '6', // 6-红冲待审批列表
  };
  approvePageTotal = 0;
  approveCreateDate: ''; // 红冲-开票日期
  // 作废待审批数据
  invoicedShow = false;
  invoicedMore = 'down';
  invoicedSumAmount = 0; // 选择发票合计费用
  invoicedSelectedRows: STData[] = []; // 选中数据
  invoicedRows: any = [];
  @ViewChild('invoicedSt', { static: false }) invoicedSt: STComponent;
  invoicedColumns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '发票代码', index: 'invoiceCode', i18n: 'output.st.invoiceCode', width: 100 },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'output.st.invoiceNumber', width: 100 },
    { title: '开具方式', index: 'invoicingMethod', i18n: 'output.st.invoicingMethod', width: 100 },
    { title: '关联会计凭证', index: 'accountingDocument', i18n: 'output.st.accountingDocument', width: 150 },
    { title: '开票日期', index: 'invoicingTime', i18n: 'output.st.invoicingTime', type: 'date', dateFormat: 'YYYY-MM-DD', width: 120 },
    { title: '业务类型', index: 'businessType', i18n: 'output.st.businessType', width: 100 },
    { title: '购方名称', index: 'purchaseName', i18n: 'output.st.purchaseName', width: 150 },
    { title: '销方名称', index: 'sellerName', i18n: 'output.st.sellerName', width: 150 },
    { title: '开票金额(不含税)', index: 'excludingTaxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.invoiceTaxAmount', width: 160 },
    { title: '税额', index: 'taxAmount', i18n: 'output.st.taxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', width: 80 },
    { title: '价税合计', index: 'totalAmount', i18n: 'output.st.invoiceTotalAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', width: 100 },
    { title: '发票类型', index: 'invoiceType', i18n: 'output.st.invoiceType', width: 100 },
    { title: '发票载体', index: 'invoiceEntity', i18n: 'output.st.invoiceEntity', width: 100 },
    { title: '发票状态', index: 'invoiceStatus', i18n: 'output.st.invoiceStatus', render: 'invoiceStatus', fixed: 'right', width: 100 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 100,
      buttons: [
        {
          text: '审批',
          i18n: 'st.operate.approval',
          type: 'modal',
          size: 1100,
          paramName: 'i',
          iif: record => this.invalidApprovalStatus.includes(record.invoiceStatus),
          click: (item: any) => this.invalidApproval(item)
        },
        {
          text: '查看',
          i18n: 'st.operate.view',
          type: 'modal',
          size: 1100,
          paramName: 'i',
          iif: record => record.invoiceStatus === 15,
          click: (item: any) => this.invalidView(item)
        },
        {
          text: '撤销',
          i18n: 'st.operate.revocation',
          pop: true,
          popTitle: '确认要撤销此发票吗？',
          iif: record => this.revocationStatus.includes(record.invoiceStatus),
          click: (item: any) => this.revocation(1, item.invoiceId)
        },
      ]
    }
  ];
  // 列表查询参数
  invoicedQueryParams: any = {
    page: 1,
    limit: 10,
    purchaseName: '',
    sellerName: '',
    invoiceCode: '',
    invoiceNumber: '',
    invoiceType: '',
    invoiceEntity: '',
    invoiceStatus: '',
    invoicingTime: '',
    minExcludingTaxAmount: '',
    maxExcludingTaxAmount: '',
    menuType: '4', // 4-作废待审批列表
  };
  invoicedPageTotal = 0;
  invoicedCreateDate: ''; // 作废-开票日期
  invoiceStatusOption = clientConfig.outputInvoiceStatusOption; // 全部发票状态
  invoiceTypeOption = clientConfig.invoiceTypeOption;
  invoiceCarrierOption = clientConfig.invoiceCarrierOption;
  loading = false;

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private modalService: NzModalService,
    private msgSrv: NzMessageService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getApproveList();
    this.getInvoicedList();
  }

  // 获取红冲列表
  getApproveList() {
    this.loading = true;
    this.http.get('/output/invoice/list', this.approveQueryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.approveRows = data.page.list;
        this.approveQueryParams.page = data.page.currPage;
        this.approveQueryParams.limit = data.page.pageSize;
        this.approvePageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 获取作废列表
  getInvoicedList() {
    this.loading = true;
    this.http.get('/output/invoice/list', this.invoicedQueryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.invoicedRows = data.page.list;
        this.invoicedQueryParams.page = data.page.currPage;
        this.invoicedQueryParams.limit = data.page.pageSize;
        this.invoicedPageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 红冲-审批
  redPushApproval(item) {
    let viewComponent: any = '';
    if (item.businessType === '订单开票') {
      viewComponent = OutputNisBillingViewComponent;
    } else if (item.businessType === '提前开票') {
      viewComponent = OutputInvoiceInAdvanceViewComponent;
    } else {
      viewComponent = OutputJdeBillingViewComponent;
    }
    // ptype: 5-红冲审批
    this.modal.static(viewComponent, { i: { ptype: 5, selectedRows: item } }, 1100).subscribe((success) => {
      if (success) {
        this.getApproveList();
      } else {
        this.approveSt.reload();
      }
    });
  }

  // 红冲-批量审批
  bulkRedPushApproval() {
    this.modalService.confirm({
      nzTitle: '确认要对此发票进行审批操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          this.msgSrv.success('审批成功！');
          this.approveSt.load(); // 接口对接后删除
          this.getApproveList();
          this.approveSumAmount = 0;
          this.approveSelectedRows = [];
          resolve(true);
        }).catch(() => console.log('审批失败!'))
    });
  }

  // 作废-审批
  invalidApproval(item) {
    let viewComponent: any = '';
    if (item.businessType === '订单开票') {
      viewComponent = OutputNisBillingViewComponent;
    } else if (item.businessType === '提前开票') {
      viewComponent = OutputInvoiceInAdvanceViewComponent;
    } else {
      viewComponent = OutputJdeBillingViewComponent;
    }
    // ptype: 6-作废审批
    this.modal.static(viewComponent, { i: { ptype: 6, selectedRows: item } }, 1100).subscribe((success) => {
      if (success) {
        this.getInvoicedList();
      } else {
        this.invoicedSt.reload();
      }
    });
  }

  // 作废-批量审批
  bulkInvalidApproval() {
    this.modalService.confirm({
      nzTitle: '确认要对此发票进行审批操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          this.msgSrv.success('审批成功！');
          this.invoicedSt.load(); // 接口对接后删除
          this.getInvoicedList();
          this.invoicedSumAmount = 0;
          this.invoicedSelectedRows = [];
          resolve(true);
        }).catch(() => console.log('审批失败!'))
    });
  }

  // 撤销
  revocation(ptype, invoiceId) {
    this.loading = true;
    this.http.post('/output/invoice/revoked?invoiceIds=' + invoiceId).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('操作成功');
        ptype === 0 ? this.getApproveList() : this.getInvoicedList();
      } else {
        ptype === 0 ? this.approveSt.load() : this.invoicedSt.load();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 红冲-批量撤销
  bulkRedPushRevocation() {
    this.modalService.confirm({
      nzTitle: '确认要撤销此发票吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          const invoiceIds = this.approveSelectedRows.map(item => item.invoiceId).join(',');
          this.revocation(0, invoiceIds);
          this.approveSumAmount = 0;
          this.approveSelectedRows = [];
          resolve(true);
        }).catch(() => console.log('撤销失败!'))
    });
  }

  // 作废-批量撤销
  bulkInvalidRevocation() {
    this.modalService.confirm({
      nzTitle: '确认要撤销此发票吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          const invoiceIds = this.invoicedSelectedRows.map(item => item.invoiceId).join(',');
          this.revocation(1, invoiceIds);
          this.invoicedSumAmount = 0;
          this.invoicedSelectedRows = [];
          resolve(true);
        }).catch(() => console.log('撤销失败!'))
    });
  }

  // 红冲-查看
  redPushView(item) {
    let viewComponent: any = '';
    if (item.businessType === '订单开票') {
      viewComponent = OutputNisBillingViewComponent;
    } else if (item.businessType === '提前开票') {
      viewComponent = OutputInvoiceInAdvanceViewComponent;
    } else {
      viewComponent = OutputJdeBillingViewComponent;
    }
    // ptype=3,审批流程查看
    this.modal.static(viewComponent, { i: { ptype: 3, selectedRows: item } }, 1100).subscribe((success) => {
      if (success) {
        this.getApproveList();
      } else {
        this.approveSt.reload();
      }
    });
  }

  // 作废-查看
  invalidView(item) {
    let viewComponent: any = '';
    if (item.businessType === '订单开票') {
      viewComponent = OutputNisBillingViewComponent;
    } else if (item.businessType === '提前开票') {
      viewComponent = OutputInvoiceInAdvanceViewComponent;
    } else {
      viewComponent = OutputJdeBillingViewComponent;
    }
    // ptype=3,审批流程查看
    this.modal.static(viewComponent, { i: { ptype: 3, selectedRows: item } }, 1100).subscribe((success) => {
      if (success) {
        this.getInvoicedList();
      } else {
        this.invoicedSt.reload();
      }
    });
  }


  // tab切换
  salesChange(idx: number) {
    if (this.saleTabs[idx].show !== true) {
      this.saleTabs[idx].show = true;
      this.cdr.detectChanges();
    }
  }

  /// 红冲-搜索
  approveSearch() {
    this.approveQueryParams.page = 1;
    this.getApproveList();
  }

  // 作废-搜索
  invoicedSearch() {
    this.invoicedQueryParams.page = 1;
    this.getInvoicedList();
  }

  // 红冲-重置
  approveCancel() {
    this.approveQueryParams = {
      page: 1,
      limit: 10,
      purchaseName: '',
      sellerName: '',
      invoiceCode: '',
      invoiceNumber: '',
      invoiceType: '',
      invoiceEntity: '',
      invoiceStatus: '',
      invoicingTime: '',
      minExcludingTaxAmount: '',
      maxExcludingTaxAmount: '',
      menuType: '6', // 6-红冲待审批列表
    };
    this.approveCreateDate = '';
    this.getApproveList();
  }

  // 作废-重置
  invoicedCancel() {
    this.invoicedQueryParams = {
      page: 1,
      limit: 10,
      purchaseName: '',
      sellerName: '',
      invoiceCode: '',
      invoiceNumber: '',
      invoiceType: '',
      invoiceEntity: '',
      invoiceStatus: '',
      invoicingTime: '',
      minExcludingTaxAmount: '',
      maxExcludingTaxAmount: '',
      menuType: '4', // 4-作废待审批列表
    };
    this.invoicedCreateDate = '';
    this.getInvoicedList();
  }

  // 红冲-开票日期事件
  approveCreateDateChange(event) {
    this.approveQueryParams.invoicingTime = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 作废-开票日期事件
  invoicedCreateDateChange(event) {
    this.invoicedQueryParams.invoicingTime = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 红冲-展开
  approveShowOrHidden() {
    this.approveShow = !this.approveShow;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.approveShow) {
      this.approveMore = 'up';
    } else {
      this.approveMore = 'down';
    }
  }

  // 作废-展开
  invoicedShowOrHidden() {
    this.invoicedShow = !this.invoicedShow;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.invoicedShow) {
      this.invoicedMore = 'up';
    } else {
      this.invoicedMore = 'down';
    }
  }

  // 红冲-分页
  approvePagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.approveQueryParams.page !== event.pi) {
        this.approveQueryParams.page = event.pi;
        this.getApproveList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.approveQueryParams.limit !== event.ps) {
        this.approveQueryParams.limit = event.ps;
        this.approveQueryParams.page = 1;
        this.getApproveList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.approveSelectedRows = event.checkbox!;
      this.approveSumAmount = this.approveSelectedRows.reduce((total, cv) => total + cv.totalAmount, 0);
      this.cdr.detectChanges();
    }
  }

  // 红冲-分页
  invoicedPagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.invoicedQueryParams.page !== event.pi) {
        this.invoicedQueryParams.page = event.pi;
        this.getInvoicedList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.invoicedQueryParams.limit !== event.ps) {
        this.invoicedQueryParams.limit = event.ps;
        this.invoicedQueryParams.page = 1;
        this.getInvoicedList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.invoicedSelectedRows = event.checkbox!;
      this.invoicedSumAmount = this.invoicedSelectedRows.reduce((total, cv) => total + cv.totalAmount, 0);
      this.cdr.detectChanges();
    }
  }
}
