import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-rule',
  templateUrl: './rule.component.html',
})
export class OutputRuleComponent implements OnInit {
  loading = false;
  rows: any[] = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '税号', index: 'taxCode', i18n: 'output.st.taxCode' },
    { title: '公司名称', index: 'name', i18n: 'output.st.companyName' },
    { title: '客户', index: 'customers', i18n: 'output.st.customers', format: (item: any) => `${item.customers}个`, },
    {
      title: '操作',
      i18n: 'st.operate',
      buttons: [
        {
          text: '查看规则',
          i18n: 'st.operate.viewRule',
          type: 'link',
          click: record => this.view(record.deptId),
        },
      ]
    }
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    taxCode: '',
    name: '',
  };
  pageTotal = 0;

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private router: Router,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getList();
  }

  // 获取客户规则列表
  getList() {
    this.loading = true;
    this.http.get('/output/customer/deptlist', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }


  // 查看规则
  view(id) {
    this.router.navigate(['/output/viewRule'], { queryParams: { deptId: id } });
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
  }

}
