import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STData } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { InputExpenseAccountManualInputComponent } from './manual-input/manual-input.component';
import { InputPurchaseOrderManualUploadComponent } from '../purchase-order/manual-upload/manual-upload.component';
import { InputExpenseAccountInvoiceInfoComponent } from './invoice-info/invoice-info.component';
import { DialogService } from 'src/app/service/dialog.service';
import * as format from 'date-fns/format';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-input-expense-account',
  templateUrl: './expense-account.component.html',
  styleUrls: ['./expense-account.component.less'],
})
export class InputExpenseAccountComponent implements OnInit {
  show = false;
  more = 'down';
  selectedRows: STData[] = []; // 选中数据
  rows: any = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '报销单号', index: 'expenseNumber', i18n: 'st.expenseNumber', width: 100 },
    { title: '申请者', index: 'applyUser', i18n: 'st.applyUser', width: 100 },
    { title: '报销者', index: 'endUser', i18n: 'st.endUser', width: 100 },
    { title: '申请时间', index: 'applyTime', i18n: 'st.applyTime', type: 'date', dateFormat: 'YYYY-MM-DD HH:mm:ss', width: 200 },
    { title: '报销金额', index: 'amount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.amount', width: 100 },
    { title: '已关联票据金额', index: 'invoicesAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoicesAmount', width: 150 },
    { title: '报销单状态', index: 'expenseStatus', i18n: 'st.expenseStatus', render: 'expenseStatus', width: 150 },
    {
      title: '操作',
      i18n: 'st.operate',
      width: 250,
      buttons: [
        {
          text: '扫描仪上传',
          i18n: 'st.operate.ocrUpload',
          paramName: 'i',
          click: (item: any) => this.ocrUpload(),
        },
        {
          text: '手工输入',
          i18n: 'st.operate.manualInput',
          type: 'modal',
          component: InputExpenseAccountManualInputComponent,
          size: 500,
          paramName: 'i',
          click: (item: any) => this.getList(),
        },
        {
          text: '票据上传',
          i18n: 'st.operate.pdfUpload',
          click: (item: any) => this.pdfUpload(item.id),
        },
      ]
    }
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    expenseNumber: '',
    expenseStatus: '',
    applyUser: '',
    endUser: '',
    applyTime: '',
    minAmount: '',
    maxAmount: '',
    menuType: '1', // 报销单管理列表参数
  };
  applyTime: '';
  // 报销单状态
  expenseStatusOption = [
    { value: 0, label: '待报销' },
    { value: 1, label: '已报销' },
    { value: 2, label: '超额' },
    { value: 3, label: '异常' },
  ]
  pageTotal = 0;
  loading = false;

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private dialogService: DialogService,
    private msgSrv: NzMessageService,
  ) { }

  ngOnInit() {
    this.getList();
  }

  // 获取报销单列表
  getList() {
    this.loading = true;
    this.http.get('/input/oaexpenseinfo/list', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 同步报销单-新增
  add() {
    this.loading = true;
    this.http.post('/input/oaexpenseinfo/save',{}).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('同步刷新成功');
        this.getList();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 扫描仪上传
  ocrUpload() {

  }

  // 电子PDF上传
  pdfUpload(id) {
    this.modal.static(InputPurchaseOrderManualUploadComponent, { i: { ptype: 1, expenseNo: id } }, 1100).subscribe((success) => {
      if (success) {
        this.getList();
      }
    });
  }

  // 查看发票信息
  viewInvoice(id, invoiceType) {
    // 判断票据类型,0-非增值税；1-增值税
    let type = 1;
    // tslint:disable-next-line: prefer-conditional-expression
    if (invoiceType === '专用发票' || invoiceType === '普通发票') {
      type = 1;
    } else {
      type = 0;
    }
    // tslint:disable-next-line: object-literal-shorthand
    this.modal.static(InputExpenseAccountInvoiceInfoComponent, { i: { ptype: type, expenseNo: id, invoiceType: invoiceType } }, 1100).subscribe((success) => {
      if (success) {
        this.st.reload();
      }
    });
  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      expenseNumber: '',
      expenseStatus: '',
      applyUser: '',
      endUser: '',
      applyTime: '',
      minAmount: '',
      maxAmount: '',
      menuType: '1',
    };
    this.applyTime = '';
    this.getList();
  }

  // 申请时间处理事件
  applyTimeDateChange(event) {
    this.queryParams.applyTime = event.length > 0 ? format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD') : '';
  }

  // 展开
  showOrHidden() {
    this.show = !this.show;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.show) {
      this.more = 'up';
    } else {
      this.more = 'down';
    }
  }

  // 报销单默认展开事件
  expenseDataChange(data: STData[]) {
    return data.map((i: STData, index: number) => {
      i.expand = index === 0;
      return i;
    });
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.selectedRows = event.checkbox!;
    }
  }

}
