import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, SettingsService } from '@delon/theme';
import { STComponent, STColumn } from '@delon/abc';
import { DialogService } from 'src/app/service/dialog.service';
import { Router } from '@angular/router';
import { G2TimelineData } from '@delon/chart/timeline';
import { I18NService } from '@core';
import { UserService } from 'src/app/service/user.service';
import { ACLService } from '@delon/acl';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less'],
})
export class DashboardComponent implements OnInit {
  // 待办事项
  todoRows = [];
  statisticsData = [];
  @ViewChild('todost', { static: false }) st: STComponent;
  todoColumns: STColumn[] = [
    { title: '', index: 'descr', render: 'title', className: 'todo-title', },
    {
      title: '',
      buttons: [
        {
          text: '查看',
          type: 'link',
          click: record => this.viewFiling(record),
        },
      ]
    }
  ];
  todoPageTotal = 0;
  statisticsTotal = 0;
  todoParams: any = {
    page: 1,
    limit: 5,
  };
  todoLoading = false;
  statisticsLoading = false;
  // 最新动态数据
  newsRows = [];
  @ViewChild('newst', { static: false }) newst: STComponent;
  newsColumns: STColumn[] = [
    { title: '子公司名称', index: 'deptName', i18n: 'dashboard.group.news.deptName' },
    { title: '税号', index: 'taxCode', i18n: 'dashboard.group.news.taxCode' },
    { title: '时间', index: 'createTime', type: 'date', dateFormat: 'YYYY-MM-DD  HH:mm:ss', i18n: 'dashboard.group.news.createTime' },
    { title: '进度动态', index: 'descr', i18n: 'dashboard.group.news.progress' },
  ];
  newsPageTotal = 0;
  newsParams: any = {
    page: 1,
    limit: 4,
  };
  newsLoading = false;

  // 公告数据
  saleTabs: any[] = [{ key: 'announcement', id: 1, show: true }, { key: 'upgrade', id: 2 }, { key: 'other', id: 3 }];
  noticeListData: any[] = [];
  systemListData: any[] = [];
  otherListData: any[] = [];
  curDate = new Date();
  thisDate = new Date().getDate();
  data: any = {};
  loading = false;
  chart: any[] = [
    { title: 'dashboard.group.statistics.upload', percent: 0, color: '#E0301E' },
    { title: 'dashboard.group.statistics.reportConfirm', percent: 0, color: '#E0301E' },
    { title: 'dashboard.group.statistics.declare', percent: 0, color: '#E0301E' },
    { title: 'dashboard.group.statistics.deduction', percent: 0, color: '#E0301E' },
  ];
  // 节点对应日期数值
  nodeDate: any = {
    uploadDate: '',
    reportConfirmDate: '',
    declareDate: '',
    deductionDate: '',
  }
  // 日历提醒列表
  listDataMap = {
    uploadDate: [
      { type: 'warning', color: '#FFB600', content: 'dashboard.group.statistics.uploadDate' },
    ],
    reportConfirmDate: [
      { type: 'warning', color: '#FFB600', content: 'dashboard.group.statistics.reportConfirmDate' },
    ],
    declareDate: [
      { type: 'warning', color: '#FFB600', content: 'dashboard.group.statistics.declareDate' },
    ],
    deductionDate: [
      { type: 'warning', color: '#FFB600', content: 'dashboard.group.statistics.deductionDate' },
    ]
  };
  cMonth: any = '';

  // 当前组织第一级子公司列表信息
  deptList: any = [];
  deptQueryParams: any = {
    page: 1,
    limit: 10000,
    parentId: 0, // 默认父级为总集团
  }

  // 进项计税统计
  inputDeptTabs: any[] = [];
  deptId = 1; // 默认初始化deptId
  inputData: G2TimelineData[] = [];
  titleMap = {
    y1: this.i18n.fanyi('dashboard.inputStatistics.specialTax'),
    y2: this.i18n.fanyi('dashboard.inputStatistics.generalTax'),
  };
  colorMap = {
    y1: '#FFB442',
    y2: '#20A227',
  };
  inputSpecialTax = 0; // 进项专票税额统计
  inputGeneralTax = 0; // 进项普票税额统计
  inputLoading= false;

  // 销项计税统计
  outputDeptTabs: any[] = [];
  outputData: G2TimelineData[] = [];
  outputSpecialTax = 0; // 销项专票税额统计
  outputGeneralTax = 0; // 销项普票税额统计
  outputLoading = false;

  @ViewChild('statistics', { static: false }) statistics: STComponent;
  statisticsColumn: STColumn[] = [
    { title: '公司名称', index: 'deptName'},
    { title: '增值税', index: 'vat', render: 'vat'},
    { title: '印花税', index: 'sd', render: 'sd'},
    { title: '企业所得税', index: 'cit', render: 'cit'},
    { title: '财报', index: 'fs', render: 'fs'},
    { title: '附加税', index: 'surtax', render: 'surtax'},
    { title: '个人所得税', index: 'iit', render: 'iit'},
  ];

  statisticsParams: any = {
    page: 1,
    limit: 5,
  };

  constructor(
    private http: _HttpClient,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    public settings: SettingsService,
    private router: Router,
    private i18n: I18NService,
    private aclService: ACLService,
  ) { }

  async ngOnInit() {
    this.loading = true;
    this.getStatisticsList();
    // 判断进项税额统计和销项税额统计权限
    if (this.aclService.canAbility(['dashboard:group:inputTaxStatistics', 'dashboard:group:outputTaxStatistics'])) {
      this.deptQueryParams.parentId = this.settings.user.username === 'admin' ? 0 : this.settings.user.deptId; // 获取当前集团deptId
      this.deptList = await this.getChildDeptList(); // 获取当前集团一级子公司列表
      // 初始化进项和销项计税tab列表
      if (this.aclService.canAbility('dashboard:group:inputTaxStatistics')) {
        this.inputDeptTabs = JSON.parse(JSON.stringify(this.deptList));
        this.inputDeptTabs[0].show = true;
        await this.getInputStatisticsData();
      }
      if (this.aclService.canAbility('dashboard:group:outputTaxStatistics')) {
        this.outputDeptTabs = JSON.parse(JSON.stringify(this.deptList));
        this.outputDeptTabs[0].show = true;
        await this.getOutputStatisticsData();
      }
    }
    // 判断申报统计权限
    if (this.aclService.canAbility('dashboard:group:reportStatistics')) {
      await this.getDashboardData();
      // 初始化申报统计日历月份
      this.cMonth = new Date(this.settings.layout.globalDate).getMonth();
    }
    // 判断待办事项权限
    if (this.aclService.canAbility('dashboard:group:todo')) {
      await this.getTodoList();
    }
    // 判断最新动态权限
    if (this.aclService.canAbility('dashboard:group:news')) {
      await this.getNewsList();
    }
    // 判断公告权限
    if (this.aclService.canAbility('dashboard:group:announcement')) {
      await this.getNoticeList();
    }
    this.loading = false;
  }

  // tab切换事件
  salesChange(idx: number) {
    if (this.saleTabs[idx].show !== true) {
      this.saleTabs[idx].show = true;
      this.cdr.detectChanges();
    }
  }

  // 获取公告管理列表
  getNoticeList() {
    this.http.get('/sys/announcement/list').subscribe(data => {
      const flag = this.dialogService.getErrorCode(data);
      if (flag) {
        if (data.page.list.length > 0) {
          const noticeTypes = [1, 2, 3, 6];
          this.noticeListData = data.page.list.filter(i => noticeTypes.includes(i.type) === true).slice(0, 7);
          this.systemListData = data.page.list.filter(i => i.type === 5).slice(0, 7);
          this.otherListData = data.page.list.filter(i => i.type === 4).slice(0, 7);
        }
      }
    }, error => {
      console.log('HTTP Error', error);
    });
  }

  // 获取集团总览数据
  getDashboardData() {
    this.http.get('/filing/dashboard').subscribe(data => {
      const flag = this.dialogService.getErrorCode(data);
      if (flag) {
        if (data.data) {
          this.data = data.data;
          // 初始化纳税申报饼状图统计数据
          this.chart[0].percent = this.data.uploadPercent;
          this.chart[1].percent = this.data.confirmPercent;
          this.chart[2].percent = this.data.declarePercent;
          this.chart[3].percent = this.data.deductionPercent;
          for (const item of this.chart) {
            if (item.percent >= 0.6 && item.percent < 0.8) {
              item.color = '#FFB600';
            } else if (item.percent >= 0.8) {
              item.color = '#46CE5E';
            } else {
              item.color = '#E0301E';
            }
          }

          // 初始化申报节点日期和状态
          if (data.data.filingNode) {
            // 节点时间
            this.nodeDate.uploadDate = data.data.filingNode.uploadDate;
            this.nodeDate.reportConfirmDate = data.data.filingNode.reportConfirmDate;
            this.nodeDate.declareDate = data.data.filingNode.declareDate;
            this.nodeDate.deductionDate = data.data.filingNode.deductionDate;
            // 节点状态
            this.listDataMap.uploadDate[0].color = this.chart[0].color;
            this.listDataMap.reportConfirmDate[0].color = this.chart[1].color;
            this.listDataMap.declareDate[0].color = this.chart[2].color;
            this.listDataMap.deductionDate[0].color = this.chart[3].color;
          }
        }
      }
    }, error => {
      console.log('HTTP Error', error);
    });
  }

  // 待办事项列表
  getTodoList() {
    this.todoLoading = true;
    this.http.get('/sys/notice/list', this.todoParams).subscribe(data => {
      this.todoLoading = false;
      this.dialogService.getErrorCode(data);
      if (data.code === 0) {
        this.todoRows = data.page.list;
        this.todoParams.page = data.page.currPage;
        this.todoParams.limit = data.page.pageSize;
        this.todoPageTotal = data.page.totalCount;
      }
    }, error => {
      this.todoLoading = false;
      console.log('HTTP Error', error);
    });
  }

  getStatisticsList(){
    this.statisticsLoading = true;
    this.http.get('/sys/tax/page', this.statisticsParams).subscribe(data => {
      this.statisticsLoading = false;
      if (data.code === 0) {
        this.statisticsData = data.page.list;
        for(const index in this.statisticsData){
          const item = this.statisticsData[index];
          this.statisticsData[index].vat = -1;
          this.statisticsData[index].fs = -1;
          this.statisticsData[index].cit = -1;
          this.statisticsData[index].sd = -1;
          this.statisticsData[index].iit = -1;
          this.statisticsData[index].surtax = -1;
          for(const subIndex in item.taxList){
            const tax = item.taxList[subIndex];
            if (tax.taxId === 1){
              this.statisticsData[index].vat = 1;
            }else if (tax.taxId === 2){
              this.statisticsData[index].sd = 1;
            }else if (tax.taxId === 3){
              this.statisticsData[index].cit = 1;
            }else if (tax.taxId === 4){
              this.statisticsData[index].fs = 1;
            }else if (tax.taxId === 5){
              this.statisticsData[index].surtax = 1;
            }else if (tax.taxId === 6){
              this.statisticsData[index].iit = 1;
            }
          }
        }
        console.log(this.statisticsData);
        this.statisticsParams.page = data.page.currPage;
        this.statisticsParams.limit = data.page.pageSize;
        this.statisticsTotal = data.page.totalCount;
      }
    }, error => {
      this.statisticsLoading = false;
      console.log('HTTP Error', error);
    });
  }

  // 查看待办-操作记录详情
  viewFiling(record) {
    this.router.navigate(['/filing/record/info'], { queryParams: { filingId: record.filingId, deptId: record.deptId } });
  }

  // 最新动态列表
  getNewsList() {
    this.newsLoading = true;
    this.http.get('/filing/operatelog/list', this.newsParams).subscribe(data => {
      this.newsLoading = false;
      this.dialogService.getErrorCode(data);
      if (data.code === 0) {
        this.newsRows = data.page.list;
        this.newsParams.page = data.page.currPage;
        this.newsParams.limit = data.page.pageSize;
        this.newsPageTotal = data.page.totalCount;
      }
    }, error => {
      this.newsLoading = false;
      console.log('HTTP Error', error);
    });
  }

  calendarChange(event) {
    this.thisDate = event.getDate();
  }

  // 待办事项分页
  todoPagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.todoParams.page !== event.pi) {
        this.todoParams.page = event.pi;
        this.getTodoList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.todoParams.limit !== event.ps) {
        this.todoParams.limit = event.ps;
        this.todoParams.page = 1;
        this.getTodoList();
      }
    }
  }

  statisticschange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.statisticsParams.page !== event.pi) {
        this.statisticsParams.page = event.pi;
        this.getStatisticsList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.todoParams.limit !== event.ps) {
        this.statisticsParams.limit = event.ps;
        this.statisticsParams.page = 1;
        this.getStatisticsList();
      }
    }
  }


  // 最新动态分页
  newsPagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.newsParams.page !== event.pi) {
        this.newsParams.page = event.pi;
        this.getNewsList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.newsParams.limit !== event.ps) {
        this.newsParams.limit = event.ps;
        this.newsParams.page = 1;
        this.getNewsList();
      }
    }
  }

  // 获取当前组织一级子公司列表
  getChildDeptList() {
    return new Promise((resolve, reject) => {
      this.http.get('/sys/dept/list', this.deptQueryParams).subscribe((data: any) => {
        this.dialogService.getErrorCode(data);
        if (data.msg === 'success') {
          if (data.page.list.length > 0) {
            this.deptId = data.page.list[0].deptId; // 获取初始化deptId
            resolve(data.page.list);
          }
        }
      }, error => {
        resolve(error.message);
      });
    });
  }

  // 获取进项税额统计数据
  getInputStatisticsData() {
    this.inputLoading = true;
    this.http.get('/input/invoice/taxStatistics?deptId=' + this.deptId).subscribe(data => {
      this.inputLoading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        if (data.data.length > 0) {
          this.generateInputData(data.data);
        }
      }
    }, error => {
      this.inputLoading = false;
      console.log('HTTP Error', error);
    });
  }

  // 进项税额数据处理
  generateInputData(data) {
    this.inputGeneralTax = data[0].tax || 0;
    this.inputSpecialTax = data[1].tax || 0;
    // 处理普票和专票税额列表，组成折线图数据
    const generalTaxList = data[0].taxStatistics;
    let specialTaxList = data[1].taxStatistics;
    specialTaxList.map((item, specialIndex) => {
      item.y2 = generalTaxList[specialIndex].tax;
    })
    specialTaxList = JSON.parse(JSON.stringify(specialTaxList).replace(/date/g, "x"));
    specialTaxList = JSON.parse(JSON.stringify(specialTaxList).replace(/tax/g, "y1"));
    this.inputData = specialTaxList;
    // 对应集团组织所在tab赋值
    const index = this.inputDeptTabs.map(item => item.deptId).indexOf(this.deptId);
    if (index > -1) {
      this.inputDeptTabs[index].data = [...this.inputData];
    } else {
      console.log('组织Id错误！');
    }
  }

  // 获取销项税额统计数据
  getOutputStatisticsData() {
    this.outputLoading = true;
    this.http.get('/output/invoice/taxStatistics?deptId=' + this.deptId).subscribe(data => {
      this.outputLoading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        if (data.data.length > 0) {
          this.generateOutputData(data.data);
        }
      }
    }, error => {
      this.outputLoading = false;
      console.log('HTTP Error', error);
    });
  }

  // 销项税额数据处理
  generateOutputData(data) {
    this.outputGeneralTax = data[0].tax || 0;
    this.outputSpecialTax = data[1].tax || 0;
    // 处理普票和专票税额列表，组成折线图数据
    const generalTaxList = data[0].taxStatistics;
    let specialTaxList = data[1].taxStatistics;
    specialTaxList.map((item, specialIndex) => {
      item.y2 = generalTaxList[specialIndex].tax;
    })
    specialTaxList = JSON.parse(JSON.stringify(specialTaxList).replace(/date/g, "x"));
    specialTaxList = JSON.parse(JSON.stringify(specialTaxList).replace(/tax/g, "y1"));
    this.outputData = specialTaxList;
    // 对应集团组织所在tab赋值
    const index = this.outputDeptTabs.map(item => item.deptId).indexOf(this.deptId);
    if (index > -1) {
      this.outputDeptTabs[index].data = [...this.outputData];
    } else {
      console.log('组织Id错误！');
    }
  }

  // 进项税额统计折线图切换事件
  inputStatisticsChange(idx: number) {
    if (this.inputDeptTabs[idx].show !== true) {
      this.inputDeptTabs[idx].show = true;
      this.deptId = this.inputDeptTabs[idx].deptId;
      this.getInputStatisticsData();
      this.cdr.detectChanges();
    }
  }

  // 销项税额统计切换事件
  outputStatisticsChange(idx: number) {
    if (this.outputDeptTabs[idx].show !== true) {
      this.outputDeptTabs[idx].show = true;
      this.deptId = this.outputDeptTabs[idx].deptId;
      this.getOutputStatisticsData();
      this.cdr.detectChanges();
    }
  }

}
