import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-input-invoice-overview-mark',
  templateUrl: './mark.component.html',
})
export class InputInvoiceOverviewMarkComponent implements OnInit {
  record: any = {};
  i: any;
  ptype = 0; // 页面类型，默认0新增;1编辑
  loading = false;
  data: any = {
    'invoiceId': '',
    'item': '',
  }
  // 标记类型
  markOption = [
    { value: '银行手续费', label: '银行手续费' },
    { value: '关联方服务费', label: '关联方服务费' },
    { value: '礼品类-视同销售(科目)', label: '礼品类-视同销售(科目)' },
    { value: '礼品类-视同销售(供应商)', label: '礼品类-视同销售(供应商)' },
    { value: '礼品类-视同销售(备注)', label: '礼品类-视同销售(备注)' },
    { value: '礼品类-视同销售(关键字)', label: '礼品类-视同销售(关键字)' },
    { value: '福利类-常规', label: '福利类-常规' },
    { value: '福利类-其他', label: '福利类-其他' },
    { value: '福利费-餐饮住宿', label: '福利费-餐饮住宿' },
    { value: '福利费-洗衣住宿', label: '福利费-洗衣住宿' },
  ]

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {

  }

  // 保存信息
  save() {
    this.modal.close(true);
    this.msgSrv.success('操作成功');
  }

  close() {
    this.modal.destroy();
  }
}
