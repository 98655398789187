import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-configuration-subject-edit',
  templateUrl: './edit.component.html',
})
export class OutputConfigurationSubjectEditComponent implements OnInit {
  loading = false;
  i: any;
  ptype = 0;
  data: any = {
    subjectId: '',
    deptId: '',
    standardAccountId: '',
    name: '',
  }
  // 标准会计科目
  accountingSubjectsOption = [];

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.ptype = this.i.ptype || 0;
    if (this.ptype === 1) {
      this.data = this.i.selectedRows;
    }
    this.getStandardAccountList();
  }

  // 获取标准会计准则列表
  getStandardAccountList() {
    this.loading = true;
    this.http.get('/output/standardaccount/alllist').subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.accountingSubjectsOption = data.list;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 提交新增或修改
  saveOrUpdate() {
    if (this.ptype === 1) {
      this.update();
    } else {
      this.save();
    }
  }

  // 新增
  save() {
    this.loading = true;
    this.http.put('/output/subjects/save', this.data).subscribe(res => {
      this.loading = false;
      const flag = this.dialogService.getErrorCode(res);
      if (flag) {
        this.modal.close(true);
        this.msgSrv.success('操作成功！');
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 修改
  update() {
    this.loading = true;
    this.http.post('/output/subjects/update', this.data).subscribe(res => {
      this.loading = false;
      const flag = this.dialogService.getErrorCode(res);
      if (flag) {
        this.modal.close(true);
        this.msgSrv.success('操作成功！');
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  close() {
    this.modal.destroy();
  }
}
