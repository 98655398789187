import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InputInvoiceOverviewComponent } from './invoice-overview/invoice-overview.component';
import { InputPurchaseOrderComponent } from './purchase-order/purchase-order.component';
import { InputExpenseAccountComponent } from './expense-account/expense-account.component';
import { InputLedgerBankComponent } from './ledger-bank/ledger-bank.component';
import { InputStatisticalSignatureComponent } from './statistical-signature/statistical-signature.component';
import { InputPurchaseOrderAuthenticityComponent } from './purchase-order/authenticity/authenticity.component';
import { InputRuleSettingComponent } from './rule-setting/rule-setting.component';
import { InputProductComponent } from './product/product.component';
import { InputExpenseAccountAuthenticityComponent } from './expense-account/authenticity/authenticity.component';
import { InputPurchaseOrderCreditComponent } from './purchase-order/credit/credit.component';
import { InputPurchaseOrderCertificationComponent } from './purchase-order/certification/certification.component';
import { InputPurchaseOrderTransferOutComponent } from './purchase-order/transfer-out/transfer-out.component';
import { InputPurchaseOrderAbnormalInvoiceComponent } from './purchase-order/abnormal-invoice/abnormal-invoice.component';
import { InputExpenseAccountAbnormalInvoiceComponent } from './expense-account/abnormal-invoice/abnormal-invoice.component';
import { InputExpenseAccountTransferOutComponent } from './expense-account/transfer-out/transfer-out.component';
import { InputExpenseAccountCertificationComponent } from './expense-account/certification/certification.component';
import { InputExpenseAccountCreditComponent } from './expense-account/credit/credit.component';
import { InputPurchaseOrderRefundComponent } from './purchase-order/refund/refund.component';
import { InputPurchaseOrderManualUploadComponent } from './purchase-order/manual-upload/manual-upload.component';
import { InputPurchaseOrderUploadTipComponent } from './purchase-order/upload-tip/upload-tip.component';
import { InputTransferOutComponent } from './transfer-out/transfer-out.component';
import { InputTransferOutConfigurationComponent } from './transfer-out/configuration/configuration.component';
import { InputTransferOutConfigurationAddComponent } from './transfer-out/configuration-add/configuration-add.component';
import { InputCertificationComponent } from './certification/certification.component';
import { InputConfigurationComponent } from './configuration/configuration.component';
import { InputPurchaseOrderThreeSingleMatchesComponent } from './purchase-order/three-single-matches/three-single-matches.component';
import { InputPurchaseOrderThreeSingleMatchesMaterialMatchComponent } from './purchase-order/three-single-matches/material-match/material-match.component';
import { InputInvoiceOverviewExpenseOverviewComponent } from './invoice-overview/expense-overview/expense-overview.component';
import { InputConfigurationFaultTolerantComponent } from './configuration/fault-tolerant/fault-tolerant.component';
import { InputConfigurationInvoiceVerificationComponent } from './configuration/invoice-verification/invoice-verification.component';
import { InputTransferOutLabelComponent } from './transfer-out/label/label.component';
import { InputTransferOutTaxFreeComponent } from './transfer-out/tax-free/tax-free.component';
import { InputTransferOutGiftComponent } from './transfer-out/gift/gift.component';
import { InputTransferOutWelfareComponent } from './transfer-out/welfare/welfare.component';
import { InputTransferOutRuleScopeComponent } from './transfer-out/rule-scope/rule-scope.component';

const routes: Routes = [
  { path: 'invoice-overview', component: InputInvoiceOverviewComponent },
  { path: 'purchase-order', component: InputPurchaseOrderComponent },
  { path: 'expense-account', component: InputExpenseAccountComponent },
  { path: 'ledger-bank', component: InputLedgerBankComponent },
  { path: 'statistical-signature', component: InputStatisticalSignatureComponent },
  { path: 'purchase-order/authenticity', component: InputPurchaseOrderAuthenticityComponent },
  { path: 'rule-setting', component: InputRuleSettingComponent },
  { path: 'product', component: InputProductComponent },
  { path: 'purchase-order/credit', component: InputPurchaseOrderCreditComponent },
  { path: 'purchase-order/certification', component: InputPurchaseOrderCertificationComponent },
  { path: 'purchase-order/transfer-out', component: InputPurchaseOrderTransferOutComponent },
  { path: 'purchase-order/abnormal-invoice', component: InputPurchaseOrderAbnormalInvoiceComponent },
  { path: 'expense-account/authenticity', component: InputExpenseAccountAuthenticityComponent },
  { path: 'expense-account/abnormal-invoice', component: InputExpenseAccountAbnormalInvoiceComponent },
  { path: 'expense-account/transfer-out', component: InputExpenseAccountTransferOutComponent },
  { path: 'expense-account/certification', component: InputExpenseAccountCertificationComponent },
  { path: 'expense-account/credit', component: InputExpenseAccountCreditComponent }
,
  { path: 'refund', component: InputPurchaseOrderRefundComponent },
  { path: 'manual-upload', component: InputPurchaseOrderManualUploadComponent },
  { path: 'upload-tip', component: InputPurchaseOrderUploadTipComponent },
  { path: 'transfer-out', component: InputTransferOutComponent },
  { path: 'transfer-out/configuration', component: InputTransferOutConfigurationComponent },
  { path: 'certification', component: InputCertificationComponent },
  { path: 'configuration', component: InputConfigurationComponent },
  { path: 'threeSingleMatches', component: InputPurchaseOrderThreeSingleMatchesComponent },
  { path: 'threeSingleMatches/material-match', component: InputPurchaseOrderThreeSingleMatchesMaterialMatchComponent },
  { path: 'expense-overview', component: InputInvoiceOverviewExpenseOverviewComponent },
  { path: 'fault-tolerant', component: InputConfigurationFaultTolerantComponent },
  { path: 'invoice-verification', component: InputConfigurationInvoiceVerificationComponent },
  { path: 'transfer-out/label', component: InputTransferOutLabelComponent },
  { path: 'transfer-out/tax-free', component: InputTransferOutTaxFreeComponent },
  { path: 'transfer-out/gift', component: InputTransferOutGiftComponent },
  { path: 'transfer-out/welfare', component: InputTransferOutWelfareComponent },
  { path: 'transfer-out/rule-scope', component: InputTransferOutRuleScopeComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InputRoutingModule { }
