import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-configuration-invoice-inventory-edit',
  templateUrl: './edit.component.html',
})
export class OutputConfigurationInvoiceInventoryEditComponent implements OnInit {
  loading = false;
  i: any;
  data: any = {
    inventoryId: '',
    invoiceType: '',
    inventory: '',
  }

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    if (this.i) {
      this.data.inventoryId = this.i.inventoryId;
      this.data.invoiceType = this.i.invoiceType;
      this.data.inventory = this.i.inventory;
    }
  }

  save() {
    this.loading = true;
    this.http.post('/output/invoice/inventory/update', this.data).subscribe(res => {
      this.loading = false;
      const flag = this.dialogService.getErrorCode(res);
      if (flag) {
        this.modal.close(true);
        this.msgSrv.success('操作成功！');
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  close() {
    this.modal.destroy();
  }
}
