import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { typeWithParameters } from '@angular/compiler/src/render3/util';

@Component({
  selector: 'app-input-configuration-fault-tolerant-edit',
  templateUrl: './edit.component.html',
})
export class InputConfigurationFaultTolerantEditComponent implements OnInit {
  loading = false;
  i: any;
  data: any = {
    'id': '',
    'fault': '',
  }

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
  ) { }

  ngOnInit(): void {
    if (this.i) {
      this.data = this.i;
    }
  }

  save() {
    this.msgSrv.success('操作成功');
    this.modal.close(true);
  }

  close() {
    this.modal.destroy();
  }
}
