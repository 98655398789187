import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-input-purchase-order-refund',
  templateUrl: './refund.component.html',
})
export class InputPurchaseOrderRefundComponent implements OnInit {
  loading = false;
  i: any;
  data: any = {
    'invoiceIds': "",
    "expressCompany": "",
    "expressNo": "",
    "detailedReason": "",
    "refundReason": "",
    "refundStatus": "2",
  }
  // 快递公司
  expressCompanyOptions = [
    { value: '顺丰', label: '顺丰' },
    { value: '中通', label: '中通' },
    { value: '圆通', label: '圆通' },
    { value: '韵达', label: '韵达' },
    { value: '天天', label: '天天' },
    { value: '中国邮政EMS', label: '中国邮政EMS' },
    { value: '京东快递', label: '京东快递' },
  ]


  constructor(
    private http: _HttpClient,
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    if (this.i) {
      // 获取退票发票id
      this.data.invoiceIds = this.i.invoiceId;
    }
  }

  // 退票操作
  refund() {
    this.loading = true;
    this.http.post('/input/invoicerefund/save', this.data).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('退票成功');
        this.modal.close(true);
        this.cdr.detectChanges();
      } else {
        this.modal.close(false);
      }
    }, error => {
      console.log('HTTP Error', error);
    });
  }


  close() {
    this.modal.destroy();
  }

}
