import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OutputNISBillingComponent } from './nis-billing/nis-billing.component';
import { OutputJDEBillingComponent } from './jde-billing/jde-billing.component';
import { OutputPrefabricatedInvoiceComponent } from './prefabricated-invoice/prefabricated-invoice.component';
import { OutputInvoicedComponent } from './invoiced/invoiced.component';
import { OutputCreditNoteComponent } from './credit-note/credit-note.component';
import { OutputNoCreditNoteComponent } from './no-credit-note/no-credit-note.component';
import { OutputRuleComponent } from './rule/rule.component';
import { OutputCommodityComponent } from './commodity/commodity.component';
import { OutputProductComponent } from './product/product.component';
import { OutputRuleViewRuleComponent } from './rule/view-rule/view-rule.component';
import { OutputCommodityEditComponent } from './commodity/edit/edit.component';
import { OutputCommodityViewComponent } from './commodity/view/view.component';
import { OutputConfigurationCorporateCustomerRulesComponent } from './configuration/corporate-customer-rules/corporate-customer-rules.component';
import { OutputConfigurationCorporateCustomerRulesViewComponent } from './configuration/corporate-customer-rules/view/view.component';
import { OutputConfigurationCorporateCustomerRulesEditComponent } from './configuration/corporate-customer-rules/edit/edit.component';
import { OutputInvoiceInAdvanceComponent } from './invoice-in-advance/invoice-in-advance.component';
import { OutputPrefabricatedInvoicePendingInvoicingComponent } from './prefabricated-invoice/pending-invoicing/pending-invoicing.component';
import { OutputNoCreditNoteInvoicedListComponent } from './no-credit-note/invoiced-list/invoiced-list.component';
import { OutputConfigurationInvoiceInventoryComponent } from './configuration/invoice-inventory/invoice-inventory.component';
import { OutputConfigurationSubjectComponent } from './configuration/subject/subject.component';
import { OutputConfigurationTaxClassificationComponent } from './configuration/tax-classification/tax-classification.component';

const routes: Routes = [

  { path: 'NIS-billing', component: OutputNISBillingComponent },
  { path: 'JDE-billing', component: OutputJDEBillingComponent },
  { path: 'prefabricated-invoice', component: OutputPrefabricatedInvoiceComponent },
  { path: 'invoiced', component: OutputInvoicedComponent },
  { path: 'credit-note', component: OutputCreditNoteComponent },
  { path: 'no-credit-note', component: OutputNoCreditNoteComponent },
  { path: 'rule', component: OutputRuleComponent },
  { path: 'commodity', component: OutputCommodityComponent },
  { path: 'product', component: OutputProductComponent },
  { path: 'viewRule', component: OutputRuleViewRuleComponent },
  { path: 'commodity/edit', component: OutputCommodityEditComponent },
  { path: 'commodity/view', component: OutputCommodityViewComponent },
  { path: 'corporate-customer-rules', component: OutputConfigurationCorporateCustomerRulesComponent },
  { path: 'corporate-customer-rules/view', component: OutputConfigurationCorporateCustomerRulesViewComponent },
  { path: 'corporate-customer-rules/edit', component: OutputConfigurationCorporateCustomerRulesEditComponent },
  { path: 'invoice-in-advance', component: OutputInvoiceInAdvanceComponent },
  { path: 'pending-invoicing', component: OutputPrefabricatedInvoicePendingInvoicingComponent },
  { path: 'invoiced-list', component: OutputNoCreditNoteInvoicedListComponent },
  { path: 'invoice-inventory', component: OutputConfigurationInvoiceInventoryComponent },
  { path: 'subject', component: OutputConfigurationSubjectComponent },
  { path: 'tax-classification', component: OutputConfigurationTaxClassificationComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OutputRoutingModule { }
