import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminOrganizationComponent } from './organization/organization.component';
import { AdminRoleComponent } from './role/role.component';
import { AdminUserComponent } from './user/user.component';
import { AdminOrganizationEditComponent } from './organization/edit/edit.component';
import { AdminOrganizationViewComponent } from './organization/view/view.component';
import { AdminRoleEditComponent } from './role/edit/edit.component';
import { AdminRoleViewComponent } from './role/view/view.component';
import { AdminRoleEditAuthComponent } from './role/edit-auth/edit-auth.component';
import { AdminUserEditComponent } from './user/edit/edit.component';
import { AdminUserEditPasswordComponent } from './user/edit-password/edit-password.component';
import { AdminOrganizationThirdComponent } from './organization/third/third.component';
import { AdminRoleEditRoleComponent } from './role/edit-role/edit-role.component';

const COMPONENTS = [
  AdminOrganizationComponent,
  AdminRoleComponent,
  AdminUserComponent,
  AdminOrganizationThirdComponent];
const COMPONENTS_NOROUNT = [
  AdminOrganizationEditComponent,
  AdminOrganizationViewComponent,
  AdminRoleEditComponent,
  AdminRoleViewComponent,
  AdminRoleEditAuthComponent,
  AdminUserEditComponent,
  AdminUserEditPasswordComponent,
  AdminRoleEditRoleComponent];

@NgModule({
  imports: [
    SharedModule,
    AdminRoutingModule
  ],
  declarations: [
    ...COMPONENTS,
    ...COMPONENTS_NOROUNT
  ],
  exports: [AdminUserEditComponent, AdminOrganizationEditComponent],
  entryComponents: COMPONENTS_NOROUNT
})
export class AdminModule { }
