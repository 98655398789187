import { Component, OnInit } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';

interface Purchaser {
  name: string
  identifier: string
  address?: string
  phone?: string
  bank?: string
  account?: string
}

interface Seller {
  name: string
  identifier: string
  address: string
  phone: string
  bank: string
  province: string
  account: string
  accountants: {
    payee: string
    drawer: string
    auditor: string
  }
}

interface Product {
  amount: string
  cno: string
  productName: string
  productNo: string
  quantity: number
  rate: string
  specifications: string
  tax: string
  discount: string
  unit: string
  unitPrice: string
  isDiscount: boolean
}

interface InvoiceDetail {
  vatTotal: number
  invoiceAmount: number
  priceTotalExcludingVat: number
  products: Product[]
}

interface Invoice {
  purchaser: Purchaser
  seller: Seller
  invoiceDetail: InvoiceDetail
}

@Component({
  selector: 'app-output-nis-billing-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.less'],
})
export class OutputNisBillingViewInvoiceComponent implements OnInit {
  record: any = {};
  i: any;
  selectedRows: any = []; // 订单列表
  sumAmount = 0; // 订单总额
  singelAmount = 0; // 单张发票金额上限
  invoiceInfo: Invoice = {
    purchaser: {
      name: '北京盛安德科技发展有限公司',
      identifier: '91110102X00384294D',
      address: '',
      phone: '',
      bank: '',
      account: ''
    },
    seller: {
      name: '北京盛安德科技发展有限公司',
      identifier: '91110102X00384294D',
      address: '北京市西城区新德街甲20号楼天岳恒大厦705号',
      phone: '010-82084356',
      bank: '北京市XX银行西城分行',
      province: '北京',
      account: '312467687453434',
      accountants: {
        payee: '张三',
        drawer: '李四吴',
        auditor: '王五陆'
      }
    },
    invoiceDetail: {
      invoiceAmount: 0,
      vatTotal: 0,
      priceTotalExcludingVat: 0,
      products: [
        {
          amount: "",
          cno: "",
          productName: "",
          productNo: "",
          quantity: 0,
          rate: "",
          specifications: "",
          tax: "",
          discount: "",
          unit: "",
          unitPrice: "",
          isDiscount: true
        }
      ]
    }
  };
  invoiceArr: Invoice[];
  selectedInvoice: Invoice;

  constructor(
    private modal: NzModalRef,
    public msgSrv: NzMessageService,
    public http: _HttpClient
  ) { }

  ngOnInit(): void {
    this.selectedRows.push(this.i);
    this.sumAmount = this.i.totalAmount;
    // 获取发票信息
    this.invoiceInfo.invoiceDetail = this.getInvoiceDetail();
    this.invoiceArr = [this.invoiceInfo];
    this.selectedInvoice = this.invoiceArr[0];
  }

  // 获取发票详细信息
  private getInvoiceDetail(): InvoiceDetail {
    let priceTotalExcludingVat = 0;
    let vatTotal = 0;
    const products = [];
    this.selectedRows.forEach(item => {
      priceTotalExcludingVat += (+item.noTaxAmount);
      vatTotal += (+item.taxAmount);
    });
    return {
      priceTotalExcludingVat,
      vatTotal,
      invoiceAmount: priceTotalExcludingVat + vatTotal,
      products,
    };
  }

  // 更改发票价格
  updataPrice(product: Product, $event): void {
    $event.stopPropagation();
    const value = +($event.target.innerText.replace(/,/g, ''));
    this.invoiceInfo.invoiceDetail.products.forEach(item => {
      if (item.cno === product.cno) {
        item.quantity = (+product.amount) / value
      }
    })
  }

  close() {
    this.modal.destroy();
  }
}
