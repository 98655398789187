import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STData } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { InputInvoiceOverviewViewComponent } from './view/view.component';
import { DialogService } from 'src/app/service/dialog.service';
import * as format from 'date-fns/format';
import { clientConfig } from './../../../../environments/client-config';
import { InputPurchaseOrderManualUploadComponent } from '../purchase-order/manual-upload/manual-upload.component';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { environment } from '@env/environment';
import { InputInvoiceOverviewMatchComponent } from './match/match.component';
import { InputInvoiceOverviewEditComponent } from './edit/edit.component';
import { InputInvoiceOverviewMarkComponent } from './mark/mark.component';

@Component({
  selector: 'app-input-invoice-overview',
  templateUrl: './invoice-overview.component.html',
  styleUrls: ['./invoice-overview.component.less'],
})
export class InputInvoiceOverviewComponent implements OnInit {
  show = false;
  more = 'down';
  selectedRows: STData[] = []; // 选中数据
  rows: any = [];
  batchList: any = [{ 'id': '', 'invoiceBatchNumber': '' }]; // 采购单号列表
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '批次号', index: 'batchNumber', render: 'batchNumber', i18n: 'st.batchNumber', width: 100 },
    { title: '发票代码', index: 'invoiceCode', i18n: 'st.invoiceCode', width: 100 },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'st.invoiceNumber', width: 100 },
    { title: '购方企业名称', index: 'invoicePurchaserCompany', i18n: 'st.invoicePurchaserCompany', width: 150 },
    { title: '购方税号', index: 'invoicePurchaserParagraph', i18n: 'st.invoicePurchaserParagraph', width: 100 },
    { title: '销方企业名称', index: 'invoiceSellCompany', i18n: 'st.invoiceSellCompany', width: 150 },
    { title: '销方税号', index: 'invoiceSellParagraph', i18n: 'st.invoiceSellParagraph', width: 100 },
    { title: '票面金额(含税)', index: 'invoiceTotalPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTotalPrice', width: 200 },
    { title: '票面金额(不含税)', index: 'invoiceFreePrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceFreePrice', width: 200 },
    { title: '税额', index: 'invoiceTaxPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTaxPrice', width: 50 },
    { title: '校验码', index: 'invoiceCheckCode', i18n: 'st.invoiceCheckCode', width: 100 },
    { title: '进项转出', index: 'invoiceTransOut', i18n: 'st.invoiceTransOut', width: 100 },
    { title: '开票日期', index: 'invoiceCreateDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceCreateDate', width: 150 },
    { title: '发票类型', index: 'invoiceEntity', render: 'invoiceEntity', i18n: 'st.invoiceEntity', width: 100 },
    { title: '入账日期', index: 'entryDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.entryDate', width: 150 },
    { title: '上传日期', index: 'invoiceUploadDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceUploadDate', width: 150 },
    { title: '上传人', index: 'createUserName', i18n: 'st.createUserName', width: 100 },
    { title: '上传方式', index: 'invoiceUploadType', i18n: 'st.invoiceUploadType', render: 'invoiceUploadType', width: 100 },
    { title: '验真分类', index: 'invoiceVerifyTruth', i18n: 'st.invoiceVerifyTruth', render: 'invoiceVerifyTruth', width: 100 },
    { title: '发票状态', index: 'invoiceStatus', i18n: 'st.invoiceStatus', render: 'invoiceStatus', fixed: 'right', width: 120 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 120,
      buttons: [
        {
          text: '标记',
          i18n: 'st.operate.mark',
          paramName: 'i',
          iif: (item) => item.invoiceStatus > 4,
          click: (item: any) => this.mark(item.id),
        },
        {
          text: '发票信息',
          i18n: 'input.invoiceInfo',
          type: 'modal',
          size: 1100,
          component: InputInvoiceOverviewEditComponent,
          paramName: 'i',
          click: (item: any) => this.getList(),
        },
      ]
    }
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    invoiceBatchNumber: '',
    invoiceCode: '',
    invoiceNumber: '',
    invoiceStatus: '',
    invoicePurchaserCompany: '',
    invoiceSellCompany: '',
    invoiceCreateDateArray: '',
    invoiceTotalPriceBegin: '',
    invoiceTotalPriceEnd: '',
    invoiceFromto: '采购订单',
    invoiceEntity: '',
    createUserName: '',
    invoiceUploadDateArray: '',
  };
  pageTotal: any = 0; // 列表记录总数
  loading = false;
  invoiceStatusOption = clientConfig.invoiceStatusOption; // 全部发票状态
  matchShow = true; // 匹配采购单功能显示标识,默认不显示

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private dialogService: DialogService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) { }

  ngOnInit() {
    this.getList();
  }

  // 获取发票列表
  getList() {
    this.loading = true;
    this.http.get('/input/invoice/invoiceList', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.batchList = data.invoiceBatchEntityList;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 扫描仪上传
  ocrUpload() {

  }

  // 手动上传
  manualUpload() {
    this.modal.static(InputPurchaseOrderManualUploadComponent, { i: { ptype: 0, uploadType: '1', invoiceFromto: '采购订单' } }, 1100).subscribe((success) => {
      if (success) {
        this.getList();
      }
    });
  }

  // 匹配采购单
  matchPurchase() {
    const ids = this.selectedRows.map(item => item.id).join(',');
    this.modal.static(InputInvoiceOverviewMatchComponent, { i: { invoiceIds: ids } }, 1100).subscribe((success) => {
      if (success) {
        this.getList();
      }
    });
  }

  // 刷新列表
  refreshList() {
    this.getList();
  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      invoiceBatchNumber: '',
      invoiceCode: '',
      invoiceNumber: '',
      invoiceStatus: '',
      invoicePurchaserCompany: '',
      invoiceSellCompany: '',
      invoiceCreateDateArray: '',
      invoiceTotalPriceBegin: '',
      invoiceTotalPriceEnd: '',
      invoiceFromto: '采购订单',
      invoiceEntity: '',
      createUserName: '',
      invoiceUploadDateArray: '',
    };
    this.getList();
  }

  // 标记
  mark(id) {
    this.modal.static(InputInvoiceOverviewMarkComponent, { i: { invoiceId: id } }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      }
    });
  }

  // 展开
  showOrHidden() {
    this.show = !this.show;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.show) {
      this.more = 'up';
    } else {
      this.more = 'down';
    }
  }

  // 导出发票信息
  export() {
    if (this.selectedRows.length > 0) {
      this.exportById();
    } else {
      this.exportBySearch();
    }
  }

  // 按所选数据导出
  exportById() {
    const ids = this.selectedRows.map(item => item.id).join(',');
    const token = this.tokenService.get().token;
    window.open(environment.SERVER_URL + "/input/invoice/exportRecordListByIds?invoiceIds=" +
      ids +
      "&title=发票总览列表" +
      "&Authorization=" + token
    );
    this.selectedRows = [];
    this.st.reload();
  }

  // 按查询条件导出
  exportBySearch() {
    const token = this.tokenService.get().token;
    window.open(environment.SERVER_URL + "/input/invoice/exportRecordList?invoiceCode=" +
      this.queryParams.invoiceCode +
      "&invoiceNumber=" +
      this.queryParams.invoiceNumber +
      "&invoicePurchaserCompany=" +
      this.queryParams.invoicePurchaserCompany +
      "&invoiceSellCompany=" +
      this.queryParams.invoiceSellCompany +
      "&invoiceCreateDateArray=" +
      this.queryParams.invoiceCreateDateArray +
      "&invoiceStatus=" +
      this.queryParams.invoiceStatus +
      "&invoiceEntity=" +
      this.queryParams.invoiceEntity +
      "&invoiceUploadDateArray=" +
      this.queryParams.invoiceUploadDateArray +
      "&invoiceTotalPriceBegin=" +
      this.queryParams.invoiceTotalPriceBegin +
      "&invoiceTotalPriceEnd=" +
      this.queryParams.invoiceTotalPriceEnd +
      "&invoiceFromto=采购订单" +
      "&createUserName=" +
      this.queryParams.createUserName +
      "&invoiceBatchNumber=" +
      this.queryParams.invoiceBatchNumber +
      "&Authorization=" + token
    );
  }

  // 开票日期事件
  invoiceCreateDateChange(event) {
    this.queryParams.invoiceCreateDateArray = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 上传日期事件
  invoiceUploadDateChange(event) {
    this.queryParams.invoiceUploadDateArray = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.selectedRows = event.checkbox!;
      // 判断匹配采购单功能显示
      if (this.selectedRows.length > 0) {
        this.matchShow = false;
        // tslint:disable-next-line: prefer-conditional-expression
        if (this.selectedRows.every(item => !item.batchNumber)) {
          this.matchShow = false;
        } else {
          this.matchShow = true;
        }
      }
    }
  }

}
