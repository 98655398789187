import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STData } from '@delon/abc';
import { NzMessageService, NzModalService, UploadXHRArgs } from 'ng-zorro-antd';
import { DialogService } from 'src/app/service/dialog.service';
import { InputTransferOutGiftEditComponent } from '../gift/edit/edit.component';
import * as format from 'date-fns/format';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-input-transfer-out-tax-free',
  templateUrl: './tax-free.component.html',
  styleUrls: ['./tax-free.component.less'],
})
export class InputTransferOutTaxFreeComponent implements OnInit {
  loading = false;
  show = false;
  more = 'down';
  selectedRows: STData[] = []; // 选中数据
  rows: any = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '免税规则类别编号', index: 'numbering', width: 200 },
    { title: '规则范围', index: 'labelRange', width: 100 },
    { title: '免税规则类别名称', index: 'labelName', width: 200 },
    { title: '标签类型', index: 'attributionName', width: 100 },
    { title: '所属集团', index: 'deptName', width: 200 },
    { title: '创建时间', index: 'createTime', type: 'date', dateFormat: 'YYYY-MM-DD', width: 120 },
    { title: '启用状态', index: 'labelFalg', render: 'labelFalg', width: 100 },
    {
      title: '操作',
      width: 100,
      i18n: 'st.operate',
      buttons: [
        {
          text: '编辑',
          i18n: 'st.operate.edit',
          paramName: 'i',
          click: (item: any) => this.edit(item),
        },
        {
          text: '删除',
          i18n: 'st.operate.delete',
          paramName: 'i',
          pop: true,
          popTitle: '删除操作不可恢复，确认删除吗？',
          click: (item: any) => this.delete(item.id),
        },
      ]
    }
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    numbering: '',
    labelName: '',
    labelAttribution: '',
    rangeId: '',
    createTimeArray: '',
    inquireFalg: '1', // 1-免税
  };
  createTime: ''; // 创建时间
  ruleScopeOption = [{ id: '', labelName: '' },]; // 规则范围
  taxFreeCodeOption = [
    { value: '1001', label: '1001' },
    { value: '1002', label: '1002' },
    { value: '1003', label: '1003' },
    { value: '1004', label: '1004' },
    { value: '1005', label: '1005' },
  ]; // 免税规则分类编码
  labelOption: any = [
    { id: '', labelName: '' },
  ];
  pageTotal= 0;
  importAction = "/input/label/UploadExcel";

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private modalSrv: NzModalService,
    public msg: NzMessageService,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.getRuleScopeList();
    this.getLabelList();
    this.getList();
  }

  // 获取列表数据
  getList() {
    this.loading = true;
    this.http.get('/input/label/findLabel', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 获取规则范围列表
  getRuleScopeList() {
    this.http.get('/input/label/findLabelList?inquireFalg=5').subscribe(data => {
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.ruleScopeOption = data.data;
      }
    }, error => {
      console.log('HTTP Error', error);
    });
  }

  // 获取标签列表
  getLabelList() {
    this.http.get('/input/label/findLabelList?inquireFalg=0').subscribe(data => {
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.labelOption = data.data;
      }
    }, error => {
      console.log('HTTP Error', error);
    });
  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // Excel上传
  importUpload = (item: UploadXHRArgs) => {
    this.loading = true;
    const url = item.action!;
    const formData = new FormData();
    formData.set("file", item.file as any);
    return this.http.post(url, formData, { headers: new HttpHeaders().set("content-type", "multipart/form-data") }).subscribe(data => {
      this.loading = false;
      if (data.msg === 'success') {
        this.modalSrv.success({
          nzTitle: '上传成功',
          nzOnOk: () => this.getList()
        });
      } else {
        this.modalSrv.error({
          nzTitle: '上传失败',
          nzOnOk: () => console.log('Info OK')
        });
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    }
    );
  };

  // 删除
  delete(id) {
    this.loading = true;
    this.http.post('/input/label/deleteLabel?ids=' + id).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msg.success('删除成功');
        this.getList();
      } else {
        this.st.reload();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 批量删除
  bulkDelete() {
    this.modalSrv.confirm({
      nzTitle: '删除操作不可恢复，确认删除吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        // tslint:disable-next-line: no-shadowed-variable
        new Promise((resolve, reject) => {
          // 处理获取的数据id
          const idList = new Array();
          this.selectedRows.map(item => {
            idList.push(item.id);
          })
          const ids = idList.join(',');
          this.delete(ids);
          this.selectedRows = [];
          resolve(true);
        }).catch(() => console.log('批量删除失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          this.selectedRows = [];
          this.st.reload();
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 新增
  add() {
    this.modal.static(InputTransferOutGiftEditComponent, { i: { ptype: 0, inquireFalg: '1' } }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      }
    });
  }

  // 编辑
  edit(item) {
    this.modal.static(InputTransferOutGiftEditComponent, { i: { ptype: 1, item } }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      }
    });
  }

  // 启用/禁用事件
  changeStatus(event, id) {
    const labelFalg = event ? 1 : 0;
    this.http.post('/input/label/updatelabelFalg?ids=' + id + '&labelFalg=' + labelFalg).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msg.success('操作成功');
        this.getList();
      } else {
        this.st.reload();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 批量启用
  bulkEnable() {
    this.modalSrv.confirm({
      nzTitle: '确认启用所选标签吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        // tslint:disable-next-line: no-shadowed-variable
        new Promise((resolve, reject) => {
          // 处理获取的数据id
          const idList = new Array();
          this.selectedRows.map(item => {
            idList.push(item.id);
          })
          const ids = idList.join(',');
          this.changeStatus(true, ids);
          this.selectedRows = [];
          resolve(true);
        }).catch(() => console.log('启用所选标签失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          this.selectedRows = [];
          this.st.reload();
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 批量禁用
  bulkDisable() {
    this.modalSrv.confirm({
      nzTitle: '确认禁用所选标签吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        // tslint:disable-next-line: no-shadowed-variable
        new Promise((resolve, reject) => {
          // 处理获取的数据id
          const idList = new Array();
          this.selectedRows.map(item => {
            idList.push(item.id);
          })
          const ids = idList.join(',');
          this.changeStatus(false, ids);
          this.selectedRows = [];
          resolve(true);
        }).catch(() => console.log('禁用所选标签失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          this.selectedRows = [];
          this.st.reload();
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // excel上传
  excelUpload() {

  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      numbering: '',
      labelName: '',
      labelAttribution: '',
      rangeId: '',
      createTimeArray: '',
      inquireFalg: '2', // 2-礼品
    };
    this.createTime = '';
    this.getList();
  }

  // 创建时间事件
  createTimeChange(event) {
    this.queryParams.createTimeArray = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 展开
  showOrHidden() {
    this.show = !this.show;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.show) {
      this.more = 'up';
    } else {
      this.more = 'down';
    }
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.selectedRows = event.checkbox!;
      this.cdr.detectChanges();
    }
  }
}
