import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { Router } from '@angular/router';
import { OutputConfigurationTaxClassificationEditComponent } from './edit/edit.component';
import { NzMessageService } from 'ng-zorro-antd';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-configuration-tax-classification',
  templateUrl: './tax-classification.component.html',
  styleUrls: ['./tax-classification.component.less'],
})
export class OutputConfigurationTaxClassificationComponent implements OnInit {
  loading = false;
  rows: any = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '商品编号', index: 'goodsNumber', i18n: 'output.commodity.goodsNumber' },
    { title: '商品名称', index: 'goodsName', i18n: 'output.commodity.goodsName' },
    { title: '商品税编', index: 'goodsTaxCode', i18n: 'output.commodity.goodsTaxCode' },
    { title: '商品税率', index: 'goodsTaxRate', render: 'goodsTaxRate', i18n: 'output.commodity.goodsTaxRate' },
    {
      title: '操作',
      i18n: 'st.operate',
      buttons: [
        {
          text: '编辑',
          i18n: 'st.operate.edit',
          click: (item: any) => this.edit(item)
        },
        {
          text: '删除',
          i18n: 'st.operate.delete',
          pop: true,
          popTitle: '确认要进行删除操作吗？',
          click: (item: any) => this.delete(item.classificationId)
        }
      ]
    }
  ];
  queryParams: any = {
    page: 1,
    limit: 10,
    goodsNumber: '',
    goodsName: '',
    goodsTaxCode: '',
    goodsTaxRate: '',
  };
  pageTotal = 0;
  rateOption = [
    { value: '0.07', label: '7%' },
    { value: '0.11', label: '11%' },
    { value: '0.13', label: '13%' },
  ]; // 税率

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private msg: NzMessageService,
    private router: Router,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getList();
  }

  // 获取商品列表
  getList() {
    this.loading = true;
    this.http.get('/output/tax/classification/list', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 添加商品
  add() {
    this.modal.static(OutputConfigurationTaxClassificationEditComponent, { i: { ptype: 0 } }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.queryParams.goodsTaxRate = this.queryParams.goodsTaxRate || '';
    this.getList();
  }

  // 编辑
  edit(item) {
    this.modal.static(OutputConfigurationTaxClassificationEditComponent, { i: { ptype: 1, selectedRows: item } }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 删除
  delete(classificationId) {
    this.loading = true;
    this.http.delete('/output/tax/classification/delete?classificationIds=' + classificationId).subscribe(res => {
      this.loading = false;
      const flag = this.dialogService.getErrorCode(res);
      if (flag) {
        this.msg.success('操作成功！');
        this.getList();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
  }
}
