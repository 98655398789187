import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { STComponent, STColumn } from '@delon/abc';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-input-statistical-signature-invoice-statistics',
  templateUrl: './invoice-statistics.component.html',
  styleUrls: ['./invoice-statistics.component.less'],
})
export class InputStatisticalSignatureInvoiceStatisticsComponent implements OnInit {
  saleTabs: any[] = [{ key: 'normal', id: 1, show: true }, { key: 'abnormal', id: 2 }];
  normalRows: any = []; // 统计数据
  @ViewChild('normalSt', { static: false }) normalSt: STComponent;
  columns: STColumn[] = [
    { title: '发票类型', index: 'invoiceType', render: 'invoiceType', i18n: 'st.invoiceEntity' },
    { title: '抵扣份数', index: 'deductInvoiceNum', i18n: 'st.deductibleNum' },
    { title: '抵扣金额', index: 'deductAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.deductibleAmount' },
    { title: '抵扣有效税额', index: 'deductTax', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.deductibleTax' },
    { title: '不抵扣份数', index: 'unDeductInvoiceNum', i18n: 'st.unDeductibleNum' },
    { title: '不抵扣金额', index: 'unDeductAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.unDeductibleAmount' },
    { title: '不抵扣有效税额', index: 'unDeductTax', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.unDeductibleTax' },
  ];
  // 列表分页参数
  normalQueryParams: any = {
    page: 1,
    limit: 10,
    companyId: '',
  };
  normalPageTotal = 0;
  abnormalRows: any = []; // 差异发票统计数据
  @ViewChild('abnormalSt', { static: false }) abnormalSt: STComponent;
  abnormalColumns: STColumn[] = [
    { title: '发票类型', index: 'invoiceType', render: 'invoiceType', i18n: 'st.invoiceEntity', width: 100 },
    { title: '发票代码', index: 'invoiceCode', i18n: 'st.invoiceCode', width: 100 },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'st.invoiceNumber', width: 100 },
    { title: '海关缴款书号码', index: 'paymentCertificateNo', i18n: 'st.paymentCertificateNo', width: 200 },
    { title: '税额', index: 'tax', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTaxPrice', width: 60 },
    { title: '合计金额', index: 'amount', i18n: 'st.totalAmount', width: 100 },
    { title: '有效税额', index: 'validTax', i18n: 'st.validTax', width: 100 },
    { title: '转内销编号', index: 'exportRejectNo', i18n: 'st.exportRejectNo', width: 120 },
    { title: '发票状态', index: 'invoiceStatus', render: 'invoiceStatus', i18n: 'st.invoiceStatus', width: 100 },
    { title: '管理状态', index: 'manageStatus', render: 'manageStatus', i18n: 'st.manageStatus', width: 100 },
    { title: '勾选类型', index: 'deductType', render: 'deductType', i18n: 'st.deductType', width: 100 },
  ];
  abnormalQueryParams: any = {
    page: 1,
    limit: 10,
    companyId: '',
    statisticsMonth: '', // 统计月份
  };
  abnormalPageTotal = 0;
  // 查询列表参数
  companyId = '';
  i: any;
  loading = false;
  taxNo = ''; // 税号

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    // 获取当前companyId
    if (this.i.deptId) {
      this.companyId = this.i.deptId;
      this.getStatisticsList();
      this.getAbnormalInvoiceList();
    } else {
      this.msgSrv.error('id不存在，请联系管理员!');
    }
    // 获取taxNo
    if (this.i) {
      this.taxNo = this.i.taxCode || '';
    }
  }

  // 获取统计信息列表
  getStatisticsList() {
    this.loading = true;
    this.normalQueryParams.companyId = this.companyId;
    this.http.get('/input/invoicesync/getTaskResultDetail', this.normalQueryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.normalRows = data.page.list;
        this.normalQueryParams.page = data.page.currPage;
        this.normalQueryParams.limit = data.page.pageSize;
        this.normalPageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 获取差异发票统计信息列表
  getAbnormalInvoiceList() {
    this.loading = true;
    this.abnormalQueryParams.companyId = this.companyId;
    this.http.get('/input/invoicesync/getTaskResultDifference', this.abnormalQueryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.abnormalRows = data.page.list;
        this.abnormalQueryParams.page = data.page.currPage;
        this.abnormalQueryParams.limit = data.page.pageSize;
        this.abnormalPageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 统计确认
  confirm() {
    this.loading = true;
    this.http.get('/input/invoicesync/confirm?taxNo=' + this.taxNo).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('统计申请成功');
        this.modal.close(true);
      } else {
        this.modal.close(false);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // tab切换事件
  salesChange(idx: number) {
    if (this.saleTabs[idx].show !== true) {
      this.saleTabs[idx].show = true;
      this.cdr.detectChanges();
    }
  }

  // 统计-分页
  normalPagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.normalQueryParams.page !== event.pi) {
        this.normalQueryParams.page = event.pi;
        this.getStatisticsList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.normalQueryParams.limit !== event.ps) {
        this.normalQueryParams.limit = event.ps;
        this.normalQueryParams.page = 1;
        this.getStatisticsList();
      }
    }
  }

  // 发票-分页
  abnormalPagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.abnormalQueryParams.page !== event.pi) {
        this.abnormalQueryParams.page = event.pi;
        this.getAbnormalInvoiceList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.abnormalQueryParams.limit !== event.ps) {
        this.abnormalQueryParams.limit = event.ps;
        this.abnormalQueryParams.page = 1;
        this.getAbnormalInvoiceList();
      }
    }
  }

  close() {
    this.modal.destroy();
  }
}
