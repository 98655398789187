import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';

@Component({
  selector: 'app-input-transfer-out-tax-free-edit',
  templateUrl: './edit.component.html',
})
export class InputTransferOutTaxFreeEditComponent implements OnInit {
  loading = false;
  data: any = {
    code: '',
    taxFreeName: '',
  }
  constructor(
    private http: _HttpClient,
    private modal: NzModalRef,
    public msg: NzMessageService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() { }

  // 新增标签
  add() {
    this.msg.success('保存成功');
    this.cdr.detectChanges();
    this.modal.close(true);
  }

  close() {
    this.modal.destroy();
  }
}
