import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STData } from '@delon/abc';
import { InputPurchaseOrderRefundComponent } from '../purchase-order/refund/refund.component';
import { InputTransferOutTransferComponent } from './transfer/transfer.component';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';
import { DialogService } from 'src/app/service/dialog.service';
import { clientConfig } from '@env/client-config';
import * as format from 'date-fns/format';

@Component({
  selector: 'app-input-transfer-out',
  templateUrl: './transfer-out.component.html',
  styleUrls: ['./transfer-out.component.less'],
})
export class InputTransferOutComponent implements OnInit {
  saleTabs: any[] = [{ key: 'purchaseOrder', id: 1, show: true }, { key: 'expenseAccount', id: 2 }];
  // 采购单数据
  purchaseShow = false;
  purchaseMore = 'down';
  purchaseSelectedRows: STData[] = []; // 采购单选中数据
  batchList: any = [{ 'id': '', 'invoiceBatchNumber': '' }]; // 采购单号列表
  purchaseRows: any = [];
  @ViewChild('purchaseSt', { static: false }) purchaseSt: STComponent;
  purchaseColumns: STColumn[] = [
    { title: '批次号', index: 'invoiceBatchNumber', i18n: 'st.batchNumber', width: 100 },
    { title: '购方企业名称', index: 'invoicePurchaserCompany', i18n: 'st.invoicePurchaserCompany', width: 200 },
    { title: '购方税号', index: 'invoicePurchaserParagraph', i18n: 'st.invoicePurchaserParagraph', width: 100 },
    { title: '销方企业名称', index: 'invoiceSellCompany', i18n: 'st.invoiceSellCompany', width: 200 },
    { title: '销方税号', index: 'invoiceSellParagraph', i18n: 'st.invoiceSellParagraph', width: 100 },
    { title: '上传人', index: 'createBy', i18n: 'st.createUserName', width: 100 },
    { title: '上传日期', index: 'createTime', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceUploadDate', width: 120 },
    { title: '批次状态', index: 'invoiceBatchStatus', render: 'invoiceBatchStatus', i18n: 'st.invoiceBatchStatus', width: 100 },
    { title: '详情', index: 'threeErrorDescription', render: 'threeErrorDescription', i18n: 'st.threeErrorDescription', width: 100 },
  ];
  // 嵌套发票列表
  @ViewChild('purchaseExpendst', { static: false }) purchaseExpendst: STComponent;
  purchaseExpendColumns: STColumn[] = [
    { title: '发票代码', index: 'invoiceCode', i18n: 'st.invoiceCode', width: 100 },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'st.invoiceNumber', width: 100 },
    { title: '票面金额(含税)', index: 'invoiceTotalPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTotalPrice', width: 150 },
    { title: '票面金额(不含税)', index: 'invoiceFreePrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceFreePrice', width: 170 },
    { title: '税额', index: 'invoiceTaxPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTaxPrice', width: 80 },
    { title: '校验码', index: 'invoiceCheckCode', i18n: 'st.invoiceCheckCode', width: 100 },
    { title: '开票日期', index: 'invoiceCreateDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceCreateDate', width: 120 },
    { title: '入账日期', index: 'entryDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.entryDate', width: 120 },
    { title: '发票类型', index: 'invoiceEntity', render: 'invoiceEntity', i18n: 'st.invoiceEntity', width: 100 },
    { title: '转出类型', index: 'objectName', width: 100 },
    { title: '转出状态', index: 'outFlag', render: 'outFlag', width: 100 },
    { title: '转出比列', index: 'outRatio', render: 'outRatio', width: 100 },
    { title: '上传方式', index: 'invoiceUploadType', render: 'invoiceUploadType', i18n: 'st.invoiceUploadType', width: 100 },
    { title: '发票状态', index: 'invoiceStatus', render: 'invoiceStatus', i18n: 'st.invoiceStatus', fixed: 'right', width: 100 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 60,
      buttons: [
        {
          text: '编辑',
          i18n: 'st.operate.edit',
          paramName: 'i',
          iif: (item) => item.outRatio,
          click: (item: any) => this.edit(item, 0),
        },
      ]
    }
  ];
  purchaseQueryParams: any = {
    page: 1,
    limit: 10,
    invoiceBatchNumber: '',
    createDate: '',
    createBy: '',
    invoicePurchaserCompany: '',
    invoiceSellCompany: '',
    invoiceCode: '',
    invoiceNumber: '',
    invoiceEntity: '',
    invoiceCreateDate: '',
    invoiceStatus: '',
    minAmount: '',
    maxAmount: '',
    menuType: '1', // 采购单管理参数
  };
  createDate: '';
  invoiceCreateDate: '';
  invoiceStatusOption = clientConfig.invoiceStatusOption; // 全部发票状态
  purchasePageTotal = 0;

  // 报销单数据
  expenseShow = false;
  expenseMore = 'down';
  expenseSelectedRows: STData[] = []; // 采购单选中数据
  expenseRows: any = [];
  @ViewChild('expenseSt', { static: false }) expenseSt: STComponent;
  expenseColumns: STColumn[] = [
    { title: '报销单号', index: 'expenseNumber', i18n: 'st.expenseNumber', width: 100 },
    { title: '申请者', index: 'applyUser', i18n: 'st.applyUser', width: 100 },
    { title: '报销者', index: 'endUser', i18n: 'st.endUser', width: 100 },
    { title: '申请时间', index: 'applyTime', i18n: 'st.applyTime', type: 'date', dateFormat: 'YYYY-MM-DD HH:mm:ss', width: 200 },
    { title: '报销金额', index: 'amount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.amount', width: 100 },
    { title: '已关联票据金额', index: 'invoicesAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoicesAmount', width: 150 },
    { title: '报销单状态', index: 'expenseStatus', i18n: 'st.expenseStatus', render: 'expenseStatus', width: 150 },
  ];
  // 嵌套发票列表
  @ViewChild('expenseExpendst', { static: false }) expenseExpendst: STComponent;
  expenseExpendColumns: STColumn[] = [
    { title: '', index: 'invoiceNumber', type: 'checkbox', className: 'st-checkbox' },
    { title: '发票代码', index: 'invoiceCode', i18n: 'st.invoiceCode', width: 100 },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'st.invoiceNumber', width: 100 },
    { title: '购方企业名称', index: 'invoicePurchaserCompany', i18n: 'st.invoicePurchaserCompany', width: 150 },
    { title: '购方税号', index: 'invoicePurchaserParagraph', i18n: 'st.invoicePurchaserParagraph', width: 100 },
    { title: '销方企业名称', index: 'invoiceSellCompany', i18n: 'st.invoiceSellCompany', width: 150 },
    { title: '销方税号', index: 'invoiceSellParagraph', i18n: 'st.invoiceSellParagraph', width: 100 },
    { title: '票面金额(含税)', index: 'invoiceTotalPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTotalPrice', width: 150 },
    { title: '票面金额(不含税)', index: 'invoiceFreePrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceFreePrice', width: 170 },
    { title: '税额', index: 'invoiceTaxPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTaxPrice', width: 80 },
    { title: '校验码', index: 'invoiceCheckCode', i18n: 'st.invoiceCheckCode', width: 100 },
    { title: '开票日期', index: 'invoiceCreateDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceCreateDate', width: 120 },
    { title: '入账日期', index: 'entryDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.entryDate', width: 120 },
    { title: '发票类型', index: 'invoiceEntity', render: 'invoiceEntity', i18n: 'st.invoiceEntity', width: 100 },
    { title: '转出类型', index: 'objectName', width: 100 },
    { title: '转出状态', index: 'outFlag', render: 'outFlag', width: 100 },
    { title: '转出比列', index: 'outRatio', render: 'outRatio', width: 100 },
    { title: '上传方式', index: 'invoiceUploadType', render: 'invoiceUploadType', i18n: 'st.invoiceUploadType', width: 100 },
    { title: '发票状态', index: 'invoiceStatus', render: 'invoiceStatus', i18n: 'st.invoiceStatus', fixed: 'right', width: 100 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 50,
      buttons: [
        {
          text: '编辑',
          i18n: 'st.operate.edit',
          paramName: 'i',
          click: (item: any) => this.edit(item.id, 1),
        },
      ]
    }
  ];
  // 列表查询参数
  expenseQueryParams: any = {
    page: 1,
    limit: 10,
    expenseNumber: '',
    expenseStatus: '',
    applyUser: '',
    endUser: '',
    applyTime: '',
    minAmount: '',
    maxAmount: '',
    menuType: '1', // 报销单管理列表参数
  };
  // 报销单状态
  expenseStatusOption = [
    { value: 0, label: '待报销' },
    { value: 1, label: '已报销' },
    { value: 2, label: '超额' },
    { value: 3, label: '异常' },
  ]
  loading = false;

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private modalSrv: NzModalService,
    private msgSrv: NzMessageService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getPurchaseList();
  }

  // 获取采购单列表
  getPurchaseList() {
    this.loading = true;
    this.http.get('/input/label/findLabelByInvoice', this.purchaseQueryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.purchaseRows = data.page.list;
        this.batchList = data.invoiceBatchEntityList;
        this.purchaseQueryParams.page = data.page.currPage;
        this.purchaseQueryParams.limit = data.page.pageSize;
        this.purchasePageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 获取报销单列表
  getExpenseList() {
  }

  // 转出
  transfer(id, ptype) {
    this.modal.static(InputTransferOutTransferComponent, { i: { invoiceIds: id, ptype: 0 } }, 500).subscribe((success) => {
      if (success) {
        // ptype: 0 采购单；1 报销单
        if (ptype === 0) {
          this.getPurchaseList();
        } else {
          this.getExpenseList()
        }
      }
    });
  }

  // 批量转出
  bulkTransfer(ptype) {
    const idList = new Array();
    if (ptype === 0) {
      this.purchaseSelectedRows.map(item => {
        idList.push(item.id);
      })
      this.transfer(idList, ptype);
      this.purchaseSelectedRows = [];
    } else {
      this.expenseSelectedRows.map(item => {
        idList.push(item.id);
      })
      this.transfer(idList, ptype);
      this.expenseSelectedRows = [];
    }
  }

  // 转出编辑
  edit(item, ptype) {
    this.modal.static(InputTransferOutTransferComponent, { i: { invoiceId: item.id, outRatio: item.outRatio, objectName: item.objectName, ptype: 1 } }, 500).subscribe((success) => {
      if (success) {
        if (ptype === 0) {
          this.getPurchaseList();
        } else {
          this.getExpenseList()
        }
      }
    });
  }

  // 批量转出编辑
  bulkEdit(ptype) {
    const idList = new Array();
    if (ptype === 0) {
      this.purchaseSelectedRows.map(item => {
        idList.push(item.id);
      })
      this.edit(idList, ptype);
      this.purchaseSelectedRows = [];
    } else {
      this.expenseSelectedRows.map(item => {
        idList.push(item.id);
      })
      this.edit(idList, ptype);
      this.expenseSelectedRows = [];
    }
  }

  // 退票
  refund(id, ptype) {
    this.modal.static(InputPurchaseOrderRefundComponent, { i: { invoiceId: id } }, 500).subscribe((success) => {
      if (success) {
        if (ptype === 0) {
          this.getPurchaseList();
        } else {
          this.getExpenseList()
        }
      }
    });
  }

  // 批量退票
  bulkRefund(ptype) {
    this.modalSrv.confirm({
      nzContent: '确认要进行<font color="red">批量退票</font>操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          const idList = new Array();
          if (ptype === 0) {
            this.purchaseSelectedRows.map(item => {
              idList.push(item.id);
            })
            const ids = idList.join(',');
            this.refund(ids, ptype);
            this.purchaseSelectedRows = [];
          } else {
            this.expenseSelectedRows.map(item => {
              idList.push(item.id);
            })
            const ids = idList.join(',');
            this.refund(ids, ptype);
            this.expenseSelectedRows = [];
          }
          resolve(true);
        }).catch(() => console.log('退票失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          if (ptype === 0) {
            this.purchaseSelectedRows = [];
            this.purchaseSt.reload();
          } else {
            this.expenseSelectedRows = [];
            this.expenseSt.reload();
          }
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 删除
  delete(id, ptype) {
    this.loading = true;
    const idList = Array.isArray(id) ? id : [id];
    this.http.post('/input/invoice/delete', (idList)).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('删除成功');
        if (ptype === 0) {
          this.getPurchaseList();
        } else {
          this.getExpenseList();
        }
      } else {
        if (ptype === 0) {
          this.purchaseSt.reload();
        } else {
          this.expenseSt.reload();
        }
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 批量删除
  bulkDelete(ptype) {
    this.modalSrv.confirm({
      nzContent: '确认要进行<font color="red">删除</font>操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          const idList = new Array();
          if (ptype === 0) {
            this.purchaseSelectedRows.map(item => {
              idList.push(item.id);
            })
            this.delete(idList, ptype);
            this.purchaseSelectedRows = [];
          } else {
            this.expenseSelectedRows.map(item => {
              idList.push(item.id);
            })
            this.delete(idList, ptype);
            this.expenseSelectedRows = [];
          }
          resolve(true);
        }).catch(() => console.log('删除失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          if (ptype === 0) {
            this.purchaseSelectedRows = [];
            this.purchaseSt.reload();
          } else {
            this.expenseSelectedRows = [];
            this.expenseSt.reload();
          }
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // tab切换
  salesChange(idx: number) {
    if (this.saleTabs[idx].show !== true) {
      this.saleTabs[idx].show = true;
      this.cdr.detectChanges();
    }
  }

  // 采购单-搜索
  purchaseSearch() {
    this.purchaseQueryParams.page = 1;
    this.getPurchaseList();
  }

  // 报销单-搜索
  expenseSearch() {

  }

  // 采购单-多选框禁用
  purchaseDisabledCheckbox(data: STData[]) {
    return data.map((i: STData, index: number) => {
      i.disabled = index === 0;
      return i;
    });
  }

  // 报销单-多选框禁用
  expenseDisabledCheckbox(data: STData[]) {
    return data.map((i: STData, index: number) => {
      i.disabled = index === 0;
      return i;
    });
  }

  // 采购单-重置
  purchaseCancel() {
    this.purchaseQueryParams = {
      page: 1,
      limit: 10,
      invoiceBatchNumber: '',
      createDate: '',
      createBy: '',
      invoicePurchaserCompany: '',
      invoiceSellCompany: '',
      invoiceCode: '',
      invoiceNumber: '',
      invoiceEntity: '',
      invoiceCreateDate: '',
      invoiceStatus: '',
      minAmount: '',
      maxAmount: '',
      menuType: '1', // 采购单管理参数
    };
  }

  // 报销单-重置
  expenseCancel() {
    this.expenseQueryParams = {
      page: 1,
      limit: 10,
      expenseNumber: '',
      expenseStatus: '',
      applyUser: '',
      endUser: '',
      applyTime: '',
      minAmount: '',
      maxAmount: '',
      menuType: '1', // 报销单管理列表参数
    };
  }

  // 开票日期事件
  invoiceCreateDateChange(event) {
    this.purchaseQueryParams.invoiceCreateDate = event.length > 0 ? format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD') : '';
  }

  // 上传日期事件
  invoiceUploadDateChange(event) {
    this.purchaseQueryParams.createDate = event.length > 0 ? format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD') : '';
  }


  // 采购单-展开
  purchaseShowOrHidden() {
    this.purchaseShow = !this.purchaseShow;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.purchaseShow) {
      this.purchaseMore = 'up';
    } else {
      this.purchaseMore = 'down';
    }
  }

  // 报销单-展开
  expenseShowOrHidden() {
    this.expenseShow = !this.expenseShow;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.expenseShow) {
      this.expenseMore = 'up';
    } else {
      this.expenseMore = 'down';
    }
  }

  // 采购单-分页
  purchasePagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.purchaseQueryParams.page !== event.pi) {
        this.purchaseQueryParams.page = event.pi;
        this.getPurchaseList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.purchaseQueryParams.limit !== event.ps) {
        this.purchaseQueryParams.limit = event.ps;
        this.purchaseQueryParams.page = 1;
        this.getPurchaseList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.purchaseSelectedRows = event.checkbox!;
    }
  }

  // 采购单-分页
  expensePagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.expenseQueryParams.page !== event.pi) {
        this.expenseQueryParams.page = event.pi;
        this.getExpenseList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.expenseQueryParams.limit !== event.ps) {
        this.expenseQueryParams.limit = event.ps;
        this.expenseQueryParams.page = 1;
        this.getExpenseList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.expenseSelectedRows = event.checkbox!;
    }
  }

}
