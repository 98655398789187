import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService, UploadFile } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { clientConfig } from './../../../../../environments/client-config';
import { DialogService } from 'src/app/service/dialog.service';
import * as format from 'date-fns/format';

@Component({
  selector: 'app-input-purchase-order-addtional-invoice',
  templateUrl: './addtional-invoice.component.html',
})
export class InputPurchaseOrderAddtionalInvoiceComponent implements OnInit {
  i: any;
  loading = false;
  invoiceEntityOption = clientConfig.invoiceEntityOption;
  data: any = {
    "invoiceEntity": '专用发票',
    "invoiceCode": "",
    "invoiceNumber": "",
    "invoiceCreateDate": "",
    "invoiceFreePrice": "",
    "invoiceCheckCode": "",
    "invoiceImage": "",
  }
  uploadAction = '/input/fileServer/upload';

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    // 获取发票信息
    this.getInfo();
  }

  // 获取发票信息
  getInfo() {
    this.loading = true;
    this.http.get('/input/invoice/getById?id=' + this.i.id).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        // 发票信息
        if (res.invoiceEntity) {
          this.data = res.invoiceEntity;
          // 校验码后六位
          if (res.invoiceEntity.invoiceCheckCode) {
            this.data.invoiceCheckCode = res.invoiceEntity.invoiceCheckCode.slice(-6);
          }
        }
      }
    }, error => {
      console.log('HTTP Error', error);
    });
  }

  // 补充发票信息
  addtionalInvoice() {
    this.loading = true;
    this.http.post('/input/invoice/update', this.data).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('操作成功');
        this.modal.close(true);
      } else {
        this.modal.close(false);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 图片上传状态处理事件
  handleChange(res): void {
    if (res.type === 'success') {
      this.data.invoiceImage = res.file.response.msg; // 上传成功后获取返回图片地址
    }
  }

  // 开票时间处理
  invoiceCreateDateChange(event) {
    this.data.invoiceCreateDate = event ? format(event, 'YYYY-MM-DD HH:mm:ss') : '';
  }

  close() {
    this.modal.destroy();
  }
}
