import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService, NzModalService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { STComponent, STColumn } from '@delon/abc';
import * as format from 'date-fns/format';
import { DialogService } from 'src/app/service/dialog.service';
import { clientConfig } from '@env/client-config';

@Component({
  selector: 'app-output-invoice-in-advance-write-off',
  templateUrl: './write-off.component.html',
  styleUrls: ['./write-off.component.less'],
})
export class OutputInvoiceInAdvanceWriteOffComponent implements OnInit {
  record: any = {};
  i: any;
  invoiceId: any = ''; // 发票id
  checkedRow: any = {}; // 单选数据
  loading = false;
  show = false;
  more = 'down';
  rows: any[] = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '', index: 'id', type: 'radio', width: 10 },
    { title: '订单编号', index: 'orderNumber', i18n: 'output.st.orderNumber', width: 100 },
    { title: '订单日期', index: 'orderDate', type: 'date', dateFormat: 'YYYYMMDD', i18n: 'output.st.orderDate', width: 120 },
    { title: '购方名称', index: 'purchaseName', i18n: 'output.st.purchaseName', width: 150 },
    { title: '销方名称', index: 'sellerName', i18n: 'output.st.sellerName', width: 150 },
    { title: '总金额', index: 'excludingTaxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.amount', width: 100 },
    { title: '待核销金额', index: 'invoiceAmountAvailable', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.amountAvailable', width: 120 }, // todo-待确认
    { title: '税额', index: 'taxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.taxAmount', width: 100 },
    { title: '价税合计', index: 'totalAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.invoiceTotalAmount', width: 100 },
    { title: '票据类型', index: 'invoiceType', i18n: 'output.st.orderInvoiceType', width: 100 },
    { title: '发票载体', index: 'invoiceEntity', i18n: 'output.st.invoiceEntity', width: 100 },
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    orderNumber: '',
    orderDate: '',
    invoiceType: '',
    invoiceEntity: '',
    minAvailableAmount: '',
    maxAvailableAmount: '',
    minAmount: '',
    maxAmount: '',
  };
  orderDate: ''; // 订单日期
  pageTotal = 0
  invoiceTypeOption = clientConfig.invoiceTypeOption;
  invoiceCarrierOption = clientConfig.invoiceCarrierOption;

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    private modalService: NzModalService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.invoiceId = this.i.invoiceId || '';
    this.getList();
  }

  // 获取列表
  getList() {
    this.loading = true;
    this.http.get('/output/order/list', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 核销操作
  save() {
    if (Object.keys(this.checkedRow).length === 0) {
      this.modalService.warning({
        nzTitle: '请选择要核销的订单！',
        nzWrapClassName: 'vertical-center-modal',
      });
      return false;
    }
    const orderId = this.checkedRow.orderId!;
    this.loading = true;
    this.http.post('/output/invoice/writeOff?invoiceId=' + this.invoiceId + '&orderId=' + orderId).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.modal.close(true);
        this.msgSrv.success('操作成功');
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 订单日期事件
  orderDateChange(event) {
    this.queryParams.orderDate = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      orderNumber: '',
      orderDate: '',
      invoiceType: '',
      invoiceEntity: '',
      minAvailableAmount: '',
      maxAvailableAmount: '',
      minAmount: '',
      maxAmount: '',
    };
    this.orderDate = '';
    this.getList();
  }

  // 展开
  showOrHidden() {
    this.show = !this.show;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.show) {
      this.more = 'up';
    } else {
      this.more = 'down';
    }
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
    // 单选事件
    if (event.type === 'radio') {
      this.checkedRow = event.radio!;
    }
  }

  close() {
    this.modal.destroy();
  }
}
