import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { DialogService } from 'src/app/service/dialog.service';


@Component({
  selector: 'app-input-transfer-out-transfer',
  templateUrl: './transfer.component.html',
})
export class InputTransferOutTransferComponent implements OnInit {
  record: any = {};
  i: any;
  ptype = 0; // 页面类型，默认0新增;1编辑
  loading = false;
  data: any = {
    invoiceId: '',
    objectName: '',
    outRatio: '',
  }
  formatterPercent = (value: number) => `${value} %`;
  parserPercent = (value: string) => value.replace(' %', '');

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    // 获取页面类型和id
    if (this.i) {
      this.ptype = this.i.ptype;
      this.data.invoiceId = this.i.invoiceId;
      this.data.outRatio = this.i.outRatio * 100;
      this.data.objectName = this.i.objectName;
    }
  }

  // 保存信息
  save() {
    this.data.outRatio = this.data.outRatio / 100;
    this.loading = true;
    this.http.post('/input/label/updateManual', this.data).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('操作成功');
        this.modal.close(true);
      } else {
        this.modal.close(false);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  close() {
    this.modal.destroy();
  }
}
