// Custom icon static resources

import {
  InfoOutline,
  BulbOutline,
  ProfileOutline,
  ScheduleOutline,
  MenuOutline,
  ExceptionOutline,
  LinkOutline,
} from '@ant-design/icons-angular/icons';

export const ICONS = [InfoOutline, BulbOutline, ProfileOutline, ScheduleOutline, MenuOutline, ExceptionOutline, LinkOutline];
