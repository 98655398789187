import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { STComponent, STColumn, STData } from '@delon/abc';
import { clientConfig } from '@env/client-config';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-nis-billing-view-apply',
  templateUrl: './view-apply.component.html',
  styleUrls: ['./view-apply.component.less'],
})
export class OutputNisBillingViewApplyComponent implements OnInit {
  record: any = {};
  i: any;
  loading = false;
  saleTabs: any[] = [{ key: 'info', id: 1, show: true }, { key: 'order', id: 2 }];
  taxpayerTypeOption = [
    { value: '一般纳税人', label: '一般纳税人' },
    { value: '个体工商户', label: '个体工商户' },
    { value: '企业纳税人', label: '企业纳税人' },
  ] // 纳税人类型
  invoiceTypeOption = clientConfig.invoiceTypeOption;
  invoiceCarrierOption = clientConfig.invoiceCarrierOption;
  // 基本信息
  data: any = {
    orderId: "", // 申请开票ids
    taxpayerType: "",
    invoiceType: "",
    invoiceEntity: "",
    preformedInvoice: 0, // 是否预制发票
    invoiceRemark: "",
    orderAmount: "",  // 订单总额不含税
    invoiceAmountLimit: "", // 票面金额上限
    splitInvoiceNumber: "", // 拆分张数
    applyType: '0', // 申请类型：0-订单开票
  }
  // 订单明细
  selectedRows: any = []; // 订单列表
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '订单编号', index: 'orderNumber', i18n: 'output.st.orderNumber', width: 100 },
    { title: '订单日期', index: 'createTime', type: 'date', dateFormat: 'YYYYMMDD', i18n: 'output.st.orderDate', width: 120 },
    { title: '购方名称', index: 'purchaseName', i18n: 'output.st.purchaseName', width: 150 },
    { title: '销方名称', index: 'sellerName', i18n: 'output.st.sellerName', width: 150 },
    { title: '总金额（不含税）', index: 'excludingTaxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.excludingTaxAmount', width: 200 },
    { title: '税额', index: 'taxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.taxAmount', width: 100 },
    // { title: '折扣方式', index: 'discountType', i18n: 'output.st.discountType', width: 100 },
    // { title: '折扣总额', index: 'excludingTaxDiscountAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.excludingTaxDiscountAmount', width: 100 },
    { title: '合计（含税）', index: 'totalAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.totalAmount', width: 120 },
    { title: '票据类型', index: 'invoiceEntity', i18n: 'output.st.orderInvoiceType', width: 100 },
    { title: '订单状态', index: 'status', render: 'status', i18n: 'output.st.orderStatus', fixed: 'right', width: 100 },
  ];
  totalAmount = 0; // 订单价税合计
  customerName = ''; // 购方名称

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.data.orderId = this.i.orderIds || '';
    this.customerName = this.i.customerName || '';
    this.data.orderAmount = this.i.orderAmount || '';
    this.getPurchaseInfo();
    this.getOrderList();
  }

  // 获取购方信息
  getPurchaseInfo() {
    this.loading = true;
    this.http.get(`/output/invoicerules/getDetail/${this.customerName}`).subscribe(async data => {
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        if (data.data.customer) {
          this.data.taxpayerType = data.data.customer.taxpayerType;
        }
        if (data.data.rules) {
          this.data.invoiceType = data.data.rules.invoiceType;
          this.data.invoiceEntity = data.data.rules.invoiceEntity;
          this.data.preformedInvoice = data.data.rules.preformedInvoice;
          this.data.invoiceRemark = data.data.rules.remark;
          this.data.invoiceAmountLimit = data.data.rules.amount;
          // 计算订单拆分张数
          this.data.splitInvoiceNumber = await this.getSplitNumber(this.data.invoiceAmountLimit);
        }
        this.loading = false;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 获取订单拆分张数接口
  getSplitNumber(amount) {
    return new Promise((resolve, reject) => {
      this.http.get('/output/invoiceapply/splitNumber?orderIds=' + this.data.orderId + '&amount=' + amount).subscribe(res => {
        this.dialogService.getErrorCode(res);
        if (res.msg === 'success') {
          this.data.splitInvoiceNumber = res.number;
          resolve(res.number);
        }
      }, error => {
        resolve(error.message);
      });
    });
  }

  // 获取订单明细列表
  getOrderList() {
    this.loading = true;
    this.http.get(`/output/order/info/${this.data.orderId}`).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        if (data.order.length > 0) {
          this.selectedRows = data.order;
          if (data.order.length > 0) {
            this.totalAmount = data.order[0].totalAmount;
          }
        }
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 默认展开事件
  dataChange(data: STData[]) {
    return data.map((i: STData, index: number) => {
      i.expand = index === 0;
      return i;
    });
  }

  // tab切换事件
  salesChange(idx: number) {
    if (this.saleTabs[idx].show !== true) {
      this.saleTabs[idx].show = true;
      this.cdr.detectChanges();
    }
  }

  close() {
    this.modal.destroy();
  }
}
