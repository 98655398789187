import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NzModalRef, NzMessageService, NzModalService, NzTreeSelectComponent } from 'ng-zorro-antd';
import { _HttpClient, SettingsService } from '@delon/theme';
import { STComponent, STColumn } from '@delon/abc';
import { clientConfig } from '@env/client-config';
import * as format from 'date-fns/format';
import { DialogService } from 'src/app/service/dialog.service';
import { UserService } from 'src/app/service/user.service';
import { CacheService } from '@delon/cache';
import * as _ from 'lodash';

interface InvoiceApplyDetail {
  id: number
  name: string
  specifications: string
  unit: string
  quantity: number
  price: number
  amount: number
  taxRate: string
  taxAmount: number
  isDiscount: boolean
  groupId: number
};


@Component({
  selector: 'app-output-jde-billing-apply-for-billing',
  templateUrl: './apply-for-billing.component.html',
  styleUrls: ['./apply-for-billing.component.less'],
})
export class OutputJdeBillingApplyForBillingComponent implements OnInit {
  record: any = {};
  i: any;
  saleTabs: any[] = [{ key: 'info', id: 1, show: true }, { key: 'invoiceView', id: 2 }];
  businessTypeOption = [
    { value: '利息', label: "利息" },
    { value: '固定资产-一般计税', label: "固定资产-一般计税" },
    { value: '固定资产-简易征收', label: "固定资产-简易征收" },
  ];
  invoiceTypeOption = clientConfig.invoiceTypeOption;
  invoiceCarrierOption = clientConfig.invoiceCarrierOption;
  rateOption = [
    { value: '0.07', label: '7%' },
    { value: '0.11', label: '11%' },
    { value: '0.13', label: '13%' },
  ];
  // 申请开票信息
  applyData: any = {
    customerName: '', // 购方客户名称
    applyInfo: {}, // 申请信息-data
    details: [], // 发票信息
  };
  //是否是数电发票，默认为false
  digital = false;
  // 基本信息
  data: any = {
    applyUser: this.settingsService.user.username, // 申请人默认当前用户
    applyTime: format(new Date(), 'YYYY-MM-DD HH:mm:ss'),
    excludingTaxAmount: "",
    businessType: "利息",
    accountingVouchers: "",
    invoiceType: "普通发票",
    invoiceEntity: "纸质发票",
    invoiceRemark: "",
    purchaseName: "",
    sellerName: "",
    taxRate: "",
    taxAmount: "",
    totalAmount: 0,
    invoicingTime: "",
    applyType: '1', // 申请类型：1-无订单开票
  }
  // 开票信息列表
  details: InvoiceApplyDetail[] = [];
  invoiceApplyDetail: InvoiceApplyDetail = {
    id: 0,
    name: '',
    specifications: '',
    unit: '',
    quantity: 0,
    price: 0,
    amount: 0,
    taxRate: '',
    taxAmount: 0,
    isDiscount: false,
    groupId: 0,
  };
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '货物或应税劳务名称', index: 'name', render: 'name', i18n: 'output.invoicing.itemName' },
    { title: '规格型号', index: 'specifications', render: 'specifications', i18n: 'output.invoicing.specification' },
    { title: '单位', index: 'unit', render: 'unit', i18n: 'output.invoicing.unit' },
    { title: '数量', index: 'quantity', render: 'quantity', i18n: 'output.invoicing.quantity' },
    { title: '单价', index: 'price', render: 'price', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.invoicing.price' },
    { title: '金额', index: 'amount', render: 'amount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.invoicing.totalAmount' },
    { title: '税率', index: 'taxRate', render: 'taxRate', i18n: 'output.invoicing.taxRate' },
    { title: '税额', index: 'taxAmount', render: 'taxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.invoicing.taxAmount' },
    {
      title: '操作',
      i18n: 'st.operate',
      buttons: [
        {
          text: '删除',
          i18n: 'st.operate.delete',
          click: record => this.delete(record.id),
        },
        {
          text: '折扣',
          i18n: 'st.operate.discount',
          iif: (item) => item.isDiscount === false,
          click: record => this.discount(record.id, record.groupId),
        },
      ]
    }
  ];
  totalAmount = 0; // 价税合计
  orderAmount = 0; // 商品金额合计
  taxAmount = 0; // 税额合计
  loading = false;
  validateFlag = false; // 商品金额与不含税票面金额判断标识
  ptype = 0; // 0-申请开票；1-重新申请
  invoiceId = '';
  // 重新申请开票信息
  reapplyData: any = {
    invoice: {}, // 发票信息
    invoiceGoods: [], // 开票商品列表
  };
  // 购销方信息
  @ViewChild('selectTree', null) selectTree: NzTreeSelectComponent;
  sellerOptions: any = []; // 销方公司选择列表
  sellerDeptId = ''; // 选中销方企业id
  purchaseOptions: any = []; // 购方企业选择列表


  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    private modalService: NzModalService,
    public http: _HttpClient,
    private cdr: ChangeDetectorRef,
    public settingsService: SettingsService,
    private dialogService: DialogService,
    private userService: UserService,
    public cache: CacheService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.ptype = this.i.ptype || 0;
    // 判断当前销方企业名称
    if (this.settingsService.user.username === 'admin') {
      // 如果为admin账户，销方为子公司列表，选择一个子公司进行开票
      await this.getDeptList();
      this.data.sellerName = this.sellerOptions[0].title;
      this.sellerDeptId = this.sellerOptions[0].key;
    } else {
      // 如果是其他用户，则销方为当前登录用户所属企业
      this.data.sellerName = this.settingsService.user.deptName;
      this.sellerDeptId = this.settingsService.user.deptId;
    }
    // 获取销方对应购方企业列表,默认第一个为购方企业
    this.getClientListByDeptId(this.sellerDeptId);
    if (this.ptype === 1) {
      this.invoiceId = this.i.invoiceId || '';
      this.getInvoiceInfo();
    }
  }

  // 获取发票信息
  getInvoiceInfo() {
    this.totalAmount = 0;
    this.orderAmount = 0;
    this.taxAmount = 0;
    this.loading = true;
    this.http.get(`/output/invoice/info/${this.invoiceId}`).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.data = data.invoice;
        this.data.invoiceRemark = data.invoice.remark || '';
        if (data.invoice.invoiceGoodsList) {
          this.details = data.invoice.invoiceGoodsList;
          // 替换列名称
          this.details = JSON.parse(JSON.stringify(this.details).replace(/goodsName/g, "name"));
          this.details = JSON.parse(JSON.stringify(this.details).replace(/specification/g, "specifications"));
        }
        // 发票税率，税额，价税合计赋值
        if (this.details.length > 0) {
          this.details.map(item => {
            this.totalAmount += (item.amount + item.taxAmount);
            this.orderAmount += item.amount;
            this.taxAmount += item.taxAmount;
          })
          // this.data.taxRate = this.details[0].taxRate; // todo - 默认商品税率一致，按第一个商品税率
          this.data.taxAmount = this.taxAmount;
          this.data.totalAmount = this.totalAmount;
        }
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 获取当前企业子公司列表
  async getDeptList() {
    this.loading = true;
    this.sellerOptions = this.cache.getNone('deptTree');
    if (this.sellerOptions === null) {
      this.sellerOptions = await this.userService.getDeptTreeList();
    }
    this.treeList(this.sellerOptions);
    this.loading = false;
  }

  // 树
  treeList(data) {
    if (data.length > 0) {
      for (const dept of data) {
        if (dept.children.length === 0) {
          dept.isLeaf = true;
        }
        if (dept.children.length > 0) {
          this.treeList(dept.children);
        }
      }
    }
  }

  // 根据企业id获取对应客户列表
  getClientListByDeptId(deptId) {
    this.loading = true;
    this.http.get('/output/customer/list?deptId=' + deptId).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        if (data.list.length > 0) {
          this.purchaseOptions = data.list;
          // 默认显示第一个客户为购方企业
          this.data.purchaseName = this.purchaseOptions[0].name;
          this.data.purchaseTaxCode = this.purchaseOptions[0].taxCode;
          this.data.purchaseAddress = this.purchaseOptions[0].address;
          this.data.purchaseContact = this.purchaseOptions[0].contact;
          this.data.purchaseBank = this.purchaseOptions[0].bank;
          this.data.purchaseBankAccount = this.purchaseOptions[0].bankAccount;
        } else {
          this.purchaseOptions = [];
          this.data.purchaseName = '';
        }
        this.cdr.detectChanges();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 新增开票信息
  addBilling() {
    this.details = [
      ...this.details,
      {
        id: this.details.length,
        name: '',
        specifications: '',
        unit: '',
        quantity: 0,
        price: 0,
        amount: 0,
        taxRate: '',
        taxAmount: 0,
        isDiscount: false,
        groupId: this.details.length,
      }
    ];
  }

  // 获取开票金额和税额，价税合计
  getAmount(event, index) {
    const updateRecord: InvoiceApplyDetail = this.details[index];
    if (!updateRecord.isDiscount) {
      const groupId = updateRecord.groupId;
      for (let i = this.details.length - 1; i >= 0; i--) {
        if (groupId == this.details[i].groupId) {
          this.details[i].quantity = updateRecord.quantity;
          this.details[i].taxRate = updateRecord.taxRate;
        }
      }
    }
    this.totalAmount = 0;
    this.orderAmount = 0;
    this.taxAmount = 0;
    if (this.details[index].quantity && this.details[index].price) {
      this.details[index].amount = this.details[index].quantity * this.details[index].price;
    }
    if (this.details[index].amount && this.details[index].taxRate) {
      this.details[index].taxAmount = this.details[index].amount * parseFloat(this.details[index].taxRate);
    }
    if (this.details.length > 0) {
      this.details.map(item => {
        this.totalAmount += (item.amount + item.taxAmount);
        this.orderAmount += item.amount;
        this.taxAmount += item.taxAmount;
      })
    }
    // 发票税率，税额，价税合计赋值
    // this.data.taxRate = this.details[0].taxRate; // todo - 默认商品税率一致，按第一个商品税率
    this.data.taxAmount = this.taxAmount;
    this.data.totalAmount = this.totalAmount;
    // 判断商品金额总和是否大于票面金额不含税
    this.validateFlag = this.orderAmount > this.data.excludingTaxAmount ? true : false;
    this.details = [...this.details];
    this.cdr.detectChanges();
  }

  nameChange(event, index) {
    const updateRecord: InvoiceApplyDetail = this.details[index];
    if (!updateRecord.isDiscount) {
      const groupId = updateRecord.groupId;
      for (let i = this.details.length - 1; i >= 0; i--) {
        if (groupId == this.details[i].groupId) {
          this.details[i].name = updateRecord.name;
        }
      }
    }
  }

  specificationsChange(event, index) {
    const updateRecord: InvoiceApplyDetail = this.details[index];
    if (!updateRecord.isDiscount) {
      const groupId = updateRecord.groupId;
      for (let i = this.details.length - 1; i >= 0; i--) {
        if (groupId == this.details[i].groupId) {
          this.details[i].specifications = updateRecord.specifications;
        }
      }
    }
  }

  unitChange(event, index) {
    const updateRecord: InvoiceApplyDetail = this.details[index];
    if (!updateRecord.isDiscount) {
      const groupId = updateRecord.groupId;
      for (let i = this.details.length - 1; i >= 0; i--) {
        if (groupId == this.details[i].groupId) {
          this.details[i].unit = updateRecord.unit;
        }
      }
    }
  }

  // 删除客户
  delete(id) {
    const index = this.details.findIndex(item => item.id === id);
    const deletRecord: InvoiceApplyDetail = this.details[index];
    // 选中折扣操作行
    if (!deletRecord.isDiscount) {
      const groupId = deletRecord.groupId;
      for (let i = this.details.length - 1; i >= 0; i--) {
        if (groupId == this.details[i].groupId) {
          this.details.splice(i, 1);
        }
      }
    } else {
      this.details.splice(index, 1);
    }
    this.totalAmount = 0;
    this.orderAmount = 0;
    this.taxAmount = 0;
    if (this.details.length > 0) {
      this.details.map(item => {
        this.totalAmount += (item.amount + item.taxAmount);
        this.orderAmount += item.amount;
        this.taxAmount += item.taxAmount;
      })
    }
    // 发票税率，税额，价税合计赋值
    // this.data.taxRate = this.details[0].taxRate;
    this.data.taxAmount = this.taxAmount;
    this.data.totalAmount = this.totalAmount;
    // 判断商品金额总和是否大于票面金额不含税
    this.validateFlag = this.orderAmount > this.data.excludingTaxAmount ? true : false;
    this.st.reload();
    this.cdr.detectChanges();
    this.msgSrv.success('删除成功');
  }

  // 打折扣
  discount(id, groupId) {
    const groupObjects = this.details.filter(item => item.groupId === groupId);
    if (groupObjects.length > 1) {
      this.msgSrv.info('该条已有折扣');
      return;
    }
    const index = this.details.findIndex(item => item.id === id);
    const discountRecord = _.cloneDeep(this.details[index]);
    discountRecord.id = this.details.length;
    discountRecord.isDiscount = true;
    discountRecord.groupId = id;
    this.details.splice(index + 1, 0, discountRecord);
    this.st.reload();
  }

  // 提交
  saveOrUpdate() {
    this.ptype === 1 ? this.reApply() : this.save();
  }

  // 无订单开票-申请
  save() {
    if (this.orderAmount !== this.data.excludingTaxAmount) {
      this.modalService.warning({
        nzTitle: '商品金额总和与不含税票面金额不等！',
      });
      return;
    }
    this.applyData.customerName = this.data.purchaseName;
    this.applyData.applyInfo = this.data;
    this.applyData.details = this.details;
    console.log('this.applyData::', this.applyData);
    this.loading = true;
    this.http.put('/output/invoiceapply/noOrderApply', this.applyData).subscribe(res => {
      this.loading = false;
      const flag = this.dialogService.getErrorCode(res);
      if (flag) {
        this.msgSrv.success('操作成功！');
        this.modal.close(true);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 无订单开票-重新申请
  reApply() {
    this.reapplyData.invoice = this.data;
    this.reapplyData.invoice.remark = this.data.invoiceRemark || '';
    let invoiceGoods = [...this.details];
    // 替换列名称
    invoiceGoods = JSON.parse(JSON.stringify(invoiceGoods).replace(/name/g, "goodsName"));
    invoiceGoods = JSON.parse(JSON.stringify(invoiceGoods).replace(/specifications/g, "specification"));
    this.reapplyData.invoiceGoods = invoiceGoods;
    this.loading = true;
    this.http.post('/output/invoice/update', this.reapplyData).subscribe(res => {
      this.loading = false;
      const flag = this.dialogService.getErrorCode(res);
      if (flag) {
        this.msgSrv.success('操作成功！');
        this.modal.close(true);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  getInfo(deptId) {
    this.http.get(`/sys/dept/info/${deptId}`).subscribe(res => {
      this.dialogService.getErrorCode(res);

      if (res.code === 0) {
        this.data.sellerName = res.data.name;
        this.data.sellerTaxCode = res.data.taxCode;
        this.data.sellerAddress = res.data.address;
        this.data.sellerContact = res.data.contact;
        this.data.sellerBank = res.data.bank;
        this.data.sellerBankAccount = res.data.bankAccount;
      }
    }, error => {
      console.log('HTTP Error', error);
    });
  }

  // tab切换事件
  salesChange(idx: number) {
    if (this.saleTabs[idx].show !== true) {
      this.saleTabs[idx].show = true;
      this.cdr.detectChanges();
    }
  }

  // 申请时间处理
  invoiceApplyTimeChange(event) {
    this.data.applyTime = event ? format(event, 'YYYY-MM-DD HH:mm:ss') : '';
  }

  // 销方企业选择事件
  sellerChange(event) {
    if (event) {
      this.getInfo(event);
      this.sellerDeptId = event;
      // 获取销方对应购方列表
      this.getClientListByDeptId(this.sellerDeptId);
    } else {
      console.log('销方企业Id不存在！');
    }
  }
  purchaseChange(event) {
    const choosed = this.purchaseOptions.find((item => item.name === event));
    this.data.purchaseName = choosed.name;
    this.data.purchaseTaxCode = choosed.taxCode;
    this.data.purchaseAddress = choosed.address;
    this.data.purchaseContact = choosed.contact;
    this.data.purchaseBank = choosed.bank;
    this.data.purchaseBankAccount = choosed.bankAccount;
  }

  invoiceEntityChange(event) {
    if (event.includes("数电发票")) {
      this.digital = true;
    } else {
      this.digital = false;
    }
  }

  close() {
    this.modal.destroy();
  }
}
