import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { Router } from '@angular/router';
import { OutputConfigurationInvoiceInventoryEditComponent } from './edit/edit.component';
import { OutputConfigurationInvoiceInventorySetAlarmRuleComponent } from './set-alarm-rule/set-alarm-rule.component';
import { clientConfig } from '@env/client-config';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-configuration-invoice-inventory',
  templateUrl: './invoice-inventory.component.html',
})
export class OutputConfigurationInvoiceInventoryComponent implements OnInit {
  loading = false;
  rows: any[] = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '发票类型', index: 'invoiceType', i18n: 'output.st.invoiceType' },
    { title: '当前库存', index: 'inventory', i18n: 'output.inventory.inventory' },
    { title: '报警条件', index: 'alarmConditions', i18n: 'output.inventory.alarmConditions', render: 'alarmConditions' },
    // { title: '通知人', index: 'noticeusers',i18n: 'output.inventory.noticeusers', render: 'noticeusers' },
    { title: '当前状态', index: 'status', i18n: 'output.inventory.status', render: 'status' },
    {
      title: '操作',
      i18n: 'st.operate',
      buttons: [
        {
          text: '编辑库存',
          i18n: 'st.operate.editInventory',
          param: 'i',
          click: record => this.edit(record),
        },
        {
          text: '设置报警规则',
          i18n: 'st.operate.setAlarmRule',
          param: 'i',
          click: record => this.setAlarmRule(record),
        },
      ]
    }
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    invoiceType: '',
    status: '',
  };
  pageTotal = 0;
  // 发票类型
  invoiceTypeOption = clientConfig.invoiceTypeOption;
  // 当前状态
  statusOption = clientConfig.inventoryStatusOption;


  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private router: Router,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getList();
  }

  // 获取列表
  getList() {
    this.loading = true;
    this.http.get('/output/invoice/inventory/list', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.queryParams.invoiceType = this.queryParams.invoiceType || '';
    this.queryParams.status = this.queryParams.status || '';
    this.getList();
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      invoiceType: '',
      status: '',
    };
    this.getList();
  }

  // 编辑库存
  edit(item) {
    this.modal.static(OutputConfigurationInvoiceInventoryEditComponent, { i: item }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 设置规则
  setAlarmRule(item) {
    this.modal.static(OutputConfigurationInvoiceInventorySetAlarmRuleComponent, { i: item }, 1100).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
  }
}
