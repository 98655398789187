import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STData, STChange } from '@delon/abc';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { OutputPrefabricatedInvoiceApprovalComponent } from './approval/approval.component';
import { OutputPrefabricatedInvoiceEditComponent } from './edit/edit.component';
import { OutputPrefabricatedInvoiceViewComponent } from './view/view.component';
import { clientConfig } from './../../../../environments/client-config';
import * as format from 'date-fns/format';
import { OutputNisBillingViewComponent } from '../nis-billing/view/view.component';
import { OutputInvoiceInAdvanceViewComponent } from '../invoice-in-advance/view/view.component';
import { OutputJdeBillingViewComponent } from '../jde-billing/view/view.component';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-prefabricated-invoice',
  templateUrl: './prefabricated-invoice.component.html',
  styleUrls: ['./prefabricated-invoice.component.less'],
})
export class OutputPrefabricatedInvoiceComponent implements OnInit {
  loading = false;
  show = false;
  more = 'down';
  rows: any[] = [];
  date: any = {}; // 搜索订单日期
  revocationStatus = [2, 3, 10, 11, 14, 15];
  selectedRows: STData[] = [];
  sumAmount = 0; // 选择订单合计费用
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '发票代码', index: 'invoiceCode', i18n: 'output.st.invoiceCode', width: 100 },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'output.st.invoiceNumber', width: 100 },
    { title: '开具方式', index: 'invoicingMethod', i18n: 'output.st.invoicingMethod', width: 100 },
    { title: '关联会计凭证', index: 'accountingDocument', i18n: 'output.st.accountingDocument', width: 150 },
    { title: '开票日期', index: 'invoicingTime', i18n: 'output.st.invoicingTime', type: 'date', dateFormat: 'YYYY-MM-DD', width: 120 },
    { title: '业务类型', index: 'businessType', i18n: 'output.st.businessType', width: 100 },
    { title: '购方名称', index: 'purchaseName', i18n: 'output.st.purchaseName', width: 150 },
    { title: '销方名称', index: 'sellerName', i18n: 'output.st.sellerName', width: 150 },
    { title: '开票金额(不含税)', index: 'excludingTaxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.invoiceTaxAmount', width: 160 },
    { title: '税额', index: 'taxAmount', i18n: 'output.st.taxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', width: 80 },
    { title: '价税合计', index: 'totalAmount', i18n: 'output.st.invoiceTotalAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', width: 100 },
    { title: '发票类型', index: 'invoiceType', i18n: 'output.st.invoiceType', width: 100 },
    { title: '发票载体', index: 'invoiceEntity', i18n: 'output.st.invoiceEntity', width: 100 },
    { title: '发票状态', index: 'invoiceStatus', i18n: 'output.st.invoiceStatus', render: 'invoiceStatus', fixed: 'right', width: 100 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 120,
      buttons: [
        {
          text: '审批',
          i18n: 'st.operate.approval',
          type: 'modal',
          size: 1100,
          paramName: 'i',
          iif: record => record.invoiceStatus === 2,
          click: (item: any) => this.approval(item)
        },
        {
          text: '查看',
          i18n: 'st.operate.view',
          type: 'modal',
          size: 1100,
          paramName: 'i',
          iif: record => record.invoiceStatus === 3,
          click: (item: any) => this.view(item)
        },
      ]
    }
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    purchaseName: '',
    sellerName: '',
    invoiceCode: '',
    invoiceNumber: '',
    invoiceType: '',
    invoiceEntity: '',
    invoiceStatus: '',
    invoicingTime: '',
    minExcludingTaxAmount: '',
    maxExcludingTaxAmount: '',
    menuType: '1', // 1-预制发票待审批列表
  };
  createDate: ''; // 开票日期
  invoiceStatusOption = clientConfig.outputInvoiceStatusOption; // 全部发票状态
  invoiceTypeOption = clientConfig.invoiceTypeOption;
  invoiceCarrierOption = clientConfig.invoiceCarrierOption;
  pageTotal = 0;

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private cdr: ChangeDetectorRef,
    public msg: NzMessageService,
    private modalService: NzModalService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getList();
  }

  // 获取列表数据
  getList() {
    this.loading = true;
    this.http.get('/output/invoice/list', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 审批
  approval(item) {
    let viewComponent: any = '';
    if (item.businessType === '订单开票') {
      viewComponent = OutputNisBillingViewComponent;
    } else if (item.businessType === '提前开票') {
      viewComponent = OutputInvoiceInAdvanceViewComponent;
    } else {
      viewComponent = OutputJdeBillingViewComponent;
    }
    this.modal.static(viewComponent, { i: { ptype: 2, selectedRows: item } }, 1100).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 撤销
  revocation(invoiceId) {
    this.loading = true;
    this.http.post('/output/invoice/revoked?invoiceIds=' + invoiceId).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msg.success('操作成功');
        this.getList();
      } else {
        this.st.reload();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 批量撤销
  bulkRevocation() {
    this.modalService.confirm({
      nzTitle: '确认要撤销此发票吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          const invoiceIds = this.selectedRows.map(item => item.invoiceId).join(',');
          this.revocation(invoiceIds);
          this.selectedRows = [];
          this.sumAmount = 0;
          resolve(true);
        }).catch(() => console.log('撤销失败!'))
    });
  }

  // 查看
  view(item) {
    let viewComponent: any = '';
    if (item.businessType === '订单开票') {
      viewComponent = OutputNisBillingViewComponent;
    } else if (item.businessType === '提前开票') {
      viewComponent = OutputInvoiceInAdvanceViewComponent;
    } else {
      viewComponent = OutputJdeBillingViewComponent;
    }
    // ptype=3,审批流程查看
    this.modal.static(viewComponent, { i: { ptype: 3, selectedRows: item } }, 1100).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      purchaseName: '',
      sellerName: '',
      invoiceCode: '',
      invoiceNumber: '',
      invoiceType: '',
      invoiceEntity: '',
      invoiceStatus: '',
      invoicingTime: '',
      minExcludingTaxAmount: '',
      maxExcludingTaxAmount: '',
      menuType: '1', // 1-预制发票待审批列表
    };
    this.createDate = '';
    this.getList();
  }

  // 展开
  showOrHidden() {
    this.show = !this.show;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.show) {
      this.more = 'up';
    } else {
      this.more = 'down';
    }
  }

  // 开票日期事件
  createDateChange(event) {
    this.queryParams.invoicingTime = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.selectedRows = event.checkbox!;
      this.sumAmount = this.selectedRows.reduce((total, cv) => total + cv.totalAmount, 0);
      this.cdr.detectChanges();
    }
  }

}
