import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { STComponent, STColumn } from '@delon/abc';
import { DialogService } from 'src/app/service/dialog.service';
import { clientConfig } from './../../../../../environments/client-config';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@env/environment';

@Component({
  selector: 'app-input-invoice-overview-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.less'],

})
export class InputInvoiceOverviewViewComponent implements OnInit {
  loading = false;
  i: any;
  saleTabs: any[] = [{ key: 'basicInfo', id: 1, show: true }, { key: 'invoiceDetails', id: 2 }];
  data: any = {}; // 发票信息
  rows: any = []; // 物料列表
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '货物或应税劳务、服务名称', index: 'sphSpmc', i18n: 'input.invoiceInfo.sphSpmc' },
    { title: '规格型号', index: 'sphGgxh', i18n: 'input.invoiceInfo.sphGgxh' },
    { title: '单位', index: 'sphJldw', i18n: 'input.invoiceInfo.sphJldw' },
    { title: '数量', index: 'sphSl', i18n: 'input.invoiceInfo.sphSl' },
    { title: '单价', index: 'sphDj', i18n: 'input.invoiceInfo.sphDj' },
    { title: '金额', index: 'sphJe', i18n: 'input.invoiceInfo.sphJe' },
    { title: '税率', index: 'sphSlv', i18n: 'input.invoiceInfo.sphSlv' },
    { title: '税额', index: 'sphSe', i18n: 'input.invoiceInfo.sphSe' },
  ];
  queryParams: any = {
    page: 1,
    limit: 5,
  };
  totalPriceUppercase = '零';
  totalPriceLowercase = '0';
  invoiceImage: any = '';
  type: any = ''; // 发票图片类型pdf、jpg
  invoiceStatusOption = clientConfig.invoiceStatusOption; // 全部发票状态
  invoiceId: any = ''; // 发票id

  constructor(
    private modal: NzModalRef,
    public msgSrv: NzMessageService,
    public http: _HttpClient,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    // ptype页面类型：0-默认发票列表的查看，1-进项计税发票差异查看
    this.invoiceId = this.i.ptype === 1 ? this.i.invoiceId : this.invoiceId = this.i.id;
    this.getInfo();
  }

  // 获取发票详情
  getInfo() {
    this.loading = true;
    this.http.get(`/input/invoice/info/${this.invoiceId}`).subscribe(res => {
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        // 发票信息
        if (res.invoice) {
          this.data = res.invoice;
          if (res.invoice.invoiceImage) {
            let type = res.invoice.invoiceImage.split('.').slice(-1).toString();
            this.type = type.substring(0,type.indexOf('?'));
            const imageUrl = environment.FILE_BASE_URL + '/' + res.invoice.invoiceImage;
            console.log('imageUrl', imageUrl);
            this.invoiceImage = this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
          }
        }
        // 物料列表
        if (res.invoiceMaterialEntityList.length > 0) {
          this.rows = res.invoiceMaterialEntityList;
        }
        // 价税合计
        if (res.valorem) {
          this.totalPriceLowercase = res.valorem;
          this.totalPriceUppercase = this.smalltoBIG(res.valorem.toFixed(2));
        }
        this.loading = false;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 货币转成中文大写
  smalltoBIG(price) {
    const fraction = ['角', '分']
    const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
    const unit = [['圆', '万', '亿'], ['', '拾', '佰', '仟']]
    const head = price < 0 ? '欠' : ''
    price = Math.abs(price)
    let upper = ''
    for (let i = 0; i < fraction.length; i++) {
      upper += (digit[Math.floor(price * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '')
    }
    upper = upper || '整'
    price = Math.floor(price)
    for (let i = 0; i < unit[0].length && price > 0; i++) {
      let p = ''
      for (let j = 0; j < unit[1].length && price > 0; j++) {
        p = digit[price % 10] + unit[1][j] + p
        price = Math.floor(price / 10)
      }
      upper = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + upper
    }
    return head + upper.replace(/(零.)*零圆/, '圆').replace(/(零.)+/g, '零').replace(/^整$/, '零圆整')
  }

  // tab切换事件
  salesChange(idx: number) {
    if (this.saleTabs[idx].show !== true) {
      this.saleTabs[idx].show = true;
      this.cdr.detectChanges();
    }
  }

  close() {
    this.modal.destroy();
  }
}
