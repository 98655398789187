import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-input-transfer-out-configuration-add',
  templateUrl: './configuration-add.component.html',
})
export class InputTransferOutConfigurationAddComponent implements OnInit {
  loading = false;
  data: any = {
    labelName: '',
    labelLevel: 1, // 0-标签，1-其他
    labelAttribution: '',
    subjectName: '',
    // numbering: '',
    // rangeId: '',
    // deptId: '',
    labelFalg: 1,
    inquireFalg: '4', // 1-免税，2-礼品，3-福利，4-科目，5-范围
  }
  labelOption: any = [];

  constructor(
    private http: _HttpClient,
    private modal: NzModalRef,
    public msg: NzMessageService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getLabelList();
  }

  // 新增转出配置
  add() {
    this.loading = true;
    this.http.put('/input/label/saveLabel', this.data).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msg.success('操作成功');
        this.cdr.detectChanges();
        this.modal.close(true);
      } else {
        this.modal.close(false);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 获取标签列表
  getLabelList() {
    this.http.get('/input/label/findLabelList?inquireFalg=0').subscribe(data => {
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.labelOption = data.data;
      }
    }, error => {
      console.log('HTTP Error', error);
    });
  }

  close() {
    this.modal.destroy();
  }

}
