import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService, NzModalService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-jde-billing-reject',
  templateUrl: './reject.component.html',
})
export class OutputJdeBillingRejectComponent implements OnInit {
  i: any;
  loading = false;
  rejectData: any = {
    invoiceId: "",
    suggestion: "",
  }; // 驳回信息
  ptype = 2; // 2-申请审批；5-红冲审批；6-作废审批


  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    private modalService: NzModalService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.rejectData.invoiceId = this.i.invoiceId || '';
    this.ptype = this.i.ptype || 2;
  }

  // 驳回
  reject() {
    this.loading = true;
    let actionUrl = '';
    if (this.ptype === 2) {
      actionUrl = '/output/invoice/applyReject'; // 申请驳回
    } else if (this.ptype === 5) {
      actionUrl = '/output/invoice/reverseReject'; // 红冲驳回
    } else if (this.ptype === 6) {
      actionUrl = '/output/invoice/invalidReject'; // 作废驳回
    }
    this.http.post(actionUrl + '?invoiceId=' + this.rejectData.invoiceId + '&suggestion=' + this.rejectData.suggestion).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.modal.close(true);
        const modal = this.modalService.warning({
          nzTitle: '审批驳回',
          nzWrapClassName: 'vertical-center-modal',
        });
        setTimeout(() => modal.destroy(), 3000);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  close() {
    this.modal.destroy();
  }
}
