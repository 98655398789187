import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalRef, NzMessageService, NzCascaderOption } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper, SettingsService } from '@delon/theme';
import { OutputRuleEditCommodityComponent } from '../../rule/edit-commodity/edit-commodity.component';
import { STColumn, STComponent } from '@delon/abc';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-commodity-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.less'],
})
export class OutputCommodityViewComponent implements OnInit {
  loading = false;
  goodsId: any = '';
  data: any = {
    "goodsName": "",
    "deptId": '', // todo
    "skuCode": "",
    "goodsNo": "",
    "taxCode": "",
    "taxName": "", // todo
    "taxRate": "",
    "goodsSubject": "",
    "goodsCode": "",
    "specifications": "",
    "unit": "",
    "preferential": 1,
    "preferentialType": "1",
    "nisUnit": "",
    "price": "",
  }
  organizationOption = [
    { value: '01', label: '肯德基上海总公司' },
    { value: '02', label: '肯德基北京总公司' },
    { value: '03', label: '肯德基郑州总公司' },
  ]; // 所属组织机构
  discountTypeOption = [
    { value: '1', label: '免税' },
    { value: '2', label: '部分免税' },
    { value: '3', label: '收税' },
  ]; // 优税收惠类型
  rateOption = [
    { value: '0.07', label: '7%' },
    { value: '0.11', label: '11%' },
    { value: '0.13', label: '13%' },
  ]; // 税率
  // 特定客户含税单价设置列表
  rows: any = [
    { 'id': 1, 'code': '100011', 'name': '商品名称10011', 'taxCode': '10010', 'unitPrice': 5000 },
    { 'id': 2, 'code': '100012', 'name': '商品名称10012', 'taxCode': '10010', 'unitPrice': 4000 },
    { 'id': 3, 'code': '100013', 'name': '商品名称10013', 'taxCode': '10010', 'unitPrice': 3000 },
  ];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '客户编码', index: 'code' },
    { title: '客户名称', index: 'name' },
    { title: '纳税人识别号', index: 'taxCode' },
    { title: '商品单价（含税价）', index: 'unitPrice', render: 'unitPrice', type: 'number', numberDigits: '.2-2', className: 'text-left' },
  ];
  nzOptions: NzCascaderOption[] = []; // 所属公司树选择列表

  constructor(
    private route: ActivatedRoute,
    private msgSrv: NzMessageService,
    private modal: ModalHelper,
    public http: _HttpClient,
    private router: Router,
    private dialogService: DialogService,
    public settingsService: SettingsService,
  ) { }

  ngOnInit(): void {
    this.goodsId = this.route.snapshot.queryParams.goodsId || '';
    this.getInfo();
    if (this.settingsService.user.deptType === 1) {
      // 集团用户显示部门列表
      this.getDeptList();
    } else {
      // 门店显示所属公司
      this.data.deptId = this.settingsService.user.deptId;
    }
  }

  // 获取商品信息
  getInfo() {
    this.loading = true;
    this.http.get(`/output/goods/info/${this.goodsId}`).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.data = res.goods;
        if (res.goods.deptId) {
          this.data.deptId = res.goods.deptId.toString();
          console.log('data.deptId::',this.data.deptId);
        }
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 获取当前企业子公司列表
  getDeptList() {
    this.loading = true;
    this.http.get('/sys/dept/treeSelect').subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.msg === 'success') {
        if (res.data) {
          this.nzOptions = res.data;
          // 设置联动子叶标识
          this.treeList(this.nzOptions);
        }
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 树
  treeList(data) {
    if (data.length > 0) {
      for (const dept of data) {
        if (dept.children.length === 0) {
          dept.isLeaf = true;
        }
        if (dept.children.length > 0) {
          this.treeList(dept.children);
        }
      }
    }
  }

  // 获取特定客户列表
  getList() {

  }

  // 新增特定商品规则
  addSpecialBilling() {
    this.modal.static(OutputRuleEditCommodityComponent, { i: { ptype: 0 } }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      }
    });
  }

  back() {
    this.router.navigate(['/output/commodity']);
  }
}
