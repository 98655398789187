import { Component, OnInit } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { OutputNisBillingMergerInvoiceComponent } from '../merger-invoice/merger-invoice.component';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-nis-billing-excess-remind',
  templateUrl: './excess-remind.component.html',
  styleUrls: ['./excess-remind.component.less'],
})
export class OutputNisBillingExcessRemindComponent implements OnInit {
  loading = false;
  i: any;
  orderAmount = 0; // 订单总额
  invoiceAmountLimit = 0; // 开票金额上限
  splitInvoiceNumber = 0; // 拆分发票数量
  customerName = ''; // 购方名称
  orderIds = ''; // 申请开票订单id

  constructor(
    private modal: NzModalRef,
    public msgSrv: NzMessageService,
    public http: _HttpClient,
    private modaHelper: ModalHelper,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.orderAmount = this.i.orderAmount || 0;
    this.customerName = this.i.customerName || '';
    this.orderIds = this.i.orderIds || '';
    this.invoiceAmountLimit = this.i.invoiceAmountLimit || 0;
    this.getSplitNumber();
  }

  // 跳转合并拆分订单
  splitInvoice() {
    this.modal.close(false);
    // ptype=0代表拆分开票
    this.modaHelper.static(OutputNisBillingMergerInvoiceComponent, { i: { ptype: 0, orderIds: this.orderIds, customerName: this.customerName, orderAmount: this.orderAmount } }, 1100).subscribe((success) => {
      if (success) {
        this.modal.close(true);
      }
    });
  }

  // 获取订单拆分张数接口
  getSplitNumber() {
    this.loading = true;
    this.http.get('/output/invoiceapply/splitNumber?orderIds=' + this.orderIds + '&amount=' + this.invoiceAmountLimit).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.msg === 'success') {
        this.splitInvoiceNumber = res.number;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  close() {
    this.modal.destroy();
  }
}
