import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-configuration-tax-classification-edit',
  templateUrl: './edit.component.html',
})
export class OutputConfigurationTaxClassificationEditComponent implements OnInit {
  loading = false;
  i: any;
  ptype = 0;
  data: any = {
    classificationId: '',
    goodsNumber: '',
    goodsName: '',
    goodsTaxCode: '',
    goodsTaxRate: '',
  }
  rateOption = [
    { value: '0.07', label: '7%' },
    { value: '0.11', label: '11%' },
    { value: '0.13', label: '13%' },
  ]; // 税率

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.ptype = this.i.ptype || 0;;
    if (this.ptype === 1) {
      this.data = this.i.selectedRows;
    }
  }

  // 提交新增或修改
  saveOrUpdate() {
    if (this.ptype === 1) {
      this.update();
    } else {
      this.save();
    }
  }

  // 新增
  save() {
    this.loading = true;
    this.http.put('/output/tax/classification/save', this.data).subscribe(res => {
      this.loading = false;
      const flag = this.dialogService.getErrorCode(res);
      if (flag) {
        this.modal.close(true);
        this.msgSrv.success('操作成功！');
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 修改
  update() {
    this.loading = true;
    this.http.post('/output/tax/classification/update', this.data).subscribe(res => {
      this.loading = false;
      const flag = this.dialogService.getErrorCode(res);
      if (flag) {
        this.modal.close(true);
        this.msgSrv.success('操作成功！');
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }


  close() {
    this.modal.destroy();
  }
}
