import { Component, OnInit } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-invoiced-print-confirmation',
  templateUrl: './print-confirmation.component.html',
  styleUrls: ['./print-confirmation.component.less'],
})
export class OutputInvoicedPrintConfirmationComponent implements OnInit {
  i: any;
  selectedRows: any = []; // 发票列表
  invoiceCode = ''; // 发票代码
  invoiceNumber = ''; // 发票号码
  invoiceIds = ''; // 所选发票id
  loading = false;


  constructor(
    private modal: NzModalRef,
    public msgSrv: NzMessageService,
    public http: _HttpClient,
    private modaHelper: ModalHelper,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    if (this.i.selectedRows) {
      this.selectedRows = this.i.selectedRows;
      this.invoiceCode = this.selectedRows[0].invoiceCode || '';
      this.invoiceNumber = this.selectedRows[0].invoiceNumber || '';
      this.invoiceIds = this.selectedRows.length > 1 ? this.selectedRows.map(item => item.invoiceId).filter(invoiceId => invoiceId).join(',') : this.selectedRows[0].invoiceId;
    }
  }

  // 打印
  print() {
    this.loading = true;
    this.http.post('/output/invoice/print?invoiceId=' + this.invoiceIds).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.modal.close(true);
        this.msgSrv.success('打印成功！');
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  close() {
    this.modal.destroy();
  }
}
