import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STData, STComponent, STColumn } from '@delon/abc';
import { InputPurchaseOrderRefundComponent } from '../refund/refund.component';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';
import { DialogService } from 'src/app/service/dialog.service';
import * as format from 'date-fns/format';
import { clientConfig } from './../../../../../environments/client-config';
import { InputInvoiceOverviewEditComponent } from '../../invoice-overview/edit/edit.component';


@Component({
  selector: 'app-input-purchase-order-authenticity',
  templateUrl: './authenticity.component.html',
  styleUrls: ['./authenticity.component.less'],
})
export class InputPurchaseOrderAuthenticityComponent implements OnInit {
  show = false;
  more = 'down';
  selectedRows: STData[] = []; // 选中数据
  rows: any = [];
  batchList: any = [{ 'id': '', 'invoiceBatchNumber': '' }]; // 采购单号列表
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '批次号', index: 'invoiceBatchNumber', i18n: 'st.batchNumber', width: 100 },
    { title: '购方企业名称', index: 'invoicePurchaserCompany', i18n: 'st.invoicePurchaserCompany', width: 200 },
    { title: '购方税号', index: 'invoicePurchaserParagraph', i18n: 'st.invoicePurchaserParagraph', width: 100 },
    { title: '销方企业名称', index: 'invoiceSellCompany', i18n: 'st.invoiceSellCompany', width: 200 },
    { title: '销方税号', index: 'invoiceSellParagraph', i18n: 'st.invoiceSellParagraph', width: 100 },
    { title: '上传人', index: 'createBy', i18n: 'st.createUserName', width: 100 },
    { title: '上传日期', index: 'createTime', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceUploadDate', width: 120 },
    { title: '批次状态', index: 'invoiceBatchStatus', render: 'invoiceBatchStatus', i18n: 'st.invoiceBatchStatus', width: 100 },
    { title: '详情', index: 'threeErrorDescription', render: 'threeErrorDescription', i18n: 'st.threeErrorDescription', width: 100 },
  ];
  // 嵌套发票列表
  @ViewChild('expendst', { static: false }) expendst: STComponent;
  expendColumns: STColumn[] = [
    { title: '', index: 'invoiceNumber', type: 'checkbox', className: 'st-checkbox' },
    { title: '发票代码', index: 'invoiceCode', i18n: 'st.invoiceCode', width: 100 },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'st.invoiceNumber', width: 100 },
    { title: '票面金额(含税)', index: 'invoiceTotalPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTotalPrice', width: 150 },
    { title: '票面金额(不含税)', index: 'invoiceFreePrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceFreePrice', width: 150 },
    { title: '税额', index: 'invoiceTaxPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTaxPrice', width: 60 },
    { title: '校验码', index: 'invoiceCheckCode', i18n: 'st.invoiceCheckCode', width: 100 },
    { title: '开票日期', index: 'invoiceCreateDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceCreateDate', width: 120 },
    { title: '发票类型', index: 'invoiceEntity', render: 'invoiceEntity', i18n: 'st.invoiceEntity', width: 100 },
    { title: '入账日期', index: 'entryDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.entryDate', width: 120 },
    { title: '上传方式', index: 'invoiceUploadType', render: 'invoiceUploadType', i18n: 'st.invoiceUploadType', width: 100 },
    { title: '发票状态', index: 'invoiceStatus', render: 'invoiceStatus', i18n: 'st.invoiceStatus', fixed: 'right', width: 100 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 200,
      buttons: [
        {
          text: '发票信息',
          i18n: 'input.invoiceInfo',
          type: 'modal',
          size: 1100,
          component: InputInvoiceOverviewEditComponent,
          paramName: 'i',
          click: (item: any) => this.getList(),
        },
        {
          text: '验真',
          i18n: 'st.operate.authenticity',
          paramName: 'i',
          pop: true,
          popTitle: '确认要进行验真操作吗？',
          iif: item => item.invoiceStatus === '3' || item.invoiceStatus === '4',
          click: (item: any) => this.checkTrue(item.id),
        },
        {
          text: '...',
          paramName: 'i',
          children: [
            {
              text: '退票',
              i18n: 'st.operate.refund',
              paramName: 'i',
              pop: true,
              popTitle: '确认要进行退票操作吗？',
              iif: item => item.invoiceStatus > 1 && item.invoiceStatus < 10,
              click: (item: any) => this.refund(item.id),
            },
            {
              text: '删除',
              i18n: 'st.operate.delete',
              paramName: 'i',
              pop: true,
              popTitle: '确认要进行删除操作吗？',
              click: (item: any) => this.delete(item.id),
            },
          ]
        },
      ]
    }
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    invoiceBatchNumber: '',
    createDate: '',
    createBy: '',
    invoicePurchaserCompany: '',
    invoiceSellCompany: '',
    invoiceCode: '',
    invoiceNumber: '',
    invoiceEntity: '',
    invoiceCreateDate: '',
    invoiceStatus: '',
    minAmount: '',
    maxAmount: '',
    menuType: '2', // 采购单-验真列表参数
  };
  createDate: '';
  invoiceCreateDate: '';
  pageTotal = 0;
  loading = false;
  // 验真发票状态
  invoiceStatusOption = clientConfig.validationInvoiceStatusOption;

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private msgSrv: NzMessageService,
    private modalSrv: NzModalService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getList();
  }

  // 获取列表
  getList() {
    this.loading = true;
    this.http.get('/input/invoiceBatch/listForThree', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.batchList = data.invoiceBatchEntityList;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 验真
  checkTrue(id) {
    this.loading = true;
    this.http.post('/input/invoice/batchCheckTrue?id=' + id).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('验真成功');
        this.getList();
      } else {
        this.st.reload();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 批量验真
  bulkCheckTrue() {
    this.modalSrv.confirm({
      nzContent: '确认要进行<font color="red">验真</font>操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        // tslint:disable-next-line: no-shadowed-variable
        new Promise((resolve, reject) => {
          // 循环验真所选数据
          this.selectedRows.map(item => {
            this.checkTrue(item.id);
          })
          this.selectedRows = [];
          resolve(true);
        }).catch(() => console.log('验真失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          this.selectedRows = [];
          this.st.reload();
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 退票
  refund(id) {
    this.modal.static(InputPurchaseOrderRefundComponent, { i: { invoiceId: id } }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 批量退票
  bulkRefund() {
    this.modalSrv.confirm({
      nzContent: '确认要进行<font color="red">退票</font>操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          // 处理批量退票发票id
          const idList = [];
          this.selectedRows.map(item => {
            idList.push(item.id);
          })
          const ids = idList.join(',');
          this.refund(ids);
          this.selectedRows = [];
          resolve(true);
        }).catch(() => console.log('退票失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          this.selectedRows = [];
          this.st.reload();
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 删除
  delete(id) {
    this.loading = true;
    const idList = Array.isArray(id) ? id : [id];
    this.http.post('/input/invoice/delete', (idList)).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('删除成功');
        this.getList();
      } else {
        this.msgSrv.error(res.msg);
        this.st.reload();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 批量删除
  bulkDelete() {
    this.modalSrv.confirm({
      nzContent: '确认要进行<font color="red">删除</font>操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          // 处理获取的数据id
          const idList = new Array();
          this.selectedRows.map(item => {
            idList.push(item.id);
          })
          this.delete(idList);
          this.selectedRows = [];
          resolve(true);
        }).catch(() => console.log('删除失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          this.selectedRows = [];
          this.st.reload();
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 多选框禁用
  disabledCheckbox(data: STData[]) {
    return data.map((i: STData, index: number) => {
      i.disabled = index === 0;
      return i;
    });
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      invoiceBatchNumber: '',
      createDate: '',
      createBy: '',
      invoicePurchaserCompany: '',
      invoiceSellCompany: '',
      invoiceCode: '',
      invoiceNumber: '',
      invoiceEntity: '',
      invoiceCreateDate: '',
      invoiceStatus: '',
      minAmount: '',
      maxAmount: '',
      menuType: '2',
    };
    this.createDate = '';
    this.invoiceCreateDate = '';
    this.getList();
  }

  // 展开
  showOrHidden() {
    this.show = !this.show;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.show) {
      this.more = 'up';
    } else {
      this.more = 'down';
    }
  }

  // 开票日期事件
  invoiceCreateDateChange(event) {
    this.queryParams.invoiceCreateDate = event.length > 0 ? format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD') : '';
  }

  // 上传日期事件
  invoiceUploadDateChange(event) {
    this.queryParams.createDate = event.length > 0 ? format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD') : '';
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.selectedRows = event.checkbox!;
    }
  }

}
