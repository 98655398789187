import { Component, OnInit } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-admin-role-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.less'],
})
export class AdminRoleViewComponent implements OnInit {
  loading = false;
  i: any;
  menuIdList: any = []; // 选中权限id列表
  // 角色信息
  data = {
    "roleId": "",
    "deptId": 1,
    "roleName": "",
    "menuIdList": [],
    "deptIdList": []
  }
  // 所有菜单列表
  menuList: any = [];
  // 角色权限列表
  authList: any = [
    {
      module: '',
      parentMenu: '',
      submenu: '',
      permissions: [],
      count: 1,
      count1: 1,
    }
  ];
  moduleName: any = ''; // 一级菜单
  parentMenu: any = ''; // 二级菜单
  submenu: any = ''; // 三级菜单
  permList = []; // 操作权限
  count = 0; // 一级菜单合并数
  count1 = 0; // 二级菜单合并数

  constructor(
    private modal: NzModalRef,
    public msgSrv: NzMessageService,
    public http: _HttpClient,
    private dialogService: DialogService
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    // 获取所有权限菜单列表
    this.authList = await this.getMenuList();
    await this.getRoleInfo();
    this.loading = false;
  }

    // 获取角色信息
    getRoleInfo() {
      this.http.get(`/sys/role/info/${this.i.roleId}`).subscribe(async res => {
        this.dialogService.getErrorCode(res);
        if (res.code === 0) {
          this.data = res.role;
          this.menuIdList = this.data.menuIdList;
          // 处理权限列表选中
          this.data.menuIdList.map(item => {
            // 遍历authList中每一列的值进行匹配选中项
            this.authList.map(menu => {
              if (item === menu.module.value) {
                menu.module.checked = true;
              } else if (item === menu.parentMenu.value) {
                menu.parentMenu.checked = true;
              } else if (item === menu.submenu.value) {
                menu.submenu.checked = true;
              } else {
                if (menu.permissions.length > 0) {
                  for (const permer of menu.permissions) {
                    if (item === permer.value) {
                      permer.checked = true;
                    }
                  }
                }
              }
            })
          });
        }
      }, error => {
        console.log('HTTP Error', error);
      });
    }

    // 获取初始化权限菜单列表
    getMenuList() {
      return new Promise((resolve, reject) => {
        this.http.get(`/sys/menu/alllist`).subscribe(async res => {
          this.dialogService.getErrorCode(res);
          if (res.msg === 'success') {
            this.menuList = res.menuList;
            this.authList = [];
            const authrows = await this.generateAuthList(res.menuList);
            resolve(authrows);
          }
        }, error => {
          resolve(error.message);
        });
      });
    }

    // 处理返回的菜单列表
    async generateAuthList(menuList) {
      menuList.map(menu => {
        if (menu.type === 0) {
          if (menu.children.length > 0) {
            for (const child of menu.children) {
              // 一级菜单合并为二级菜单合并之和，即对应三级菜单的长度之和;二级菜单没有合并则为二级菜单长度之和
              if (child.children.length > 0) {
                if (child.children[0].type === 11) {
                  this.count += child.children.length;
                } else {
                  this.count += 1;
                }
              } else {
                this.count += 1;
              }
            }
          }
          this.count1 = 1;
          this.moduleName = { label: menu.name, value: menu.menuId, checked: false }; // 一级菜单

        } else if (menu.type === 1) {
          if (menu.children.length > 0) {
            // 如果有三级子菜单type=11，count1=子菜单长度进行合并；没有则为1
            this.count1 = menu.children[0].type === 11 ? menu.children.length : 1;
          }
          this.parentMenu = { label: menu.name, value: menu.menuId, checked: false }; // 二级菜单
        } else if (menu.type === 11) {
          this.submenu = { label: menu.name, value: menu.menuId, checked: false }; // 三级菜单
        }
        // 处理权限permissions
        if (menu.children.length > 0) {
          // 当children里的数据type=2时为权限，循环处理操作权限列表
          if (menu.children[0].type === 2) {
            for (const child of menu.children) {
              this.permList.push({ label: child.name, value: child.menuId, checked: false });
            }
            // 生成一条菜单记录
            this.authList.push({ module: this.moduleName, parentMenu: this.parentMenu, submenu: this.submenu, permissions: this.permList, count: this.count, count1: this.count1 });
            this.submenu = '';
            this.permList = []; // 生成记录后重置permList
            this.count = 0;
            this.count1 = 0;
          } else {
            this.generateAuthList(menu.children);
          }
        }
      })
      return this.authList;
    }

  close() {
    this.modal.destroy();
  }
}
