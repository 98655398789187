import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService, NzModalService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { STColumn, STComponent, STData } from '@delon/abc';
import { InputPurchaseOrderRefundComponent } from '../../purchase-order/refund/refund.component';
import { InputExpenseAccountInvoiceEditComponent } from '../invoice-edit/invoice-edit.component';
import * as format from 'date-fns/format';
import { clientConfig } from './../../../../../environments/client-config';
import { DialogService } from 'src/app/service/dialog.service';
import { InputExpenseAccountInvoiceViewComponent } from '../invoice-view/invoice-view.component';

@Component({
  selector: 'app-input-expense-account-invoice-info',
  templateUrl: './invoice-info.component.html',
  styleUrls: ['./invoice-info.component.less']
})
export class InputExpenseAccountInvoiceInfoComponent implements OnInit {
  i: any;
  show = false;
  more = 'down';
  selectedRows: STData[] = []; // 选中数据
  // 增值税列表
  rows: any = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '发票代码', index: 'invoiceCode', i18n: 'st.invoiceCode', width: 120 },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'st.invoiceNumber', width: 120 },
    { title: '购方企业名称', index: 'invoicePurchaserCompany', i18n: 'st.invoicePurchaserCompany', width: 150 },
    { title: '购方税号', index: 'invoicePurchaserParagraph', i18n: 'st.invoicePurchaserParagraph', width: 100 },
    { title: '销方企业名称', index: 'invoiceSellCompany', i18n: 'st.invoiceSellCompany', width: 150 },
    { title: '销方税号', index: 'invoiceSellParagraph', i18n: 'st.invoiceSellParagraph', width: 100 },
    { title: '票面金额(含税)', index: 'invoiceTotalPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTotalPrice', width: 200 },
    { title: '票面金额(不含税)', index: 'invoiceFreePrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceFreePrice', width: 200 },
    { title: '税额', index: 'invoiceTaxPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTaxPrice', width: 100 },
    { title: '校验码', index: 'invoiceCheckCode', i18n: 'st.invoiceCheckCode', width: 120 },
    { title: '开票日期', index: 'invoiceCreateDate', i18n: 'st.invoiceCreateDate', type: 'date', dateFormat: 'YYYY-MM-DD', width: 100 },
    { title: '发票类型', index: 'invoiceEntity', render: 'invoiceEntity', i18n: 'st.invoiceEntity', width: 100 },
    { title: '上传日期', index: 'invoiceUploadDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceUploadDate', width: 100 },
    { title: '上传人', index: 'createUserName', i18n: 'st.createUserName', width: 100 },
    { title: '上传方式', index: 'invoiceUploadType', render: 'invoiceUploadType', i18n: 'st.invoiceUploadType', width: 100 },
    { title: '详情', index: 'invoiceErrorDescription', render: 'invoiceErrorDescription', i18n: 'st.threeErrorDescription', width: 100 },
    { title: '发票状态', index: 'invoiceStatus', i18n: 'st.invoiceStatus', render: 'invoiceStatus', fixed: 'right', width: 100 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 150,
      buttons: [
        {
          text: '查看',
          i18n: 'st.operate.view',
          type: 'modal',
          size: 1100,
          component: InputExpenseAccountInvoiceViewComponent,
          paramName: 'i',
          click: (item: any) => this.getNonVATList(),
        },
        {
          text: '验真',
          i18n: 'st.operate.authenticity',
          paramName: 'i',
          pop: true,
          popTitle: '确认要进行验真操作吗？',
          iif: item => item.invoiceStatus === '3' || item.invoiceStatus === '4',
          click: (item: any) => this.checkTrue(item.id),
        },
        {
          text: '...',
          paramName: 'i',
          children: [
            {
              text: '退票',
              i18n: 'st.operate.refund',
              paramName: 'i',
              pop: true,
              popTitle: '确认要进行退票操作吗？',
              iif: item => item.invoiceStatus > 1 && item.invoiceStatus < 10,
              click: (item: any) => this.refund(item.id),
            },
            {
              text: '删除',
              i18n: 'st.operate.delete',
              paramName: 'i',
              pop: true,
              popTitle: '确认要进行删除操作吗？',
              click: (item: any) => this.delete(item.id),
            },
          ]
        },
      ]
    }
  ];
  // 非增值税列表
  nonVATRows = [];
  @ViewChild('nonVATSt', { static: false }) nonVATSt: STComponent;
  nonVATColumns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '发票类型', index: 'invoiceType', i18n: 'st.invoiceEntity' },
    { title: '票面金额', index: 'amount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoicePrice' },
    { title: '创建时间', index: 'createTime', i18n: 'st.createTime' },
    {
      title: '操作',
      i18n: 'st.operate',
      buttons: [
        {
          text: '编辑发票信息',
          i18n: 'st.operate.editInvoice',
          type: 'modal',
          size: 500,
          component: InputExpenseAccountInvoiceEditComponent,
          paramName: 'i',
          click: (item: any) => this.getNonVATList(),
        },
        {
          text: '查看',
          i18n: 'st.operate.view',
          type: 'modal',
          size: 1100,
          component: InputExpenseAccountInvoiceViewComponent,
          paramName: 'i',
          click: (item: any) => this.getNonVATList(),
        },
        {
          text: '删除',
          i18n: 'st.operate.delete',
          paramName: 'i',
          pop: true,
          popTitle: '确认要进行删除操作吗？',
          click: (item: any) => this.nonDelete(item.id),
        },
      ]
    }
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    invoiceUploadDateArray: '',
    createUserName: '',
    invoicePurchaserCompany: '',
    invoiceSellCompany: '',
    invoiceCode: '',
    invoiceNumber: '',
    invoiceEntity: '',
    invoiceCreateDateArray: '',
    invoiceStatus: '',
    invoiceTotalPriceBegin: '',
    invoiceTotalPriceEnd: '',
  };
  pageTotal = 0;
  loading = false;
  invoiceStatusOption = clientConfig.invoiceStatusOption; // 全部发票状态
  params = {
    expenseNo: '', // 报销单号
    invoiceType: '', // 发票类型
  }


  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    private modalHelper: ModalHelper,
    private modalSrv: NzModalService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    // 获取报销单号和发票类型
    if (this.i) {
      this.params.expenseNo = this.i.expenseNo;
      this.params.invoiceType = this.i.invoiceType;
    }
    if (this.i.ptype === 0) {
      // 非增值税列表
      this.getNonVATList();
    } else {
      // 增值税列表
      this.getVATList();
    }

  }

  // 获取增值税发票列表
  getVATList() {
    this.loading = true;
    // this.parseParam(this.queryParams);
    this.http.post('/input/oaexpenseinvoicemapping/invoicesList?page=' + this.queryParams.page + '&limit=' + this.queryParams.limit + '&invoiceCode=' + this.queryParams.invoiceCode + '&invoicePurchaserCompany=' + this.queryParams.invoicePurchaserCompany + '&invoiceSellCompany=' + this.queryParams.invoiceSellCompany + '&invoiceEntity=' + this.queryParams.invoiceEntity + '&invoiceUploadDateArray=' + this.queryParams.invoiceUploadDateArray + '&invoiceCreateDateArray=' + this.queryParams.invoiceCreateDateArray + '&invoiceTotalPriceBegin=' + this.queryParams.invoiceTotalPriceBegin + '&invoiceTotalPriceEnd=' + this.queryParams.invoiceTotalPriceBegin + '&invoiceStatus=' + this.queryParams.invoiceStatus + '&createUserName=' + this.queryParams.createUserName, this.params).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 传参格式化
  parseParam(json) {
    return Object.keys(json).map(v => {
      if (json[v] != null) {
        return v + '=' + json[v];
      }
    }).filter(a => {
      return a;
    }).join('&');
  }

  // 获取非增值税发票列表
  getNonVATList() {
    this.loading = true;
    this.http.post('/input/oaexpenseinvoicemapping/informalInvoiceList?page=' + this.queryParams.page + '&limit=' + this.queryParams.limit, this.params).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.nonVATRows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
        // 添加发票类型字段
        this.nonVATRows.map(item => {
          item.invoiceType = this.params.invoiceType;
        });
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 验真
  checkTrue(id) {
    this.loading = true;
    this.http.post('/input/invoice/batchCheckTrue?id=' + id).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('验真成功');
        this.getVATList();
      } else {
        this.st.reload();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 批量验真
  bulkCheckTrue() {
    this.modalSrv.confirm({
      nzContent: '确认要进行<font color="red">验真</font>操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        // tslint:disable-next-line: no-shadowed-variable
        new Promise((resolve, reject) => {
          // 循环验真所选数据
          this.selectedRows.map(item => {
            this.checkTrue(item.id);
          })
          this.selectedRows = [];
          resolve(true);
        }).catch(() => console.log('验真失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          this.selectedRows = [];
          this.st.reload();
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 退票
  refund(id) {
    this.modalHelper.static(InputPurchaseOrderRefundComponent, { i: { invoiceId: id } }, 500).subscribe((success) => {
      if (success) {
        this.getVATList();
      } else {
        this.st.reload();
      }
    });
  }

  // 批量退票
  bulkRefund() {
    this.modalSrv.confirm({
      nzContent: '确认要进行<font color="red">退票</font>操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          // 处理批量退票发票id
          const idList = [];
          this.selectedRows.map(item => {
            idList.push(item.id);
          })
          const ids = idList.join(',');
          this.refund(ids);
          this.selectedRows = [];
          resolve(true);
        }).catch(() => console.log('退票失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          this.selectedRows = [];
          this.st.reload();
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 删除
  delete(id) {
    this.loading = true;
    const idList = Array.isArray(id) ? id : [id];
    this.http.post('/input/invoice/delete', (idList)).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('删除成功');
        this.getVATList();
      } else {
        this.st.reload();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 批量删除
  bulkDelete() {
    this.modalSrv.confirm({
      nzContent: '确认要进行<font color="red">删除</font>操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          // 处理获取的数据id
          const idList = new Array();
          this.selectedRows.map(item => {
            idList.push(item.id);
          })
          this.delete(idList);
          this.selectedRows = [];
          resolve(true);
        }).catch(() => console.log('删除失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          this.selectedRows = [];
          this.st.reload();
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 非增值税列表-删除
  nonDelete(id) {
    this.loading = true;
    const idList = Array.isArray(id) ? id : [id];
    this.http.post('/input/unformatinvoice/delete', (idList)).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('删除成功');
        this.getNonVATList()
      } else {
        this.nonVATSt.reload();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 非增值税-批量删除
  nonBulkDelete() {
    this.modalSrv.confirm({
      nzContent: '确认要进行<font color="red">删除</font>操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          const idList = new Array();
          this.selectedRows.map(item => {
            idList.push(item.id);
          })
          this.nonDelete(idList);
          this.selectedRows = [];
          resolve(true);
        }).catch(() => console.log('删除失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          this.selectedRows = [];
          this.nonVATSt.reload();
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getVATList();
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      invoiceUploadDateArray: '',
      createUserName: '',
      invoicePurchaserCompany: '',
      invoiceSellCompany: '',
      invoiceCode: '',
      invoiceNumber: '',
      invoiceEntity: '',
      invoiceCreateDateArray: '',
      invoiceStatus: '',
      invoiceTotalPriceBegin: '',
      invoiceTotalPriceEnd: '',
    };
    this.getVATList();
  }

  // 开票日期事件
  invoiceCreateDateChange(event) {
    this.queryParams.invoiceCreateDateArray = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 上传日期事件
  invoiceUploadDateChange(event) {
    this.queryParams.invoiceUploadDateArray = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 展开
  showOrHidden() {
    this.show = !this.show;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.show) {
      this.more = 'up';
    } else {
      this.more = 'down';
    }
  }


  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        if (this.i.ptype === 0) {
          this.getNonVATList();
        } else {
          this.getVATList();
        }
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        if (this.i.ptype === 0) {
          this.getNonVATList();
        } else {
          this.getVATList();
        }
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.selectedRows = event.checkbox!;
    }
  }


  close() {
    this.modal.destroy();
  }
}
