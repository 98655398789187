import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { OutputRoutingModule } from './output-routing.module';
import { OutputNISBillingComponent } from './nis-billing/nis-billing.component';
import { OutputNisBillingOrderConfirmationComponent } from './nis-billing/order-confirmation/order-confirmation.component';
import { OutputNisBillingExcessRemindComponent } from './nis-billing/excess-remind/excess-remind.component';
import { OutputNisBillingMergerInvoiceComponent } from './nis-billing/merger-invoice/merger-invoice.component';
import { OutputJDEBillingComponent } from './jde-billing/jde-billing.component';
import { OutputPrefabricatedInvoiceComponent } from './prefabricated-invoice/prefabricated-invoice.component';
import { OutputInvoicedComponent } from './invoiced/invoiced.component';
import { OutputCreditNoteComponent } from './credit-note/credit-note.component';
import { OutputNoCreditNoteComponent } from './no-credit-note/no-credit-note.component';
import { OutputRuleComponent } from './rule/rule.component';
import { OutputCommodityComponent } from './commodity/commodity.component';
import { OutputProductComponent } from './product/product.component';
import { OutputNisBillingMakeAnInvoiceComponent } from './nis-billing/make-an-invoice/make-an-invoice.component';
import { OutputNisBillingEditComponent } from './nis-billing/edit/edit.component';
import { OutputNisBillingViewComponent } from './nis-billing/view/view.component';
import { OutputPrefabricatedInvoiceEditComponent } from './prefabricated-invoice/edit/edit.component';
import { OutputPrefabricatedInvoiceViewComponent } from './prefabricated-invoice/view/view.component';
import { OutputPrefabricatedInvoiceApprovalComponent } from './prefabricated-invoice/approval/approval.component';
import { OutputRuleViewRuleComponent } from './rule/view-rule/view-rule.component';
import { OutputRuleEditInfoComponent } from './rule/edit-info/edit-info.component';
import { OutputRuleEditPriceComponent } from './rule/edit-price/edit-price.component';
import { OutputRuleEditCommodityComponent } from './rule/edit-commodity/edit-commodity.component';
import { OutputCommodityEditComponent } from './commodity/edit/edit.component';
import { OutputCommodityViewComponent } from './commodity/view/view.component';
import { OutputConfigurationCorporateCustomerRulesComponent } from './configuration/corporate-customer-rules/corporate-customer-rules.component';
import { OutputConfigurationCorporateCustomerRulesViewComponent } from './configuration/corporate-customer-rules/view/view.component';
import { OutputConfigurationCorporateCustomerRulesEditComponent } from './configuration/corporate-customer-rules/edit/edit.component';
import { OutputNisBillingViewInvoiceComponent } from './nis-billing/view-invoice/view-invoice.component';
import { OutputJdeBillingApplyForBillingComponent } from './jde-billing/apply-for-billing/apply-for-billing.component';
import { OutputInvoiceInAdvanceComponent } from './invoice-in-advance/invoice-in-advance.component';
import { OutputInvoiceInAdvanceWriteOffComponent } from './invoice-in-advance/write-off/write-off.component';
import { OutputPrefabricatedInvoicePendingInvoicingComponent } from './prefabricated-invoice/pending-invoicing/pending-invoicing.component';
import { OutputJdeBillingViewComponent } from './jde-billing/view/view.component';
import { OutputInvoiceInAdvanceApplyForBillingComponent } from './invoice-in-advance/apply-for-billing/apply-for-billing.component';
import { OutputInvoiceInAdvanceViewComponent } from './invoice-in-advance/view/view.component';
import { OutputJdeBillingRejectComponent } from './jde-billing/reject/reject.component';
import { OutputInvoicedManualRedPushComponent } from './invoiced/manual-red-push/manual-red-push.component';
import { OutputInvoicedRelatedOrdersComponent } from './invoiced/related-orders/related-orders.component';
import { OutputInvoicedInvalidComponent } from './invoiced/invalid/invalid.component';
import { OutputInvoicedPrintConfirmationComponent } from './invoiced/print-confirmation/print-confirmation.component';
import { OutputNoCreditNoteInvoicedListComponent } from './no-credit-note/invoiced-list/invoiced-list.component';
import { OutputConfigurationInvoiceInventoryComponent } from './configuration/invoice-inventory/invoice-inventory.component';
import { OutputConfigurationInvoiceInventoryEditComponent } from './configuration/invoice-inventory/edit/edit.component';
import { OutputConfigurationInvoiceInventorySetAlarmRuleComponent } from './configuration/invoice-inventory/set-alarm-rule/set-alarm-rule.component';
import { OutputConfigurationSubjectComponent } from './configuration/subject/subject.component';
import { OutputConfigurationSubjectEditComponent } from './configuration/subject/edit/edit.component';
import { OutputConfigurationTaxClassificationComponent } from './configuration/tax-classification/tax-classification.component';
import { OutputConfigurationTaxClassificationEditComponent } from './configuration/tax-classification/edit/edit.component';
import { OutputNisBillingViewApplyComponent } from './nis-billing/view-apply/view-apply.component';
import { OutputRuleApplyComponent } from './rule/apply/apply.component';
import { OutputRuleAddSpecialGoodsComponent } from './rule/add-special-goods/add-special-goods.component';
import { OutputNisBillingRelatedInvoiceComponent } from './nis-billing/related-invoice/related-invoice.component';

const COMPONENTS = [
  OutputNISBillingComponent,
  OutputJDEBillingComponent,
  OutputPrefabricatedInvoiceComponent,
  OutputInvoicedComponent,
  OutputCreditNoteComponent,
  OutputNoCreditNoteComponent,
  OutputRuleComponent,
  OutputCommodityComponent,
  OutputProductComponent,
  OutputRuleViewRuleComponent,
  OutputCommodityEditComponent,
  OutputCommodityViewComponent,
  OutputConfigurationCorporateCustomerRulesComponent,
  OutputConfigurationCorporateCustomerRulesViewComponent,
  OutputConfigurationCorporateCustomerRulesEditComponent,
  OutputInvoiceInAdvanceComponent,
  OutputPrefabricatedInvoicePendingInvoicingComponent,
  OutputNoCreditNoteInvoicedListComponent,
  OutputConfigurationInvoiceInventoryComponent,
  OutputConfigurationSubjectComponent,
  OutputConfigurationTaxClassificationComponent];
const COMPONENTS_NOROUNT = [
  OutputNisBillingOrderConfirmationComponent,
  OutputNisBillingExcessRemindComponent,
  OutputNisBillingMergerInvoiceComponent,
  OutputNisBillingMakeAnInvoiceComponent,
  OutputNisBillingEditComponent,
  OutputNisBillingViewComponent,
  OutputPrefabricatedInvoiceEditComponent,
  OutputPrefabricatedInvoiceViewComponent,
  OutputPrefabricatedInvoiceApprovalComponent,
  OutputRuleEditInfoComponent,
  OutputRuleEditPriceComponent,
  OutputRuleEditCommodityComponent,
  OutputNisBillingViewInvoiceComponent,
  OutputJdeBillingApplyForBillingComponent,
  OutputInvoiceInAdvanceWriteOffComponent,
  OutputJdeBillingViewComponent,
  OutputInvoiceInAdvanceApplyForBillingComponent,
  OutputInvoiceInAdvanceViewComponent,
  OutputJdeBillingRejectComponent,
  OutputInvoicedManualRedPushComponent,
  OutputInvoicedRelatedOrdersComponent,
  OutputInvoicedInvalidComponent,
  OutputInvoicedPrintConfirmationComponent,
  OutputConfigurationInvoiceInventoryEditComponent,
  OutputConfigurationInvoiceInventorySetAlarmRuleComponent,
  OutputConfigurationSubjectEditComponent,
  OutputConfigurationTaxClassificationEditComponent,
  OutputNisBillingViewApplyComponent,
  OutputRuleApplyComponent,
  OutputRuleAddSpecialGoodsComponent,
  OutputNisBillingRelatedInvoiceComponent];

@NgModule({
  imports: [
    SharedModule,
    OutputRoutingModule
  ],
  declarations: [
    ...COMPONENTS,
    ...COMPONENTS_NOROUNT
  ],
  entryComponents: COMPONENTS_NOROUNT
})
export class OutputModule { }
