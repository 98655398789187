import { Component, OnInit } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@env/environment';

@Component({
  selector: 'app-input-expense-account-invoice-view',
  templateUrl: './invoice-view.component.html',
  styleUrls: ['./invoice-view.component.less'],
})
export class InputExpenseAccountInvoiceViewComponent implements OnInit {
  loading = false;
  record: any = {};
  i: any;
  invoiceImage: any = ''; // 图片路径
  type: any = ''; // 发票图片类型pdf、jpg

  constructor(
    private modal: NzModalRef,
    public msgSrv: NzMessageService,
    public http: _HttpClient,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    console.log('param',this.i)
    if (this.i.imageUrl) {
      let last = this.i.imageUrl.replace(/.*\./, "");
      if(last.indexOf("?") != -1){
        this.type = last.substring(0,last.indexOf("?"));
      }else{
        this.type = last;
      }
      const imageUrl = environment.FILE_BASE_URL + '/' + this.i.imageUrl;
      this.invoiceImage = this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
    } else if (this.i.invoiceImage) {
      let last = this.i.invoiceImage.replace(/.*\./, "");
      if(last.indexOf("?") != -1){
        this.type = last.substring(0,last.indexOf("?"));
      }else{
        this.type = last;
      }
      const imageUrl = environment.FILE_BASE_URL + '/' + this.i.invoiceImage;
      this.invoiceImage = this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
    }
  }

  close() {
    this.modal.destroy();
  }
}
