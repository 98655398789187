import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { DialogService } from 'src/app/service/dialog.service';
import * as format from 'date-fns/format';

@Component({
  selector: 'app-input-expense-account-manual-input',
  templateUrl: './manual-input.component.html',
})
export class InputExpenseAccountManualInputComponent implements OnInit {
  i: any;
  loading = false;
  vatInvoiceEntityOption = [
    { value: '专用发票', label: '专用发票' },
    { value: '普通发票', label: '普通发票' },
  ]
  // 非增值税发票类型-进行种类配置中的发票类型列表
  vetInvoiceEntityOption = [
    { value: '', label: '' },
  ]
  // 增值税
  data: any = {
    "invoiceCode": "",
    "invoiceNumber": "",
    "expenseNo": "",
    "invoiceType": "",
    "invoiceTotalPrice": "",
  }
  // 非增值税
  unformatData: any = {
    "amount": "",
    "issueDate": "",
    "expenseNo": "",
    "unformatType": "",
    "invoiceType": "",
  }
  expenseNumber = ''; // 报销单号
  type = 'unformat'; // 票据种类

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    if (this.i.expenseNumber) {
      this.expenseNumber = this.i.expenseNumber;
    }
    if (this.i.id) {
      this.data.expenseNo = this.i.id;
      this.unformatData.expenseNo = this.i.id;
    }
    this.getUnformateInvoiceTypeList();
  }

  // 手工录入信息保存
  save() {
    let params = {};
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.type === 'vat') {
      params = this.data;
    } else {
      this.unformatData.invoiceType = this.unformatData.unformatType;
      params = this.unformatData;
    }
    this.loading = true;
    this.http.post('/input/unformatinvoice/saveManualInvoice', params).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('操作成功');
        this.modal.close(true);
      } else {
        this.modal.close(false);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 获取非增值税发票列表
  getUnformateInvoiceTypeList() {
    this.loading = true;
    this.http.get('/input/invoice/category/alllist').subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.vetInvoiceEntityOption = data.list;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 开票日期事件
  invoiceCreateDateChange(event) {
    this.unformatData.issueDate = event ? format(event, 'YYYY-MM-DD HH:mm:ss') : '';
  }

  close() {
    this.modal.destroy();
  }
}
