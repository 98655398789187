import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { STComponent, STColumn } from '@delon/abc';

interface Purchaser {
  name: string
  identifier: string
  address?: string
  phone?: string
  bank?: string
  account?: string
}

interface Seller {
  name: string
  identifier: string
  address: string
  phone: string
  bank: string
  province: string
  account: string
  accountants: {
    payee: string
    drawer: string
    auditor: string
  }
}

interface Product {
  amount: string
  cno: string
  productName: string
  productNo: string
  quantity: number
  rate: string
  specifications: string
  tax: string
  discount: string
  unit: string
  unitPrice: string
  isDiscount: boolean
}

interface InvoiceDetail {
  vatTotal: number
  invoiceAmount: number
  priceTotalExcludingVat: number
  products: Product[]
}

interface Invoice {
  purchaser: Purchaser
  seller: Seller
  invoiceDetail: InvoiceDetail
}

@Component({
  selector: 'app-output-prefabricated-invoice-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less'],
})
export class OutputPrefabricatedInvoiceEditComponent implements OnInit {
  record: any = {};
  i: any;
  selectedRows: any = []; // 订单列表
  sumAmount = 0; // 订单总额
  singelAmount = 0; // 单张发票金额上限
  isJDE = false; // 开具方式
  saleTabs: any[] = [{ key: '基本信息', id: 1, show: true }, { key: '发票明细', id: 2 }, { key: '订单明细', id: 3 }, { key: '审批流程', id: 4 }];
  taxStatus = [
    { value: '1', label: "一般纳税人" },
    { value: '2', label: "企业纳税人" },
  ]
  invoiceStatus = [
    { value: '1', label: "专票" },
    { value: '2', label: "普通发票" },
  ]
  invoiceType = [
    { value: '1', label: "纸质发票" },
    { value: '2', label: "电子发票" },
  ]
  data: any = {
    "taxStatus": "1",
    "invoiceStatus": "1",
    "invoiceType": "1",
    "isTaxable": false,
    "isDiscount": true,
    "discountRole": 2,
    "discountType": 1,
  }
  invoiceInfo: Invoice = {
    purchaser: {
      name: '北京盛安德科技发展有限公司',
      identifier: '91110102X00384294D',
      address: '',
      phone: '',
      bank: '',
      account: ''
    },
    seller: {
      name: '北京盛安德科技发展有限公司',
      identifier: '91110102X00384294D',
      address: '北京市西城区新德街甲20号楼天岳恒大厦705号',
      phone: '010-82084356',
      bank: '北京市XX银行西城分行',
      province: '北京',
      account: '312467687453434',
      accountants: {
        payee: '张三',
        drawer: '李四吴',
        auditor: '王五陆'
      }
    },
    invoiceDetail: {
      invoiceAmount: 0,
      vatTotal: 0,
      priceTotalExcludingVat: 0,
      products: [
        {
          amount: "",
          cno: "",
          productName: "",
          productNo: "",
          quantity: 0,
          rate: "",
          specifications: "",
          tax: "",
          discount: "",
          unit: "",
          unitPrice: "",
          isDiscount: true
        }
      ]
    }
  };
  invoiceArr: Invoice[];
  selectedInvoice: Invoice;

  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '订单编号', index: 'code', render: 'no' },
    { title: '订单日期', type: 'date', index: 'createdAt', dateFormat: 'YYYYMMDD' },
    { title: '购方名称', index: 'buyName' },
    { title: '销方名称', index: 'sellName' },
    { title: '总金额（不含税）', index: 'totalAmount' },
    { title: '税额', index: 'taxAmount' },
    { title: '折扣方式', index: 'discountMode' },
    { title: '折扣总额', index: 'discountAmount' },
    { title: '合计', index: 'totalAmount' },
    { title: '票据类型', index: 'billType' },
    { title: '订单状态', index: 'orderStatus' },
  ];

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.selectedRows.push(this.i);
    this.sumAmount = this.i.totalAmount;
    // 获取发票信息
    this.invoiceInfo.invoiceDetail = this.getInvoiceDetail();
    this.invoiceArr = [this.invoiceInfo];
    this.selectedInvoice = this.invoiceArr[0];
    // 判断是否JDE开票
    if (this.i.billingMethod === 'JDE手工') {
      this.isJDE = true;
      this.saleTabs = [{ key: '基本信息', id: 1, show: true }, { key: '发票明细', id: 2 }, { key: '审批流程', id: 4 }];
    }
  }

  // 提交审核操作
  approval() {
    this.close();
    this.msgSrv.success('信息审核提交成功');
  }

  // tab切换事件
  salesChange(idx: number) {
    if (this.saleTabs[idx].show !== true) {
      this.saleTabs[idx].show = true;
      this.cdr.detectChanges();
    }
  }

  // 获取发票详细信息
  private getInvoiceDetail(): InvoiceDetail {
    let priceTotalExcludingVat = 0;
    let vatTotal = 0;
    let products = [];
    this.selectedRows.forEach(item => {
      priceTotalExcludingVat += (+item.noTaxAmount);
      vatTotal += (+item.taxAmount);
      item.childList.forEach(p => p.isDiscount = item.isDiscount);
      products = [...products, ...item.childList];
    });
    return {
      priceTotalExcludingVat,
      vatTotal,
      invoiceAmount: priceTotalExcludingVat + vatTotal,
      products,
    };
  }

  // 更改发票价格
  updatePrice(product: Product, $event): void {
    $event.stopPropagation();
    const value = +($event.target.innerText.replace(/,/g, ''));
    this.invoiceInfo.invoiceDetail.products.forEach(item => {
      if (item.cno === product.cno) {
        item.quantity = (+product.amount) / value
      }
    })
  }

  // 发票选中事件
  selected(invoice, $event): void {
    $event.stopPropagation();
    this.selectedInvoice = invoice;
  }

  close() {
    this.modal.destroy();
  }
}
