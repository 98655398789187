import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import * as format from 'date-fns/format';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-input-expense-account-invoice-edit',
  templateUrl: './invoice-edit.component.html',
})
export class InputExpenseAccountInvoiceEditComponent implements OnInit {
  i: any;
  loading = false;
  data: any = {
    "id": "",
    "amount": "",
    "createTime": "",
  }

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    if (this.i) {
      this.data.id = this.i.id;
      this.data.amount = this.i.amount;
      this.data.createTime = this.i.createTime;
    }
  }

  save() {
    this.loading = true;
    this.http.post('/input/unformatinvoice/update', this.data).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.modal.close(true);
      } else {
        this.modal.close(false);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 创建时间处理事件
  createTimeChange(event) {
    this.data.createTime = event ? format(event, 'YYYY-MM-DD HH:mm:ss') : '';
  }

  close() {
    this.modal.destroy();
  }
}
