import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STData } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { NzModalRef, NzMessageService, NzModalService } from 'ng-zorro-antd';
import { InputPurchaseOrderThreeSingleMatchesMatchResultComponent } from '../match-result/match-result.component';

@Component({
  selector: 'app-input-purchase-order-three-single-matches-material-match',
  templateUrl: './material-match.component.html',
  styleUrls: ['./material-match.component.less'],
})
export class InputPurchaseOrderThreeSingleMatchesMaterialMatchComponent implements OnInit {
  i: any;
  saleTabs: any[] = [{ key: 'unMatched', id: 1 }, { key: 'matched', id: 2 }];
  index = 0; // 显示当前tab标号
  // 待匹配物料-发票物料单信息
  invoiceSelectedRows: STData[] = []; // 选中数据
  unMachedInvoiceRows: any = [
    { 'id': '1', 'invoiceNumber': '02241877_1', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '23395.221', 'sphSl': '66.18', 'sphSlv': '13', 'sphSe': '12317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'status': '匹配失败', 'isAvailable': true },
    { 'id': '2', 'invoiceNumber': '02241877_1', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '395.221', 'sphSl': '6.18', 'sphSlv': '13', 'sphSe': '317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'status': '待匹配', 'isAvailable': true },
    { 'id': '3', 'invoiceNumber': '02241877_2', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '395.221', 'sphSl': '6.18', 'sphSlv': '13', 'sphSe': '317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'status': '待匹配', 'isAvailable': true },
    { 'id': '4', 'invoiceNumber': '02241877_2', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '395.221', 'sphSl': '6.18', 'sphSlv': '13', 'sphSe': '317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'status': '匹配失败', 'isAvailable': true },
    { 'id': '5', 'invoiceNumber': '02241877_3', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '395.221', 'sphSl': '6.18', 'sphSlv': '13', 'sphSe': '317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'status': '待匹配', 'isAvailable': true },
    { 'id': '6', 'invoiceNumber': '02241878_4', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '395.221', 'sphSl': '6.18', 'sphSlv': '13', 'sphSe': '317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'status': '待匹配', 'isAvailable': true },
    { 'id': '7', 'invoiceNumber': '02241878_5', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '395.221', 'sphSl': '6.18', 'sphSlv': '13', 'sphSe': '317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'status': '匹配失败', 'isAvailable': true },
    { 'id': '8', 'invoiceNumber': '', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '395.221', 'sphSl': '6.18', 'sphSlv': '13', 'sphSe': '317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'status': '', 'isAvailable': false },
    { 'id': '9', 'invoiceNumber': '', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '395.221', 'sphSl': '6.18', 'sphSlv': '13', 'sphSe': '317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'status': '', 'isAvailable': false },
    { 'id': '10', 'invoiceNumber': '', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '395.221', 'sphSl': '6.18', 'sphSlv': '13', 'sphSe': '317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'status': '', 'isAvailable': false },
  ];
  @ViewChild('unMachedInvoiceSt', { static: false }) unMachedInvoiceSt: STComponent;
  unMachedInvoiceColumns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '发票号_行号', index: 'invoiceNumber', render: 'invoiceNumber', i18n: 'input.materialMatch.invoiceNumber', width: 150 },
    { title: '货物描述', index: 'sphSpmc', i18n: 'input.materialMatch.sphSpmc', width: 100 },
    { title: '规格型号', index: 'sphGgxh', i18n: 'input.invoiceInfo.sphGgxh', width: 100 },
    { title: '单位', index: 'sphJldw', i18n: 'input.invoiceInfo.sphJldw', width: 50 },
    { title: '不含税单价', index: 'sphDj', i18n: 'input.materialMatch.sphDj', width: 200 },
    { title: '数量', index: 'sphSl', i18n: 'input.invoiceInfo.sphSl', width: 50 },
    { title: '税率', index: 'sphSlv', i18n: 'input.invoiceInfo.sphSlv', width: 100 },
    { title: '税额', index: 'sphSe', i18n: 'input.invoiceInfo.sphSe', width: 50 },
    { title: '不含税金额', index: 'sphJe', i18n: 'input.materialMatch.sphJe', width: 200 },
    { title: '含税金额', index: 'materialTotalPrice', i18n: 'input.materialMatch.materialTotalPrice', width: 100 },
    { title: '状态', index: 'status', render: 'status', i18n: 'input.materialMatch.status', fixed: 'right', width: 100 },
  ];
  unMatchedInvoiceQueryParams = {
    page: 1,
    limit: 10,
    invoiceNumber: '',
  }
  invoiceTotalPrice = 0;

  // 待匹配物料-收货单物料数据
  receiptSelectedRows: STData[] = []; // 选中数据
  unMachedReceiptRows: any = [
    { 'id': '1', 'mblnr': '5003121582', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '23395.221', 'sphSl': '66.18', 'sphSlv': '13', 'sphSe': '12317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'irzterm': '', 'cmzterm': 'D045', 'zzresult': 'A', 'zkkje': '18690.00', 'zzkkbl': '13', 'prdat': '2019-09-24', 'zzcontract': 'Y34343', 'ekgrp': 'GD3', '': '说明', 'status': '待匹配' },
    { 'id': '2', 'mblnr': '5003121582', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '23395.221', 'sphSl': '66.18', 'sphSlv': '13', 'sphSe': '12317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'irzterm': '', 'cmzterm': 'D045', 'zzresult': 'A', 'zkkje': '0.00', 'zzkkbl': '0', 'prdat': '2019-09-24', 'zzcontract': 'Y34343', 'ekgrp': 'GD3', '': '说明', 'status': '已匹配' },
    { 'id': '3', 'mblnr': '5003121582', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '23395.221', 'sphSl': '66.18', 'sphSlv': '13', 'sphSe': '12317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'irzterm': '', 'cmzterm': 'D045', 'zzresult': 'A', 'zkkje': '0.00', 'zzkkbl': '0', 'prdat': '2019-09-24', 'zzcontract': 'Y34343', 'ekgrp': 'GD3', '': '说明', 'status': '待匹配' },
    { 'id': '4', 'mblnr': '5003121582', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '23395.221', 'sphSl': '66.18', 'sphSlv': '13', 'sphSe': '12317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'irzterm': '', 'cmzterm': 'D045', 'zzresult': 'A', 'zkkje': '0.00', 'zzkkbl': '0', 'prdat': '2019-09-24', 'zzcontract': 'Y34343', 'ekgrp': 'GD3', '': '说明', 'status': '待匹配' },
    { 'id': '5', 'mblnr': '5003121582', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '23395.221', 'sphSl': '66.18', 'sphSlv': '13', 'sphSe': '12317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'irzterm': '', 'cmzterm': 'D045', 'zzresult': 'A', 'zkkje': '0.00', 'zzkkbl': '0', 'prdat': '2019-09-24', 'zzcontract': 'Y34343', 'ekgrp': 'GD3', '': '说明', 'status': '已匹配' },
    { 'id': '6', 'mblnr': '5003121582', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '23395.221', 'sphSl': '66.18', 'sphSlv': '13', 'sphSe': '12317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'irzterm': '', 'cmzterm': 'D045', 'zzresult': 'A', 'zkkje': '0.00', 'zzkkbl': '0', 'prdat': '2019-09-24', 'zzcontract': 'Y34343', 'ekgrp': 'GD3', '': '说明', 'status': '待匹配' },
    { 'id': '7', 'mblnr': '5003121582', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '23395.221', 'sphSl': '66.18', 'sphSlv': '13', 'sphSe': '12317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'irzterm': '', 'cmzterm': 'D045', 'zzresult': 'A', 'zkkje': '0.00', 'zzkkbl': '0', 'prdat': '2019-09-24', 'zzcontract': 'Y34343', 'ekgrp': 'GD3', '': '说明', 'status': '待匹配' },
    { 'id': '8', 'mblnr': '5003121582', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '23395.221', 'sphSl': '66.18', 'sphSlv': '13', 'sphSe': '12317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'irzterm': '', 'cmzterm': 'D045', 'zzresult': 'A', 'zkkje': '0.00', 'zzkkbl': '0', 'prdat': '2019-09-24', 'zzcontract': 'Y34343', 'ekgrp': 'GD3', '': '说明', 'status': '待匹配' },
    { 'id': '9', 'mblnr': '5003121582', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '23395.221', 'sphSl': '66.18', 'sphSlv': '13', 'sphSe': '12317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'irzterm': '', 'cmzterm': 'D045', 'zzresult': 'A', 'zkkje': '0.00', 'zzkkbl': '0', 'prdat': '2019-09-24', 'zzcontract': 'Y34343', 'ekgrp': 'GD3', '': '说明', 'status': '待匹配' },
    { 'id': '10', 'mblnr': '5003121582', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '23395.221', 'sphSl': '66.18', 'sphSlv': '13', 'sphSe': '12317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'irzterm': '', 'cmzterm': 'D045', 'zzresult': 'A', 'zkkje': '0.00', 'zzkkbl': '0', 'prdat': '2019-09-24', 'zzcontract': 'Y34343', 'ekgrp': 'GD3', '': '说明', 'status': '待匹配' },
  ]
  @ViewChild('unMachedReceiptSt', { static: false }) unMachedReceiptSt: STComponent;
  unMachedReceiptColumns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '物料凭证号', index: 'mblnr', i18n: 'input.materialMatch.mblnr', width: 150 },
    { title: '货物描述', index: 'sphSpmc', i18n: 'input.materialMatch.sphSpmc', width: 100 },
    { title: '规格型号', index: 'sphGgxh', i18n: 'input.invoiceInfo.sphGgxh', width: 100 },
    { title: '单位', index: 'sphJldw', i18n: 'input.invoiceInfo.sphJldw', width: 50 },
    { title: '不含税单价', index: 'sphDj', i18n: 'input.materialMatch.sphDj', width: 200 },
    { title: '数量', index: 'sphSl', i18n: 'input.invoiceInfo.sphSl', width: 50 },
    { title: '税率', index: 'sphSlv', i18n: 'input.invoiceInfo.sphSlv', width: 100 },
    { title: '税额', index: 'sphSe', i18n: 'input.invoiceInfo.sphSe', width: 50 },
    { title: '不含税金额', index: 'sphJe', i18n: 'input.materialMatch.sphJe', width: 200 },
    { title: '含税金额', index: 'materialTotalPrice', i18n: 'input.materialMatch.materialTotalPrice', width: 100 },
    { title: '质检结果', index: 'zzresult', i18n: 'input.materialMatch.zzresult', width: 200 },
    { title: '扣款金额', index: 'zkkje', i18n: 'input.materialMatch.zkkje', width: 200 },
    { title: '扣款比例', index: 'zzkkbl', i18n: 'input.materialMatch.zzkkbl', width: 200 },
    { title: '交货日期', index: 'prdat', i18n: 'input.materialMatch.prdat', width: 100 },
    { title: '合同编号', index: 'zzcontract', i18n: 'input.materialMatch.zzcontract', width: 100 },
    { title: '采购组', index: 'ekgrp', i18n: 'input.materialMatch.ekgrp', width: 100 },
    { title: '状态', index: 'status', render: 'status', i18n: 'input.materialMatch.status', fixed: 'right', width: 100 },
  ];
  unMatchedReceiptQueryParams = {
    page: 1,
    limit: 10,
    mblnr: '',
  }
  receiptTotalPrice = 0;

  // 已匹配物料-发票信息
  machedInvoiceRows: any = [
    { 'id': '1', 'invoiceNumber': '02241877_1', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '23395.221', 'sphSl': '66.18', 'sphSlv': '13', 'sphSe': '12317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'status': '匹配失败' },
    { 'id': '2', 'invoiceNumber': '02241877_1', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '395.221', 'sphSl': '6.18', 'sphSlv': '13', 'sphSe': '317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'status': '待匹配' },
  ];
  @ViewChild('machedInvoiceSt', { static: false }) machedInvoiceSt: STComponent;
  machedInvoiceColumns: STColumn[] = [
    { title: '发票号_行号', index: 'invoiceNumber', i18n: 'input.materialMatch.invoiceNumber', width: 150 },
    { title: '货物描述', index: 'sphSpmc', i18n: 'input.materialMatch.sphSpmc', width: 100 },
    { title: '规格型号', index: 'sphGgxh', i18n: 'input.invoiceInfo.sphGgxh', width: 100 },
    { title: '单位', index: 'sphJldw', i18n: 'input.invoiceInfo.sphJldw', width: 50 },
    { title: '不含税单价', index: 'sphDj', i18n: 'input.materialMatch.sphDj', width: 200 },
    { title: '数量', index: 'sphSl', i18n: 'input.invoiceInfo.sphSl', width: 50 },
    { title: '税率', index: 'sphSlv', i18n: 'input.invoiceInfo.sphSlv', width: 100 },
    { title: '税额', index: 'sphSe', i18n: 'input.invoiceInfo.sphSe', width: 50 },
    { title: '不含税金额', index: 'sphJe', i18n: 'input.materialMatch.sphJe', width: 200 },
    { title: '含税金额', index: 'materialTotalPrice', i18n: 'input.materialMatch.materialTotalPrice', width: 100 },
    { title: '状态', index: 'status', render: 'status', i18n: 'input.materialMatch.status', fixed: 'right', width: 100 },
  ];
  // 已匹配物料-收货单物料信息
  machedReceiptRows: any = [
    { 'id': '1', 'mblnr': '5003121582', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '23395.221', 'sphSl': '66.18', 'sphSlv': '13', 'sphSe': '12317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'irzterm': '', 'cmzterm': 'D045', 'zzresult': 'A', 'zkkje': '18690.00', 'zzkkbl': '13', 'prdat': '2019-09-24', 'zzcontract': 'Y34343', 'ekgrp': 'GD3', '': '说明', 'status': '待匹配' },
    { 'id': '2', 'mblnr': '5003121582', 'sphSpmc': '*塑料制品*BT2712中包袋（181025-2版）', 'sphGgxh': '853*620*0.05mm', 'sphJldw': '千只', 'sphDj': '23395.221', 'sphSl': '66.18', 'sphSlv': '13', 'sphSe': '12317.52', 'sphJe': '2442.47', 'materialTotalPrice': '2759.99', 'irzterm': '', 'cmzterm': 'D045', 'zzresult': 'A', 'zkkje': '0.00', 'zzkkbl': '0', 'prdat': '2019-09-24', 'zzcontract': 'Y34343', 'ekgrp': 'GD3', '': '说明', 'status': '已匹配' },
  ]
  @ViewChild('machedReceiptSt', { static: false }) machedReceiptSt: STComponent;
  machedReceiptColumns: STColumn[] = [
    { title: '物料凭证号', index: 'mblnr', i18n: 'input.materialMatch.mblnr', width: 150 },
    { title: '货物描述', index: 'sphSpmc', i18n: 'input.materialMatch.sphSpmc', width: 100 },
    { title: '规格型号', index: 'sphGgxh', i18n: 'input.invoiceInfo.sphGgxh', width: 100 },
    { title: '单位', index: 'sphJldw', i18n: 'input.invoiceInfo.sphJldw', width: 50 },
    { title: '不含税单价', index: 'sphDj', i18n: 'input.materialMatch.sphDj', width: 200 },
    { title: '数量', index: 'sphSl', i18n: 'input.invoiceInfo.sphSl', width: 50 },
    { title: '税率', index: 'sphSlv', i18n: 'input.invoiceInfo.sphSlv', width: 100 },
    { title: '税额', index: 'sphSe', i18n: 'input.invoiceInfo.sphSe', width: 50 },
    { title: '不含税金额', index: 'sphJe', i18n: 'input.materialMatch.sphJe', width: 200 },
    { title: '含税金额', index: 'materialTotalPrice', i18n: 'input.materialMatch.materialTotalPrice', width: 100 },
    { title: '质检结果', index: 'zzresult', i18n: 'input.materialMatch.zzresult', width: 200 },
    { title: '扣款金额', index: 'zkkje', i18n: 'input.materialMatch.zkkje', width: 200 },
    { title: '扣款比例', index: 'zzkkbl', i18n: 'input.materialMatch.zzkkbl', width: 200 },
    { title: '交货日期', index: 'prdat', i18n: 'input.materialMatch.prdat', width: 100 },
    { title: '合同编号', index: 'zzcontract', i18n: 'input.materialMatch.zzcontract', width: 100 },
    { title: '采购组', index: 'ekgrp', i18n: 'input.materialMatch.ekgrp', width: 100 },
    { title: '状态', index: 'status', render: 'status', i18n: 'input.materialMatch.status', fixed: 'right', width: 100 },
  ];

  matchFlag = false; // 匹配结果
  matchedFlag = false; // 匹配按钮显示
  show: any = [true, true, true];

  constructor(
    private http: _HttpClient,
    private modal: NzModalRef,
    private modalHelper: ModalHelper,
    private msgSrv: NzMessageService,
    private modalSrv: NzModalService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    // 处理当前tab显示
    if (this.i.ptype === 0) {
      this.index = 0;
      this.saleTabs[0].show = true;
      this.matchedFlag = true;
    } else {
      this.saleTabs[1].show = true;
      this.index = 1;
    }
    this.cdr.detectChanges();
  }

  // 获取未分配物料-发票列表
  getUnmatchedInvoiceList() {

  }

  // 获取未分配物料-收货列表
  getUnmatchedReceiptList() {

  }

  // 设置匹配
  match() {
    if (this.invoiceSelectedRows.length > 0 && this.receiptSelectedRows.length > 0) {
      this.close();
      this.matchFlag = true;
      this.modalHelper.static(InputPurchaseOrderThreeSingleMatchesMatchResultComponent, { i: { ptype: this.matchFlag } }, 800).subscribe((success) => {
      });
    } else {
      this.modalSrv.warning({
        nzTitle: '请选择发票物料单和收货物料单',
        nzWrapClassName: 'vertical-center-modal',
      });
    }

  }

  // 解除匹配
  remove(index) {
    this.show[index] = false;
    this.msgSrv.success('解除成功');
  }

  // tab切换事件
  salesChange(idx: number) {
    if (this.saleTabs[idx].show !== true) {
      this.saleTabs[idx].show = true;
      this.cdr.detectChanges();
    }
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.saleTabs[idx].id === 1) {
      this.matchedFlag = true;
    } else {
      this.matchedFlag = false;
    }
  }

  unMatchedSearch() {

  }

  // 待匹配物料-发票物料单数据无效多选框处理
  unmatchedDataChange(data: STData[]) {
    return data.map((i: STData, index: number) => {
      i.disabled = !i.isAvailable;
      return i;
    });
  }

  // 待匹配物料-发票物料单无效数据样式处理
  rowClassName(record: STData, index: number) {
    if (record.isAvailable === false) {
      return 'unavailable-tr';
    }
  }

  // 待匹配物料-发票物料分页
  unmatchedInvoicePagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.unMatchedInvoiceQueryParams.page !== event.pi) {
        this.unMatchedInvoiceQueryParams.page = event.pi;
        this.getUnmatchedInvoiceList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.unMatchedInvoiceQueryParams.limit !== event.ps) {
        this.unMatchedInvoiceQueryParams.limit = event.ps;
        this.unMatchedInvoiceQueryParams.page = 1;
        this.getUnmatchedInvoiceList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.invoiceSelectedRows = event.checkbox!;
      this.invoiceTotalPrice = this.invoiceSelectedRows.reduce((total, cv) => total + parseInt(cv.materialTotalPrice, 10), 0);
    }
  }

  // 待匹配物料-收货物料分页
  unmatchedReceiptPagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.unMatchedReceiptQueryParams.page !== event.pi) {
        this.unMatchedReceiptQueryParams.page = event.pi;
        this.getUnmatchedReceiptList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.unMatchedReceiptQueryParams.limit !== event.ps) {
        this.unMatchedReceiptQueryParams.limit = event.ps;
        this.unMatchedReceiptQueryParams.page = 1;
        this.getUnmatchedReceiptList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.receiptSelectedRows = event.checkbox!;
      this.receiptTotalPrice = this.receiptSelectedRows.reduce((total, cv) => total + parseInt(cv.materialTotalPrice, 10), 0);
    }
  }

  close() {
    this.modal.destroy();
  }

}
