import { enableProdMode, ViewEncapsulation } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { preloaderFinished } from '@delon/theme';
preloaderFinished();

import { hmrBootstrap } from './hmr';

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => {
  return platformBrowserDynamic()
    .bootstrapModule(AppModule, {
      defaultEncapsulation: ViewEncapsulation.Emulated,
    })
    .then(res => {
      if ((window as any).appBootstrap) {
        (window as any).appBootstrap();
      }
      return res;
    });
};

if (environment.hmr) {
  // tslint:disable-next-line: no-string-literal
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  bootstrap();
}

// 处理classList报错找不到remove属性
if (!("classList" in document.documentElement)) {
  Object.defineProperty(HTMLElement.prototype, 'classList', {
    get() {
      const self = this;
      const update = (fn) => {
        return (value) => {
          const classes = self.className.split(/\s+/g);
          const index = classes.indexOf(value);

          fn(classes, index, value);
          self.className = classes.join(" ");
        }
      }

      return {
        add: update((classes, index, value) => {
          if (index <= -1) classes.push(value);
        }),

        remove: update((classes, index) => {
          if (index > -1) classes.splice(index, 1);
        }),

        toggle: update((classes, index, value) => {
          index > -1 ? classes.splice(index, 1) : classes.push(value);
        }),

        contains(value) {
          return !!(self.className.split(/\s+/g).indexOf(value) > -1);
        },

        item(i) {
          return self.className.split(/\s+/g)[i] || null;
        }
      };
    }
  });
}
