import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-input-transfer-out-rule-scope-edit',
  templateUrl: './edit.component.html',
})
export class InputTransferOutRuleScopeEditComponent implements OnInit {
  loading = false;
  data: any = {
    labelName: '',
    labelLevel: 1,
    labelFalg: 1,
    inquireFalg: '5',
  }
  constructor(
    private http: _HttpClient,
    private modal: NzModalRef,
    public msg: NzMessageService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
  ) { }

  ngOnInit() { }

  // 新增标签
  add() {
    this.loading = true;
    this.http.put('/input/label/saveLabel', this.data).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msg.success('保存成功');
        this.cdr.detectChanges();
        this.modal.close(true);
      } else {
        this.modal.close(false);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  close() {
    this.modal.destroy();
  }
}
