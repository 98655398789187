import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-input-configuration-type-edit',
  templateUrl: './type-edit.component.html',
})
export class InputConfigurationTypeEditComponent implements OnInit {
  record: any = {};
  i: any;
  ptype = 0; // 页面类型，默认0新增;1编辑
  loading = false;
  data: any = {
    'id': '',
    'category': '',
  }

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    // 获取页面类型
    this.ptype = this.i.ptype;
    if (this.ptype === 1) {
      this.data.id = this.i.id;
      this.data.category = this.i.category;
    }
  }

  // 保存信息
  saveOrUpdate() {
    if (this.ptype === 0) {
      this.save();
    } else {
      this.update();
    }
  }

  // 保存
  save() {
    this.loading = true;
    this.http.put('/input/invoice/category/save', this.data).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('操作成功');
        this.modal.close(true);
      } else {
        this.modal.close(false);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 修改
  update() {
    this.loading = true;
    this.http.post('/input/invoice/category/update', this.data).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('操作成功');
        this.modal.close(true);
      } else {
        this.modal.close(false);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  close() {
    this.modal.destroy();
  }
}
