import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { SFSchema } from '@delon/form';

@Component({
  selector: 'app-input-configuration-invoice-verification',
  templateUrl: './invoice-verification.component.html',
  styles: [
    `
      :host ::ng-deep .ant-select {
    color: #7D7D7D;
    font-size: 14px;
}
    `,
  ],
})
export class InputConfigurationInvoiceVerificationComponent implements OnInit {
  loading = false;
  rows: any = [
    { 'id': 1, 'description': '成功，可进行匹配', 'action': '3' },
    { 'id': 2, 'description': '合同编号及付款条件未维护或不一致', 'action': '3' },
    { 'id': 3, 'description': '质检未通过', 'action': '3' },
    { 'id': 4, 'description': '物料凭证已被冲销', 'action': '3' },
    { 'id': 5, 'description': '物料凭证移动类型有误', 'action': '3' },
    { 'id': 6, 'description': '物料凭证不存在', 'action': '1' },
    { 'id': 7, 'description': '购方名称校验', 'action': '1' },
    { 'id': 8, 'description': '购方税号校验', 'action': '1' },
    { 'id': 9, 'description': '销方名称校验', 'action': '3' },
    { 'id': 10, 'description': '物料组和税收分类校验', 'action': '3' },
    { 'id': 10, 'description': '物料明细与商品名称+规格型号校验', 'action': '3' },
    { 'id': 10, 'description': '税率校验', 'action': '3' },
    { 'id': 10, 'description': '数量校验', 'action': '1' },
    { 'id': 10, 'description': '不含税金额校验', 'action': '1' },
    { 'id': 10, 'description': '含税金额', 'action': '3' },
  ];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '检验项', index: 'description' },
    { title: '状态', index: 'action', render: 'action' },
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 15,
  };
  // 状态列表
  actionAllOptions = [
    { value: '1', label: '不允许自动匹配，也不允许人工匹配' },
    { value: '2', label: '不校验' },
    { value: '3', label: '允许所有人手工匹配' },
  ]
  actionOptions = [
    { value: '1', label: '不允许自动匹配，也不允许人工匹配' },
    { value: '3', label: '允许所有人手工匹配' },
  ]

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper
  ) { }

  ngOnInit() { }

  // 获取列表
  getList() {

  }

}
