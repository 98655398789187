import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';

@Component({
  selector: 'app-input-transfer-out-welfare-edit',
  templateUrl: './edit.component.html',
})
export class InputTransferOutWelfareEditComponent implements OnInit {
  loading = false;
  i: any;
  ptype = 0;
  data: any = {
    id: '',
    ruleScope: '',
    taxFreeCode: '',
    taxFreeName: '',
    invoiceSellParagraph: '',
    materialCode: '',
  }
  ruleScopeOption = [
    { value: '按BU', label: '按BU' },
    { value: '按科目', label: '按科目' },
    { value: '按特殊商品', label: '按特殊商品' },
    { value: '按收入比例', label: '按收入比例' },
    { value: '按出口退税申报表', label: '按出口退税申报表' },
  ]; // 规则范围
  taxFreeCodeOption = [
    { value: '001', label: '001' },
    { value: '002', label: '002' },
    { value: '003', label: '003' },
    { value: '004', label: '004' },
    { value: '005', label: '005' },
  ]; // 免税规则分类编码
  taxFreeNameOption = [
    { value: '免税项目-出口货物材料采购', label: '免税项目-出口货物材料采购' },
    { value: '免税项目-进口木材材料采购', label: '免税项目-进口木材材料采购' },
    { value: '免税项目-个人福利', label: '免税项目-个人福利' },
    { value: '免税项目-进口材料采购', label: '免税项目-进口材料采购' },
    { value: '免税项目-进口货物采购', label: '免税项目-进口货物采购' },
  ]; // 免税规则分类名称


  constructor(
    private http: _HttpClient,
    private modal: NzModalRef,
    public msg: NzMessageService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.ptype = this.i.ptype! || 0;
    if (this.ptype === 1) {
      this.data = this.i.item;
    }
  }

  // 新增标签
  add() {
    this.msg.success('操作成功');
    this.cdr.detectChanges();
    this.modal.close(true);
  }

  close() {
    this.modal.destroy();
  }
}
