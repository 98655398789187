import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NzModalRef, NzMessageService, NzCascaderOption, NzFormatEmitEvent, NzTreeComponent, NzTreeSelectComponent } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-input-transfer-out-gift-edit',
  templateUrl: './edit.component.html',
})
export class InputTransferOutGiftEditComponent implements OnInit {
  @ViewChild('selectTree', null) selectTree: NzTreeSelectComponent;
  loading = false;
  i: any;
  ptype = 0;
  data: any = {
    labelName: '',
    labelLevel: 1, // 0-标签，1-其他
    labelAttribution: '',
    numbering: '',
    rangeId: '',
    deptId: '',
    labelFalg: 1,
    inquireFalg: '', // 1-免税，2-礼品，3-福利，4-科目，5-范围
  }
  ruleScopeOption = []; // 规则范围
  taxFreeCodeOption = [
    { value: '1001', label: '1001' },
    { value: '1002', label: '1002' },
    { value: '1003', label: '1003' },
    { value: '1004', label: '1004' },
    { value: '1005', label: '1005' },
  ]; // 免税规则分类编码
  labelOption: any = [];
  nzOptions: NzCascaderOption[] = [];


  constructor(
    private http: _HttpClient,
    private modal: NzModalRef,
    public msg: NzMessageService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.ptype = this.i.ptype! || 0;
    if (this.ptype === 1) {
      this.data = this.i.item;
      if (this.data.deptId) {
        this.data.deptId = this.data.deptId.toString() || '';
      }
    } else {
      this.data.inquireFalg = this.i.inquireFalg || '';
    }
    this.getRuleScopeList();
    this.getLabelList();
    this.getDeptList();
  }

  addOrUpdate() {
    this.ptype === 0 ? this.add() : this.update();
  }

  // 新增标签
  add() {
    this.loading = true;
    this.http.put('/input/label/saveLabel', this.data).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msg.success('操作成功');
        this.cdr.detectChanges();
        this.modal.close(true);
      } else {
        this.modal.close(false);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 更新
  update() {
    this.loading = true;
    this.http.put('/input/label/updateLabel', this.data).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msg.success('操作成功');
        this.cdr.detectChanges();
        this.modal.close(true);
      } else {
        this.modal.close(false);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 获取规则范围列表
  getRuleScopeList() {
    this.http.get('/input/label/findLabelList?inquireFalg=5').subscribe(data => {
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.ruleScopeOption = data.data;
      }
    }, error => {
      console.log('HTTP Error', error);
    });
  }

  // 获取标签列表
  getLabelList() {
    this.http.get('/input/label/findLabelList?inquireFalg=0').subscribe(data => {
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.labelOption = data.data;
      }
    }, error => {
      console.log('HTTP Error', error);
    });
  }

  // 获取公司列表
  getDeptList() {
    this.loading = true;
    this.http.get('/sys/dept/treeSelect').subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.msg === 'success') {
        if (res.data) {
          this.nzOptions = res.data;
          // 设置联动子叶标识
          this.treeList(this.nzOptions);
        }
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 树
  treeList(data) {
    if (data.length > 0) {
      for (const dept of data) {
        if (dept.children.length === 0) {
          dept.isLeaf = true;
        }
        if (dept.children.length > 0) {
          this.treeList(dept.children);
        }
      }
    }
  }

  // 所属部门选择事件
  deptChange(event: string): void {
    console.log(event);
    const node = this.selectTree.getTreeNodeByKey(event);
    if (node.origin) {
      this.data.deptName = node.origin.title || '';
      this.data.taxCode = node.origin.taxCode || '';
    }
    console.log('node::', node);

  }

  // 规则范围选择事件
  ruleSelectionChange(rule) {
    console.log('event1', rule);
    this.data.rangeId = rule.id;
    this.data.labelRange = rule.labelName;
  }

  // 标签选择事件
  labelSelectionChange(label) {
    console.log('event2', label);
    this.data.labelAttribution = label.id;
    this.data.attributionName = label.labelName;

  }

  close() {
    this.modal.destroy();
  }
}
