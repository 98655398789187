import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STData } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { InputConfigurationFaultTolerantEditComponent } from './edit/edit.component';

@Component({
  selector: 'app-input-configuration-fault-tolerant',
  templateUrl: './fault-tolerant.component.html',
})
export class InputConfigurationFaultTolerantComponent implements OnInit {
  loading = false;
  selectedRows: STData[] = []; // 选中数据
  rows: any = [
    { 'id': 1, 'fault': '300', 'unit': '元' },
  ];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '三单匹配容错', index: 'fault' },
    { title: '单位', index: 'unit' },
    {
      title: '操作',
      buttons: [
        {
          text: '编辑',
          click: (item: any) => this.update(item),
        },
      ]
    }
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
  };

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper
  ) { }

  ngOnInit() { }

  // 获取列表
  getList() {

  }

  // 修改
  update(item) {
    this.modal.static(InputConfigurationFaultTolerantEditComponent, { i: item }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      }
    });
  }


  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.selectedRows = event.checkbox!;
    }
  }

}
