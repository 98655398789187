import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STChange } from '@delon/abc';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import * as format from 'date-fns/format';
import { OutputNisBillingMergerInvoiceComponent } from '../nis-billing/merger-invoice/merger-invoice.component';
import { OutputNisBillingEditComponent } from '../nis-billing/edit/edit.component';
import { clientConfig } from './../../../../environments/client-config';
import { OutputJdeBillingApplyForBillingComponent } from './apply-for-billing/apply-for-billing.component';
import { OutputJdeBillingViewComponent } from './view/view.component';
import { DialogService } from 'src/app/service/dialog.service';
import { OutputInvoicedInvalidComponent } from '../invoiced/invalid/invalid.component';
import { OutputInvoicedPrintConfirmationComponent } from '../invoiced/print-confirmation/print-confirmation.component';

@Component({
  selector: 'app-output-jde-billing',
  templateUrl: './jde-billing.component.html',
  styleUrls: ['./jde-billing.component.less'],
})
export class OutputJDEBillingComponent implements OnInit {
  loading = false;
  show = false;
  more = 'down';
  viewStatus = [2, 4, 8, 9, 10, 13, 14, 16];
  editStatus = [3, 11, 15];
  reapplyStatus = [3, 4, 11, 15];
  revocationStatus = [2, 3, 10, 11, 14, 15];
  downloadStatus = [7, 9, 12, 13];
  invalidStatus = [7, 8, 9];
  moreStatus = [3, 7, 8, 9, 11, 15];
  rows: any[] = [];
  date: any = {}; // 搜索订单日期
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '发票代码', index: 'invoiceCode', i18n: 'output.st.invoiceCode', width: 100 },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'output.st.invoiceNumber', width: 100 },
    { title: '开票申请号', index: 'applyNumber', i18n: 'output.st.applyNumber', width: 120 },
    { title: '申请人', index: 'applyUser', i18n: 'output.st.applyUser', width: 100 },
    { title: '申请日期', index: 'applyTime', i18n: 'output.st.applyTime', type: 'date', dateFormat: 'YYYY-MM-DD', width: 120 },
    { title: '购方名称', index: 'purchaseName', i18n: 'output.st.purchaseName', width: 150 },
    { title: '销方名称', index: 'sellerName', i18n: 'output.st.sellerName', width: 150 },
    { title: '开票金额(不含税)', index: 'excludingTaxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'output.st.invoiceTaxAmount', width: 160 },
    { title: '税率', index: 'taxRate', i18n: 'output.st.taxRate', render: 'taxRate', width: 80 },
    { title: '税额', index: 'taxAmount', i18n: 'output.st.taxAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', width: 80 },
    { title: '价税合计', index: 'totalAmount', i18n: 'output.st.invoiceTotalAmount', type: 'number', numberDigits: '.2-2', className: 'text-left', width: 100 },
    { title: '开票日期', index: 'invoicingTime', i18n: 'output.st.invoicingTime', type: 'date', dateFormat: 'YYYY-MM-DD', width: 120 },
    { title: '业务类型', index: 'businessType', i18n: 'output.st.businessType', width: 100 },
    { title: '入账凭证号', index: 'accountingVouchers', i18n: 'output.st.accountingVouchers', width: 120 },
    { title: '发票类型', index: 'invoiceType', i18n: 'output.st.invoiceType', width: 100 },
    { title: '发票载体', index: 'invoiceEntity', i18n: 'output.st.invoiceEntity', width: 100 },
    { title: '发票状态', index: 'invoiceStatus', i18n: 'output.st.invoiceStatus', render: 'invoiceStatus', fixed: 'right', width: 100 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 200,
      buttons: [
        {
          text: '查看',
          i18n: 'st.operate.view',
          iif: record => this.viewStatus.includes(record.invoiceStatus),
          paramName: 'i',
          click: (item: any) => this.view(item),
        },
        {
          text: '开票',
          i18n: 'st.operate.invoicing',
          iif: record => record.invoiceStatus === 5,
          pop: true,
          popTitle: '确认要开具此发票吗？',
          click: (item: any) => this.invoicing(item.invoiceId),
        },
        {
          text: '撤销',
          i18n: 'st.operate.revocation',
          iif: record => this.revocationStatus.includes(record.invoiceStatus),
          paramName: 'i',
          pop: true,
          popTitle: '确认要撤销此发票吗？',
          click: (item: any) => this.revocation(item.invoiceId),
        },
        {
          text: '打印',
          i18n: 'st.operate.print',
          iif: record => record.invoiceStatus === 7 || record.invoiceStatus === 12,
          paramName: 'i',
          click: (item: any) => this.print(item),
        },
        {
          text: '下载',
          i18n: 'st.operate.download',
          iif: record => this.downloadStatus.includes(record.invoiceStatus),
          paramName: 'i',
          click: (item: any) => this.download(item.invoiceId),
        },
        {
          text: '重新开具',
          i18n: 'st.operate.reinvoicing',
          iif: record => record.invoiceStatus === 6,
          paramName: 'i',
          pop: true,
          popTitle: '确认要重新开具此发票吗？',
          click: (item: any) => this.invoicing(item.invoiceId),
        },
        {
          text: '重新申请',
          i18n: 'st.operate.reapply',
          iif: record => this.reapplyStatus.includes(record.invoiceStatus),
          paramName: 'i',
          click: (item: any) => this.reapply(item.invoiceId),
        },
        {
          text: '查看原因',
          i18n: 'st.operate.viewReason',
          iif: record => this.editStatus.includes(record.invoiceStatus),
          paramName: 'i',
          click: (item: any) => this.viewReason(item),
        },
        {
          text: '...',
          iif: record => this.invalidStatus.includes(record.invoiceStatus),
          paramName: 'i',
          children: [
            {
              text: '红冲',
              i18n: 'st.operate.redRush',
              iif: record => this.invalidStatus.includes(record.invoiceStatus),
              paramName: 'i',
              pop: true,
              popTitle: '确认要红冲此发票吗？',
              click: (item: any) => this.redRush(item),
            },
            {
              text: '作废',
              i18n: 'st.operate.invalid',
              iif: record => this.invalidStatus.includes(record.invoiceStatus),
              paramName: 'i',
              pop: true,
              popTitle: '确认要作废此发票吗？',
              click: (item: any) => this.invalid(item.invoiceId),
            },
          ]
        },
      ]
    }
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    purchaseName: '',
    sellerName: '',
    invoiceCode: '',
    invoiceNumber: '',
    invoiceType: '',
    invoiceEntity: '',
    invoiceStatus: '',
    invoicingTime: '',
    accountingVouchers: '',
    minExcludingTaxAmount: '',
    maxExcludingTaxAmount: '',
    menuType: '',
    applyType: '1', // 申请类型：0-订单开票；1-无订单开票；2-提前开票
  };
  pageTotal = 0;
  createDate: ''; // 开票日期
  invoiceStatusOption = clientConfig.outputInvoiceStatusOption; // 全部发票状态
  invoiceTypeOption = clientConfig.invoiceTypeOption;
  invoiceCarrierOption = clientConfig.invoiceCarrierOption;

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private cdr: ChangeDetectorRef,
    public msg: NzMessageService,
    private modalService: NzModalService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getList();
  }

  // 获无订单开票列表
  getList() {
    this.loading = true;
    this.http.get('/output/invoice/list', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 申请开票
  markInvoice() {
    // ptype: 0-申请
    this.modal.static(OutputJdeBillingApplyForBillingComponent, { i: { ptype: 0 } }, 1100).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 开票/重新开具
  invoicing(invoiceId) {
    this.loading = true;
    this.http.post('/output/invoice/invoicing?invoiceIds=' + invoiceId).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msg.success('操作成功');
        this.getList();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 撤销
  revocation(invoiceId) {
    this.loading = true;
    this.http.post('/output/invoice/revoked?invoiceIds=' + invoiceId).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msg.success('操作成功');
        this.getList();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 重新申请
  reapply(invoiceId) {
    // ptype: 1-重新申请
    this.modal.static(OutputJdeBillingApplyForBillingComponent, { i: { ptype: 1, invoiceId } }, 1100).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 打印
  print(item) {
    this.modal.static(OutputInvoicedPrintConfirmationComponent, { i: { selectedRows: [item] } }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 下载
  download(invoiceId) {

  }

  // 红冲
  redRush(item) {
    // ptype=4,红冲查看
    this.modal.static(OutputJdeBillingViewComponent, { i: { ptype: 4, selectedRows: item } }, 1100).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 作废
  invalid(invoiceId) {
    this.modal.static(OutputInvoicedInvalidComponent, { i: { invoiceId } }, 500).subscribe((success) => {
      if (success) {
        this.msg.success('操作成功');
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 查看原因
  viewReason(item) {
    // ptype=3,查看审批原因详情
    this.modal.static(OutputJdeBillingViewComponent, { i: { ptype: 3, selectedRows: item } }, 1100).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 查看
  view(item) {
    this.modal.static(OutputJdeBillingViewComponent, { i: { ptype: 1, selectedRows: item } }, 1100).subscribe((success) => {
      if (success) {
        this.getList();
      } else {
        this.st.reload();
      }
    });
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      purchaseName: '',
      sellerName: '',
      invoiceCode: '',
      invoiceNumber: '',
      invoiceType: '',
      invoiceEntity: '',
      invoiceStatus: '',
      invoicingTime: '',
      accountingVouchers: '',
      minExcludingTaxAmount: '',
      maxExcludingTaxAmount: '',
      menuType: '',
      applyType: '1', // 申请类型：0-订单开票；1-无订单开票；2-提前开票
    };
    this.createDate = '';
    this.getList();
  }

  // 展开
  showOrHidden() {
    this.show = !this.show;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.show) {
      this.more = 'up';
    } else {
      this.more = 'down';
    }
  }

  // 开票日期事件
  createDateChange(event) {
    this.queryParams.invoicingTime = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
  }

}
