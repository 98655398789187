import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STData } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { InputConfigurationTypeEditComponent } from './type-edit/type-edit.component';
import { DialogService } from 'src/app/service/dialog.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-input-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.less'],
})
export class InputConfigurationComponent implements OnInit {
  loading = false;
  selectedRows: STData[] = []; // 选中数据
  rows: any = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '序号', type: 'no', i18n: 'st.no', },
    { title: '发票类型', index: 'category', i18n: 'input.configuration.invoiceType', },
    // { title: '创建时间', index: 'createTime', type: 'date', dateFormat: 'YY-MM-DD', i18n: 'st.createTime', },
    {
      title: '操作',
      i18n: 'st.operate',
      buttons: [
        {
          text: '编辑',
          i18n: 'st.operate.edit',
          paramName: 'i',
          click: (item: any) => this.update(item),
        },
        {
          text: '删除',
          i18n: 'st.operate.delete',
          paramName: 'i',
          pop: true,
          popTitle: '确认进行删除操作吗？',
          click: (item: any) => this.delete(item.id),
        },
      ]
    }
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    createTime: '',
  };
  pageTotal = 0;

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private msgSrv: NzMessageService,
    private modalSrv: NzModalService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getList();
  }

  // 获取列表
  getList() {
    this.loading = true;
    this.http.get('/input/invoice/category/list', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 新增
  add() {
    this.modal.static(InputConfigurationTypeEditComponent, { i: { ptype: 0 } }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      }
    });
  }

  // 编辑
  update(item) {
    this.modal.static(InputConfigurationTypeEditComponent, { i: { ptype: 1, id: item.id, category: item.category } }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      }
    });
  }

  // 删除
  delete(id) {
    this.http.delete('/input/invoice/category/delete?categoryIds=' + id).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('删除成功');
        this.getList();
      } else {
        this.st.reload();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 批量删除
  bulkDelete() {
    this.modalSrv.confirm({
      nzContent: '确认要进行<font color="red">删除</font>操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          // 处理获取的数据id
          const idList = new Array();
          this.selectedRows.map(item => {
            idList.push(item.id);
          })
          const ids = idList.join(',');
          this.delete(ids);
          this.selectedRows = [];
          resolve(true);
        }).catch(() => console.log('删除失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          this.selectedRows = [];
          this.st.reload();
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      createTime: '',
    };
    this.getList();
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.selectedRows = event.checkbox!;
    }
  }

}
