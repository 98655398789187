import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService, NzModalService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { DialogService } from 'src/app/service/dialog.service';
import { STComponent, STColumn } from '@delon/abc';
import * as format from 'date-fns/format';

@Component({
  selector: 'app-input-invoice-overview-match',
  templateUrl: './match.component.html',
  styleUrls: ['./match.component.less'],
})
export class InputInvoiceOverviewMatchComponent implements OnInit {
  i: any;
  show = false;
  more = 'down';
  batchList: any = [{ 'id': '', 'invoiceBatchNumber': '' }]; // 采购单号列表
  rows: any = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '批次号', index: 'invoiceBatchNumber', i18n: 'st.batchNumber', width: 100 },
    { title: '购方企业名称', index: 'invoicePurchaserCompany', i18n: 'st.invoicePurchaserCompany', width: 200 },
    { title: '购方税号', index: 'invoicePurchaserParagraph', i18n: 'st.invoicePurchaserParagraph', width: 100 },
    { title: '销方企业名称', index: 'invoiceSellCompany', i18n: 'st.invoiceSellCompany', width: 200 },
    { title: '销方税号', index: 'invoiceSellParagraph', i18n: 'st.invoiceSellParagraph', width: 100 },
    { title: '上传人', index: 'createBy', i18n: 'st.createUserName', width: 100 },
    { title: '上传日期', index: 'createTime', i18n: 'st.invoiceUploadDate', width: 100 },
    { title: '批次状态', index: 'invoiceBatchStatus', render: 'invoiceBatchStatus', i18n: 'st.invoiceBatchStatus', width: 100 },
    { title: '详情', index: 'threeErrorDescription', render: 'threeErrorDescription', i18n: 'st.threeErrorDescription', width: 100 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 100,
      buttons: [
        {
          text: '匹配采购单',
          i18n: 'st.operate.matchPurchase',
          paramName: 'i',
          pop: true,
          popTitle: '确认要进行匹配采购单操作吗？',
          click: (item: any) => this.match(item.id),
        },
      ]
    }
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    invoiceBatchNumber: '',
    createDate: '',
    createBy: '',
    invoicePurchaserCompany: '',
    invoiceSellCompany: '',
    menuType: '1',
  };
  createDate: '';
  pageTotal = 0;
  loading = false;
  invoiceIds = ''; // 发票id

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    private modalSrv: NzModalService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.getList();
    if (this.i.invoiceIds) {
      this.invoiceIds = this.i.invoiceIds;
    }
  }

  // 获取采购单列表
  getList() {
    this.loading = true;
    this.http.get('/input/invoiceBatch/listForThree', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.batchList = data.invoiceBatchEntityList;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  match(id) {
    this.loading = true;
    this.http.post('/input/invoice/relate?invoiceIds=' + this.invoiceIds + '&batchId=' + id).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('关联采购单成功');
        this.modal.close(true);
      } else {
        this.modal.close(false);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      invoiceBatchNumber: '',
      createDate: '',
      createBy: '',
      invoicePurchaserCompany: '',
      invoiceSellCompany: '',
      menuType: '1',
    };
    this.createDate = '';
    this.getList();
  }

  // 上传日期事件
  invoiceUploadDateChange(event) {
    this.queryParams.createDate = event.length > 0 ? format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD') : '';
  }

  // 展开
  showOrHidden() {
    this.show = !this.show;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.show) {
      this.more = 'up';
    } else {
      this.more = 'down';
    }
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
  }


  close() {
    this.modal.destroy();
  }
}
