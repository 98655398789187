import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-output-rule-edit-commodity',
  templateUrl: './edit-commodity.component.html',
})
export class OutputRuleEditCommodityComponent implements OnInit {
  loading = false;
  i: any;
  data: any = {
    goodsId: '',
    amount: '',
  }
  commodityOption = [
    { goodsId: '', goodsName: '' },
  ]

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.getList();
  }

  // 获取所有商品列表
  getList() {
    this.loading = true;
    this.http.get('/output/goods/alllist').subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.commodityOption = data.list;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 添加商品规则
  save() {
    this.loading = true;
    this.http.put('/output/goodsrules/save', this.data).subscribe(res => {
      this.loading = false;
      const flag = this.dialogService.getErrorCode(res);
      if (flag) {
        this.modal.close(true);
        this.msgSrv.success('操作成功！');
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  close() {
    this.modal.destroy();
  }
}
